import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import axios from "axios";
import { API_URL } from "../../redux/Constant";
import FaqComponent from "./FaqComponent";
import Loader from "../../Components/Loader";
import GoBackButton from "../../Components/GoBackButton";

const Faq = () => {
  const [faqCategory, setFaqCategory] = useState([]);
  const [faqQuestions, setFaqQuestions] = useState([]);

  useEffect(() => {
    getfaqCategory();
    getfaqQues();
  }, []);
  const getfaqCategory = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/all/faqCategory`);
      if (res.status === 200) {
        setFaqCategory(res.data.faqscategory);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getfaqQues = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/all/faq`);
      if (res.status === 200) {
        setFaqQuestions(res.data.faqs);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
            fontWeight: "600",
            transition: "0.3s all",
            cursor: "pointer",
            pb: "1rem",
            "&:hover": {
              color: "#782980",
              transition: "0.3s all",
              "& svg": {
                transition: "0.3s all",
                transform: "translateX(5px)",
              },
            },
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Typography className="title" pb={"1rem"} mt={"2rem"}>
            FAQ
          </Typography>
         {faqQuestions?.length>0? <Grid container spacing={2} mb={"3rem"}>
            <Grid item md={4} xs={12}>
              <Typography
                sx={{
                  fontFamily: "BakbakOneRegular",
                  fontSize: {md:"65px",xs:"40px"},
                  color: "#fff",
                  textAlign:{md:"start",xs:"center"},
                  textShadow: "1px 2px 3px rgba(0, 0, 0, 0.5)",
                }}
              >
                Your
                <br /> Questions,
                <br />
                Answered
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              {faqQuestions?.map((ques, i) => (
                <FaqComponent data={ques} key={i} />
              ))}
            </Grid>
          </Grid>:
          <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>}
        </Container>
        <Box pb={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Faq;
