import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import CommonSolutionSection from "../../Components/CommonSolutionSection";
import book from "../../Assets/Images/common/book.png";
import interview from "../../Assets/Images/common/interview.png";
import course from "../../Assets/Images/common/course.png";
import lecture from "../../Assets/Images/common/lecture.png";
import banner from "../../Assets/Images/reference/banner.png";
import article_1 from "../../Assets/Images/reference/article_1.svg";
import article_2 from "../../Assets/Images/reference/article_2.svg";
import book_1 from "../../Assets/Images/reference/book_1.svg";
import book_2 from "../../Assets/Images/reference/bokk_2.svg";
import icon_1 from "../../Assets/Images/reference/icon_1.svg";
import icon_2 from "../../Assets/Images/reference/icon_2.svg";
import psycology_1 from "../../Assets/Images/reference/psycology_1.svg";
import psycology_2 from "../../Assets/Images/reference/psycology_2.svg";
import textbook_1 from "../../Assets/Images/reference/textbook_1.svg";
import textbook_2 from "../../Assets/Images/reference/textbook_2.svg";

const referenceData = [
  {
    icon_1: book_1,
    icon_2: book_2,
    title: "Research and Clinical Books",
    list: [
      <>
        Multidisciplinary Approaches to Breathing Pattern Disorders - London,{" "}
        <b>
          Churchill Livingstone (Chaitow, L., Bradley, D., Gilbert, C., & Ley,
          R., 2002)
        </b>
      </>,
      <>
        Breathe Well, Be Well - Toronto, John Wiley & Sons Canada, Limited{" "}
        <b>(Fried, R., 1999)</b>
      </>,
      <>
        Hyperventilation Syndrome: Research and Clinical Treatment - Baltimore,
        John Hopkins Univ. Press <b>(Fried, R., 1987)</b>
      </>,
      <>
        Pulmonary Physiology (7th Edition) - New York, McGraw-Hill{" "}
        <b>(Levitzky, M. G., 2007)</b>
      </>,
      <>
        Clinical Acid-Base Balance - New York, Oxford Univ. Press{" "}
        <b>(Thomson, W. S. T., Adams, J. F., & Cowan, R. A., 1997)</b>
      </>,
      <>
        Behavioral and Psychological Approaches to Breathing Disorders - New
        York, Plenum Press <b>(Timmons, B. H. & Ley, R., 1994)</b>
      </>,
    ],
  },
  {
    icon_1: textbook_1,
    icon_2: textbook_2,
    title: "Guides and Textbooks",
    list: [
      <>
        CapnoLearning: An Introductory Guide - Cheyenne, WY, USA, Breathing
        Science, Inc. <b>(Litchfield, P. M. & Reamer, S., 2023)</b>
      </>,
    ],
  },
  {
    icon_1: article_1,
    icon_2: article_2,
    title: "Journal Articles",
    list: [
      <>
        Hypocapnia - New England Journal of Medicine{" "}
        <b>(Laffey, J. G., & Kavanagh, B. P., 2002)</b>
      </>,
      <>
        CapnoLearning: Respiratory Fitness and Acid-Base Regulation -
        Psychophysiology Today <b>(Litchfield, P. M., 2010)</b>
      </>,
      <>
        Embodied Breathing Habits: Aligning Breathing Mechanics with Respiratory
        Chemistry
      </>,
      <>
        - Journal of Holistic Healthcare & Integrative Medicine{" "}
        <b>(Litchfield, P. M. & Reamer, S., 2022)</b>
      </>,
      <>
        Good Breathing, Bad Breathing - L'Esprit D'aujourdhui{" "}
        <b>(Litchfield, P. M. & Tsuda, A., 2006)</b>
      </>,
    ],
  },
  {
    icon_1: psycology_1,
    icon_2: psycology_2,
    title: "Psychology & Behavioral Medicine:",
    list: [
      <>
        The Psychology and Physiology of Breathing in Behavioral Medicine - New York, Plenum Press <b>(Fried, R., & Grimaldi, J., 1993)</b>
      </>,
    ],
  },
];

const References = () => {
  return (
    <>
      <Navbar />
      <Box>
        <Typography component={"img"} src={banner} width={"100%"} />
        <Container>
          <Grid
            container
            spacing={2}
            sx={{
              position: "relative",
              top: { md: "-210px", xs: "-80px" },
              mb:{md:"-100px"}
            }}
          >
            <Grid item md={8} xs={12}>
              <Box
                sx={{
                  background: "rgba(170, 122, 175, 0.35)",
                  borderRadius: "10px",
                  p: { sm: "3.5rem 2rem", xs: "3.5rem 1.5rem" },
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#C8A7CB",
                    borderRadius: "50%",
                    width: { md: "90px", xs: "50px" },
                    height: { md: "90px", xs: "50px" },
                    top: {md:"-50px",xs:"5px"},
                    left: {md:"40px",xs:"5px"},
                  }}
                >
                  <Typography
                    component={"img"}
                    src={icon_1}
                    sx={{ width: { md: "50px", xs: "30px" } }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#C8A7CB",
                    borderRadius: "50%",
                    width: { md: "90px", xs: "50px" },
                    height: { md: "90px", xs: "50px" },
                    bottom: {md:"40px",xs:"5px"},
                    right: {md:"-60px",xs:"5px"},
                  }}
                >
                  <Typography
                    component={"img"}
                    src={icon_2}
                    sx={{ width: { md: "50px", xs: "30px" } }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "AvenirLTStdRoman",
                    color: "#fff",
                    fontSize: { sm: "45px", xs: "30px" },
                    lineHeight: "normal",
                    fontWeight: "600",
                    pb: "1rem",
                  }}
                >
                  Explore Groundbreaking Research in Breathing Science!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "AvenirLTStdRoman",
                    color: "#49194E",
                    fontSize: "16px",
                    width: { lg: "90%" },
                  }}
                >
                  Dive into our curated collection of essential books, journals,
                  and author contributions that have shaped and supported the
                  knowledge base of Better Physiology. Explore the foundational
                  works and cutting-edge research that inform our practices.
                </Typography>
              </Box>
            </Grid>
          </Grid>
       {
        referenceData?.map((data,i)=>(
            <Box
            key={i}
            sx={{
              position: "relative",
              mb: "5rem",
              p: { sm: "3rem 2rem", xs: "2.5rem 1.5rem" },
              background: "#fff",
              borderRadius: "10px",
              "& .title": {
                fontFamily: "AvenirLTStdRoman",
                fontSize: "30px",
                lineHeight: "normal",
                fontWeight: "600",
                color: "#782980",
                pb: "1rem",
              },
              "& .subTitle": {
                fontFamily: "AvenirLTStdRoman",
                fontSize: "16px",
                color: "#49194E",
                pb: "0.5rem",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#C8A7CB",
                borderRadius: "50%",
                width: { md: "110px", xs: "70px" },
                height: { md: "110px", xs: "70px" },
                top: "-50px",
                left: "40px",
              }}
            >
              <Typography
                component={"img"}
                src={data.icon_1}
                sx={{ width: { md: "80px", xs: "30px" } }}
              />
            </Box>

            <Typography
              component={"img"}
              src={data.icon_2}
              sx={{
                position: "absolute",
                width: { md: "100px", xs: "70px" },
                height: { md: "100px", xs: "70px" },
                bottom: "0",
                right: "-0",
              }}
            />

            <Typography className="title" mt={"3rem"}>
              {data.title}
            </Typography>
          {
            data.list.map((list,index)=>(
                <Typography key={index} className="subTitle" component={"li"}>
            {list}
            </Typography>
            ))
          }
          </Box>
        ))
       }
        </Container>
        <Box>
          <CommonSolutionSection
            text={
              <>
                Explore <br /> Our
                <br /> Other <br />
                Resources
              </>
            }
            btn_1={"Course"}
            btn_2={"Book"}
            btn_3={"Interviews"}
            btn_4={"Lectures"}
            img_1={course}
            img_2={book}
            img_3={interview}
            img_4={lecture}
            link_1={"/course"}
            link_2={"/book"}
            link_3={"/interview"}
            link_4={"/lectures"}
          />
        </Box>
        <Box
          pb={"2rem"}
          sx={{
            "& a": {
              fontWeight: "500 !important",
            },
          }}
        >
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default References;
