import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import education_img from "../../Assets/Images/education_img.png";
import certificate from "../../Assets/Images/professional-certificate.png";
import CommonSolutionSection from "../../Components/CommonSolutionSection";
import consultation from "../../Assets/Images/go-brochure/consultation.png";
import videos from "../../Assets/Images/go-brochure/videos.png";
import support from "../../Assets/Images/go-brochure/support.png";
import training_img from "../../Assets/Images/go-brochure/training.png";
import lecture from "../../Assets/Images/common/lecture.png";
import training_1 from "../../Assets/Images/training/training_1.png";
import training_2 from "../../Assets/Images/training/training_2.png";
import training_3 from "../../Assets/Images/training/training_3.png";
import training_4 from "../../Assets/Images/training/training_4.png";
import training_5 from "../../Assets/Images/training/training_5.png";
import training_6 from "../../Assets/Images/training/training_6.png";
import phone from "../../Assets/Images/phone_dark.svg";
import website from "../../Assets/Images/website.svg";
import video from "../../Assets/Images/video.svg";
import GoBackButton from "../../Components/GoBackButton";



const ProfessionalSchool = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
          },
          // "& .grid": {
          //   mt: { md: "-60px", xs: "0" },
          // },
          "& .main_box": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            background: "#fff",
            p: "10px 20px",
            borderRadius: "5px",
            my: "0.3rem",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={0} sx={{ my: { md: "2rem", xs: "2rem" } }}>
            <Grid item md={6.5} xs={12}>
              <Typography className="title" pb={"1rem"}>
              Professional Education
              </Typography>
              <Typography className="subTitle" sx={{ width: { md: "80%" } }}>
              The <b>Professional School Of Behavioral Health Sciences</b> offers a <b>Professional Certificate</b> in the Science of Breathing Behavior Analysis, <b>Certification</b> as Certified Breathing Behavior Analyst, and a <b>Professional Diploma</b> in Behavioral Breathing Science to qualified applicants.
              </Typography>
              <Box
                mt={"2rem"}
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  width: { lg: "95%" },
                }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        p: {sm:"2.5rem 1.5rem",xs:"1rem"},
                      }}
                    >
                      <Typography
                        className="subTitle" sx={{
                            fontSize:"22px !important",
                            lineHeight:"38px",
                            fontFamily:"AvenirLTStdRoman !important"
                        }}>
                        If you are interested in<br/>
                         professional training,<br/> including brochures,<br/> scheduling, and <br/>registration
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Box
                      sx={{
                        background: "#fff",
                        boxShadow: {md:"-2px 1px 4px rgba(0, 0, 0, 0.3)",xs:"0px 1px 4px rgba(0, 0, 0, 0.3)"},
                        borderRadius: {
                          md: "0 10px 10px 0",
                          xs: "0px 0 10px 10px",
                        },
                        height:"100%",
                        display:"flex",
                    alignSelf:"center",
                      }}
                    >
                      <Box
                        sx={{
                          // background: "#782980",
                          // borderRadius: "10px",
                          // p: "2rem 3rem",
                          display: "grid",
                          "& .img_box": {
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            // border: "1px solid #C8A7CB",
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                          },
                          "& .text_1": {
                            color: "#782980",
                            fontSize: "16px",
                            fontFamily: "AvenirLTStdBook",
                          },
                          "& .text_2": {
                            color: "#49194E",
                            fontSize: "16px",
                            fontFamily: "AvenirLTStdBook",
                          },
                          "& a": {
                            textDecoration: "none",
                          },
                          "& .main_wrapper": {
                            // p: "0.5rem",
                            // transition: "0.3s all",
                            // borderRadius: "5px",
                            // "&:hover": {
                            //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                            // },
                          },
                        }}
                      >
                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={phone} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">Call</Typography>
                              <Typography className="text_2">
                                +1.800.850.9795
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={website} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">Visit</Typography>
                              <Typography
                                className="text_2"
                                component={"a"}
                                href="https://www.bp.edu"
                                target="_blank"
                              >
                                www.bp.edu
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={video} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">
                                Zoom appointment
                              </Typography>
                              <Typography
                                className="text_2"
                                component={"a"}
                                target="_blank"
                                href="https://e-campus-registration.bp.edu/"
                              >
                               www.e-campus.bp.edu
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={5.5} xs={12}>
            <Typography
                  component={"img"}
                  src={education_img}
                  sx={{
                    width: "100%",
                  height:"100%",
                  position:"relative",
                  left:{md:"-3rem"}
                  }}
                />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{
              mt: { lg: "20px", md: "20px", xs: "0" },
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: { lg: "3rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p": {
                    fontFamily: "AvenirLTStdBook !important",
                  },
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Typography className="title" pb={"0.5rem"}>
                  An Unseen Problem
                </Typography>
                <Typography className="subTitle">
                  Statistics suggest that tens of millions of people worldwide
                  suffer from the profound and misunderstood symptoms and
                  deficits of learned dysfunctional breathing habits.
                  Unfortunately, these habits are rarely identified by
                  practitioners, their effects mistakenly attributed to other
                  causes, and their resolutions prescriptive in nature where the
                  focus is on symptoms rather than on causes.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "180px", xs: "70px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "23%",
                    left: "-25px",
                    p: "0.5rem",
                    transform: "translateY(-23%)",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: "#782980",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: {
                      lg: "318px",
                      md: "280px",
                      sm: "300px",
                      xs: "200px",
                    },
                    height: {
                      lg: "440px",
                      md: "380px",
                      sm: "410px",
                      xs: "230px",
                    },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "20%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "3rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        component={"img"}
                        src={training_1}
                        sx={{ width: { lg: "100%", xs: "100%" } }}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box>
                        <Typography
                          component={"img"}
                          src={training_2}
                          sx={{ width: { lg: "92%", xs: "100%" } }}
                        />
                      </Box>
                      <Box mt={"0.8rem"}>
                        <Typography
                          component={"img"}
                          src={training_3}
                          sx={{ width: { lg: "92%", xs: "100%" } }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{
              mt: { lg: "0", md: "20px", xs: "0" },
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: { lg: "3rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p": {
                    fontFamily: "AvenirLTStdBook !important",
                  },
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Typography className="title" pb={"0.5rem"}>
                  Our Solution in Action
                </Typography>
                <Typography className="subTitle">
                  The Professional School,
                </Typography>
                <ul>
                  <Typography className="subTitle" component={"li"}>
                    trains healthcare practitioners, human service
                    professionals, health educators, and performance enhancement
                    consultants to integrate breathing learning services into
                    their practices and businesses.
                  </Typography>
                  <Typography
                    className="subTitle"
                    component={"li"}
                    pt={"0.2rem"}
                  >
                    trains breathing specialists, e.g., breathworkers, to
                    integrate the principles of behavior analysis into their
                    practices.
                  </Typography>
                  <Typography
                    className="subTitle"
                    component={"li"}
                    pt={"0.2rem"}
                  >
                    trains high performance professionals such as athletes,
                    performers, artists, and pilots, to bring breathing science
                    applications into their work. To this end, the School offers
                    two breathing behavior analysis programs.
                  </Typography>
                </ul>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "180px", xs: "70px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    bottom: { lg: "5%", xs: "15%" },
                    left: "-25px",
                    p: "0.5rem",
                    transform: {
                      lg: "translateY(-5%)",
                      xs: "translateY(-15%)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: "#782980",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: {
                      lg: "318px",
                      md: "280px",
                      sm: "300px",
                      xs: "200px",
                    },
                    height: {
                      lg: "440px",
                      md: "380px",
                      sm: "410px",
                      xs: "230px",
                    },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "20%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "4rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        component={"img"}
                        src={training_4}
                        sx={{ width: { lg: "100%", xs: "100%" } }}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box>
                        <Typography
                          component={"img"}
                          src={training_5}
                          sx={{ width: { lg: "95%", xs: "100%" } }}
                        />
                      </Box>
                      <Box mt={"0.8rem"}>
                        <Typography
                          component={"img"}
                          src={training_6}
                          sx={{ width: { lg: "95%", xs: "100%" } }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          
        </Container>
        <Box py={"2rem"}>
          <Typography
                  component={"img"}
                  src={certificate}
                  sx={{
                    width: "100%",
                  height:"100%",
                
                  }}
                />
          </Box>
        <Box pt={"2rem"}>
          <CommonSolutionSection
            text={
              <>
                Explore <br /> Our
                <br /> Other <br />
                Resources
              </>
            }
            btn_1={"Consultation"}
            btn_2={"GO Videos"}
            btn_3={"GO Support"}
            btn_4={"Training"}
            img_1={consultation}
            img_2={videos}
            img_3={support}
            img_4={training_img}
            link_1={"/consultation"}
            link_2={"/go-video"}
            link_3={"/go-support"}
            link_4={"/training"}
          />
        </Box>
        <Box
          pb={"2rem"}
          sx={{
            "& a": {
              fontWeight: "500 !important",
            },
          }}
        >
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default ProfessionalSchool;
