import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ItemDetails from "./ItemDetails";



const PriceDetail = ({
  result,
  totalPrice,
  display,
  shipping,
  deliveryDate,
  hide,
}) => {

  return (
    <>
      <Box
                sx={{
                  background: "#C8A7CB",
                  p: "1rem",
                  borderRadius: "5px 5px 0 0",
                  mb: "5px",
                }}
              >
                <Typography className="title">Order summary</Typography>
              </Box>
      {/* {hide && (
        <Typography
          sx={{
            fontSize: { sm: "20px", xs: "20px" },
            fontFamily: "AvenirLTStdRoman !important",
            lineHeight: "35px",
            fontWeight: "600",
            color: "#782980",
          }}
        >
          PRICE DETAILS ({result?.data?.length}{" "}
          {result?.data?.length > 1 ? "items" : "item"})
        </Typography>
      )} */}
      <Box
        sx={{ border: "1px solid #C8A7CB", p: hide ? "0.5rem 1rem" : "1rem",background:"#C8A7CB" }}
      >
        {/* {!hide && (
          <Typography
            sx={{
              fontSize: { sm: "16px", xs: "16px" },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight: "600",
              borderBottom: "1px solid #C8A7CB",
              pb: "0.5rem",
              color: "#782980",
            }}
          >
            PRICE DETAILS ({result?.data?.length}{" "}
            {result?.data?.length > 1 ? "items" : "item"})
          </Typography>
        )} */}
        {hide && (
          <Box sx={{
             pb: hide && "0.5rem",
          }}>
            {result?.data?.map((item, index) => (
              <Box>
                <ItemDetails item={item} key={index} />
              </Box>
            ))}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: hide && "0.6rem",
           borderTop: hide && "1px solid #ffffff80",
            // pt: hide && "0.3rem",
          }}
        >
          <Typography
            sx={{
              fontSize:"16px",
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:hide?"600":"600",
              color: "#782980",
            }}
          >
            Total
          </Typography>
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "16px" : "16px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              color:"#49194E"
            }}
          >
            $ {parseFloat(totalPrice).toFixed(2)}
          </Typography>
        </Box>

       {shipping != null&& <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #C8A7CB",
            pb:hide&& "0.3rem",
        
          }}
        >
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "16px" : "16px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              color:"#782980"
            }}
          >
            Shipping
          </Typography>
          <Typography
            sx={{
              fontSize: {
                sm: hide ? "16px" : "16px",
                xs: hide ? "16px" : "16px",
              },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:hide?"600":"500",
              display: "flex",
              alignItems: "center",
              gap: "5px",
               color:"#49194E"
            }}
          >
            {shipping == null ? "NA" : `$ ${parseFloat(shipping).toFixed(2)}`}
            {/* <Tooltip placement="top" title={`Your shipping charge is calculated as per items in your cart and shipping address.`} arrow   classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
       
                     
                        </Tooltip> */}
          </Typography>
        </Box>}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: hide && "1px solid #ffffff80",
            pt:hide&& "0.6rem",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: "16px", xs: "16px" },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:800,
              color: "#782980",
              
            }}
          >
            Total Amount
          </Typography>
          <Typography
            sx={{
              fontSize: { sm: "16px", xs: "16px" },
              fontFamily: "AvenirLTStdRoman",
              lineHeight: "35px",
              fontWeight:hide?"800":"500",
              color: "#49194E",
            }}
          >
            ${" "}
            {shipping
              ? parseFloat(
                  parseFloat(totalPrice) + parseFloat(shipping)
                ).toFixed(2)
              : parseFloat(totalPrice).toFixed(2)}
          </Typography>
        </Box>
        {deliveryDate && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: "0.5rem",
            
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "16px", xs: "16px" },
                fontFamily: "AvenirLTStdRoman",
                lineHeight: "35px",
                fontWeight: "600",
                color:"#782980"
              }}
            >
              Expected Delivery
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "16px", xs: "16px" },
                fontFamily: "AvenirLTStdRoman",
                lineHeight: "35px",
                fontWeight: "600",
                color:"#49194E"
              }}
            >
              {new Date(deliveryDate).toLocaleDateString()}
            </Typography>
          </Box>
        )}
        <Box pt="0.65rem" display={display}>
        
          <Button
                className={"btn"}
                sx={{
                  fontSize: "16px",
                  textTransform: "capitalize",
                  fontFamily: "AvenirLTStdBook",
                  width:"100%",
                  fontWeight:"600",
                }}
                    component={Link}
            to="/place-order"
              >
                Place Order
              </Button>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(PriceDetail);
