import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import consultation_img from "../../Assets/Images/support_img.png";
import icon_1 from "../../Assets/Images/support_icon_1.svg";
import icon_2 from "../../Assets/Images/support_icon_2.svg";
import support_earth from "../../Assets/Images/support_earth.svg";
import support_rect from "../../Assets/Images/support_rect.png";
import software_tutorial from "../../Assets/Images/software_tutorial.png";
import NewsLetter from "../../Components/NewsLetter";
import consultation_1 from "../../Assets/Images/consultation_1.png";
import support_2 from "../../Assets/Images/support_2.png";
import consultation_3 from "../../Assets/Images/consultation_3.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import phone from "../../Assets/Images/contact/phone.svg";
import email from "../../Assets/Images/contact/email.svg";
import video from "../../Assets/Images/video.svg";
import CommonSection from "../../Components/CommonSection";
import videos from "../../Assets/Images/go-brochure/videos.png";
import consultation from "../../Assets/Images/go-brochure/consultation.png";
import training from "../../Assets/Images/go-brochure/training.png";
import warranty from "../../Assets/Images/warranty.png";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component="img"
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const GoSupport = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { sm: "6rem", xs: "4rem" },
          "& .title,.title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
            position: "relative",
          },

          "& .title_1": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "24px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle,.subTitle_": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
            position: "relative",
          },
          "& .subTitle_": {
            color: "#C8A7CB",
            fontWeight: "600",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "0rem" } }}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  py: "2rem",
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${support_rect})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: { sm: "100% 100%", xs: "100% 100%" },
                    backgroundPosition: "center",
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: { lg: "-30%", sm: "-15%", xs: "-10%" },
                  }}
                />
                <Typography
                  className="title"
                  pb="1rem"
                  sx={{
                    color: "#fff !important",
                  }}
                >
                  GO Support
                </Typography>
                <Typography
                  className="subTitle"
                  pb="1rem"
                  sx={{
                    width: { lg: "65%", xs: "80%" },
                    fontFamily: "AvenirLTStdBook !important",
                    color: "#fff !important",
                  }}
                >
                  It is highly recommended that all CapnoTrainer GO users read
                  the software Manuals and watch the tutorial videos for answers
                  to their questions.
                </Typography>
                <Box
                  sx={{
                    my: "1rem",
                    width: { lg: "70%" },
                    ml: { lg: "6%", sm: "7%", xs: "7%" },
                    position: "relative",
                    p: { lg: "2rem 3rem", sm: "2rem 3rem", xs: "1rem 1.5rem" },
                    borderRadius: "10px",
                    background: "#fff",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    "& p": {
                      fontFamily: "AvenirLTStdBook !important",
                      pl: "2.5rem",
                    },
                    "& a": {
                      fontWeight: "bold",
                      color: "#49194E",
                      textDecoration: "none",
                    },
                  }}
                >
                  <Typography
                    component="img"
                    src={support_earth}
                    sx={{
                      width: { sm: "auto", xs: "80px" },
                      position: "absolute",
                      top: "50%",
                      left: { sm: "-10%", xs: "-12%" },
                      transform: "translateY(-50%)",
                    }}
                  />
                  <Typography className="subTitle">
                    These support materials are available at{" "}
                    <a href="https://www.betterphysiology.com/" target="_blank">
                      www.betterphysiology.com
                    </a>{" "}
                    as well as within the BASIC and PROFESSIONAL software
                    programs themselves.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={5} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  background: "#C8A7CB",
                  borderRadius: "10px",
                  lineHeight: "0",
                  textAlign: "center",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                }}
              >
                <IconBox
                  src={icon_1}
                  sx={{
                    top: { md: "35%", xs: "0px" },
                    left: { md: "-50px", xs: "-20px" },
                  }}
                />
                <IconBox
                  src={icon_2}
                  sx={{
                    bottom: { md: "-30px", xs: "-10px" },
                    right: { md: "-30px", xs: "-10px" },
                  }}
                />
                <Typography
                  component="img"
                  src={consultation_img}
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                    left: "-10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            sx={{
              mt: "60px",
              pt: "1.5rem",
              pb: "1rem",
              position: "relative",
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                background: "#fff",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                position: "absolute",
                right: "0",
                width: { lg: "80%", xs: "100%" },
                height: "100%",
                top: "0",
              }}
            />
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  p: "1rem",
                }}
              >
                <Typography
                  component={"img"}
                  src={support_2}
                  sx={{ width: { lg: "100%", xs: "100%" } }}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                position={"relative"}
                sx={{
                  p: "1rem",
                }}
              >
                <Typography className="title_1" pb="1rem">
                  Experiencing Hardware or Installation Issues?
                </Typography>
                <Typography
                  className="subTitle"
                  pb="2rem"
                  sx={{ width: { lg: "65%", xs: "100%" } }}
                >
                  If you encounter any hardware or installation problems, our
                  support team is here to help! For immediate assistance,
                </Typography>
                <Grid
                  container
                  spacing={4}
                  position={"relative"}
                  sx={{
                    "& .img_box": {
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      border: "1px solid #C8A7CB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .text_1": {
                      color: "#C8A7CB",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .text_2": {
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .main_box": {
                      // width:{lg:"90%"},
                      display: "flex",
                      //   justifyContent:"center",
                      gap: "10px",
                      background: "#782980",
                      borderRadius: "10px",
                      p: "1.5rem 2rem",
                    },
                    "& a": {
                      textDecoration: "none",
                      color: "inherit",
                    },
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      pl: { sm: "auto", xs: "28px !important" },
                    }}
                  >
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Call</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      pl: { sm: "auto !important", xs: "28px !important" },
                    }}
                  >
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={email} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Email</Typography>
                        <Typography className="text_2">
                          <a href="mailto:tech@betterphysiology.com ">
                            tech@betterphysiology.com 
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Box mt={"4rem"} mb={"2rem"}>
            <Typography className="title_1" pb="1rem">
              Professional GO Users and their associated Trainers:
            </Typography>
            <Typography
              className="subTitle"
              sx={{
                width: { lg: "100%", xs: "100%" },
                "& a": {
                  color: "inherit",
                  fontWeight: "600",
                  textDecoration: "none",
                },
              }}
            >
              Set an appointment on Zoom with the Better Physiology Tech System
              at{" "}
              <a href="mailto:tech@betterphysiology.com ">
                tech@betterphysiology.com 
              </a>{" "}
              for software and Cloud database account issues. You may also call{" "}
              <b>1.800.850.9795.</b> Your professional subscription includes
              these live services at no cost to you or your associate trainers.
            </Typography>
          </Box>
          <Box mb={"2rem"}>
            <Typography className="title_1" pb="1rem">
              Personal and Basic GO users:
            </Typography>
            <Typography
              className="subTitle"
              pb="1rem"
              sx={{
                width: { lg: "100%", xs: "100%" },
                "& a": {
                  color: "inherit",
                  fontWeight: "600",
                  textDecoration: "none",
                },
              }}
            >
              Read the BASIC MANUAL and watch specifically relevant video
              tutorials available at{" "}
              <a href="https://www.betterphysiology.com/" target="_blank">
                www.betterphysiology.com
              </a>{" "}
              as well as within the software itself. Live tech support on Zoom
              is available for a fee of $25.00 per 15 minutes of service by
              appointment with the Better Physiology Tech System. Go to{" "}
              <a href="mailto:tech@betterphysiology.com">
                tech@betterphysiology.com
              </a>{" "}
              or call 1.800.850.9795 to set an appointment.
            </Typography>
            <Typography
              className="subTitle"
              sx={{
                width: { lg: "100%", xs: "100%" },
                "& a": {
                  color: "inherit",
                  fontWeight: "600",
                  textDecoration: "none",
                },
              }}
            >
              Tech Support does NOT provide free software instructional
              services. You may pay for live one-on-one tutorial service as
              described later.
            </Typography>
          </Box>
          <Grid
            container
            spacing={0}
            sx={{
              mt: "60px",
              mb: "60px",
              pt: "1.5rem",
              pb: "1rem",
              position: "relative",
            }}
          >
            <Box
              sx={{
                borderRadius: "10px",
                background: "#fff",
                // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                position: "absolute",
                right: "0",
                width: { lg: "75%", xs: "100%" },
                height: "100%",
                top: "0",
              }}
            />
            <Grid
              item
              md={5}
              xs={12}
              sx={{
                display: "flex",
                // alignSelf: "center",
                position: "relative",
              }}
            >
              <Box p={"1rem"}>
                <Typography
                  component={"img"}
                  src={warranty}
                  sx={{ width: { lg: "95%", xs: "100%" } }}
                />
              </Box>
            </Grid>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                position={"relative"}
                sx={{
                  p: "1rem",
                  pr: { lg: "2rem" },
                }}
              >
                <Grid container spacing={4} position={"relative"}>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      pl: { sm: "auto", xs: "28px !important" },
                    }}
                  >
                    <Box
                      sx={{
                        p: "1.5rem",
                        background: "#782980",
                        borderRadius: "10px",
                        height: "100%",
                      }}
                    >
                      <Typography
                        className="title"
                        pb="1rem"
                        sx={{
                          color: "#fff !important",
                          fontSize: "20px !important",
                        }}
                      >
                        Standard Warranty
                      </Typography>
                      <Typography
                        className="subTitle"
                        pb="1rem"
                        sx={{
                          fontFamily: "AvenirLTStdBook !important",
                          color: "#C8A7CB !important",
                        }}
                      >
                        The CapnoTrainer GO warranty is one year and includes
                        parts, labor, and return shipment. The Warranty begins
                        on the day of shipment following initial purchase.
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    xs={12}
                    sx={{
                      pl: { sm: "auto !important", xs: "28px !important" },
                    }}
                  >
                    <Box
                      sx={{
                        p: "1.5rem",
                        background: "#782980",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography
                        className="title"
                        pb="1rem"
                        sx={{
                          color: "#fff !important",
                          fontSize: "20px !important",
                        }}
                      >
                        Extended Warranty Program
                      </Typography>
                      <Typography
                        className="subTitle"
                        pb="1rem"
                        sx={{
                          fontFamily: "AvenirLTStdBook !important",
                          color: "#C8A7CB !important",
                        }}
                      >
                        The program is for a two-year period and includes parts,
                        labor, return shipment, AND accidental damage. The
                        extended program is available only at the GO initial
                        purchase time and before shipment for a fee of $300.00.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    position: "relative",
                    left: { lg: "-25%", md: "-50%" },
                  }}
                >
                  <Typography className="title_1" pb="1rem" pt={"2rem"}>
                    Making a Claim
                  </Typography>
                  <Typography
                    className="subTitle"
                    pb="1rem"
                    sx={{
                      width: { lg: "100%", xs: "100%" },
                      "& a": {
                        color: "inherit",
                        fontWeight: "600",
                        textDecoration: "none",
                      },
                    }}
                  >
                    In the event of a warranty claim, send an email to{" "}
                    <a href="mailto:tech@betterphysiology.com">
                      tech@betterphysiology.com
                    </a>{" "}
                    . Unless an email is sent to Better Physiology on or before
                    the last day of the warranty time period, the warranty will
                    be considered expired.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Typography className="title_1" pb="1rem">
              Software TUTORIAL SERVICES
            </Typography>
            <Typography
              className="subTitle"
              pb="1rem"
              sx={{
                width: { lg: "100%", xs: "100%" },
                "& a": {
                  color: "inherit",
                  fontWeight: "600",
                  textDecoration: "none",
                },
              }}
            >
              Many people learn to use the software more efficiently through
              receiving one-on-one live help from a technology expert rather
              than by reading manuals and watching videos. Personal attention
              while learning the details of sophisticated software means
              learning to take advantage of its many perhaps otherwise
              overlooked features.
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid
              item
              md={8}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  width: { lg: "85%", xs: "100%" },
                }}
              >
                <Typography
                  className="subTitle"
                  pb="1rem"
                  sx={{
                    "& a": {
                      color: "inherit",
                      fontWeight: "600",
                      textDecoration: "none",
                    },
                  }}
                >
                  Better Physiology offers one-on-one tutorial services for a
                  fee of $25.00 per 15 minutes of service. Contact{" "}
                  <a href="mailto:tech@betterphysiology.com ">
                    tech@betterphysiology.com 
                  </a>{" "}
                  to make an appointment. This service is above and beyond the
                  trouble-shooting services provided at no charge for
                  Professional GO users who receive these services as part of
                  their subscription.
                </Typography>
                <Typography
                  className="subTitle"
                  pb="1rem"
                  sx={{
                    "& a": {
                      color: "inherit",
                      fontWeight: "600",
                      textDecoration: "none",
                    },
                  }}
                >
                  Better Physiology DOES NOT educate customers on how to provide
                  breathing behavior analysis or CapnoLearning services. Consult
                  the book, CapnoLearning: An Introductory Guide, or call
                  1.800.850.9795 for CapnoLearning consultation services as
                  described below.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                component={"img"}
                src={software_tutorial}
                sx={{
                  width: { sm: "80%", xs: "100%" },
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Box py={"2rem"}>
          <CommonSection
            title={
              <>
                Explore
                <br /> Our <br /> Other <br />
                Services
              </>
            }
            btn_1={"Go Videos"}
            btn_2={"Consultation"}
            btn_3={"Training"}
            img_1={videos}
            img_2={consultation}
            img_3={training}
            link_1={"/go-video"}
            link_2={"/consultation"}
            link_3={"/training"}
          />
        </Box>
    
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default GoSupport;
