import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Slide } from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoModal({ setOpenModal, openModal, videoLink }) {
  const [modalHeight, setModalHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    const handleResize = () => {
      setModalHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: {lg:"70%"}, // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background: "#F0EDF6",
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
        <Box
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton> */}
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
              color: "#49194E",
              cursor: "pointer",
              fontSize: "1.8rem",
            }}
            onClick={() => setOpenModal(false)}
          />
        </Box>
        <Box
          sx={{
            p: "1rem 2rem 2rem",
          }}
        >
          <Box
            sx={{
              background: "#F7F7F9",
              //   padding: "1.5rem",
              borderRadius: "12PX",
              "& label": {
                mb: "0.3rem",
                display: "block",
                color: "#000",
                fontSize: "18px",
                fontFamily: "ABLE !important",
              },
              "& input": {
                background: "#F7F7F9",
                p: "10px",
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
                border: "0",
                color: "#000",
                mb: "0.5rem",
                "&:focus": {
                  outline: "none",
                },
                "&::placeholder": {
                  color: "#E4D5F4",
                },
              },
            }}
          >
            <Box
              sx={{
                background: "#F7F7F9",
                borderRadius: "12px",
                // p: { sm: "1rem", xs: "1rem" },
                // my: "1.5rem",
                "& iframe": {
                  "@media (max-width:600px)": {
                    height: "200px",
                  },
                },
              }}
            >
              <iframe
                width="100%"
                height={modalHeight - 200}
                style={{ borderRadius: "10px" }}
                //   src="https://www.youtube.com/embed/LzpC6ul-3J8?si=BKjQQpuP7bHQ_NW1"
                src={videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
