import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import windows from "../../Assets/Images/windows.svg";
import ios from "../../Assets/Images/ios.svg";
import info from "../../Assets/Images/info.svg";
import axios from "axios";
import GoBackButton from "../../Components/GoBackButton";

const goDemoData = [
  {
    id: 1,
    downloads: "Click on Download for Windows OR Download for Mac.",
  },
  {
    id: 2,
    downloads:
      "Click on the CapnoTrainer Installer file, e.g., with Chrome, in the lower left corner.",
    text4:
      "(CapnoTrainer-installer.exe for PC, or CapnoTrainer-installer.dmg for Apple)",
  },
  {
    id: 3,
    downloads:
      "Read and follow the instructions that appear in an installation window.",
    text1:
      "If you are using a PC, the CapnoTrainer icon will now appear on your desktop.",
    text2:
      "If you are using a MAC, the CapnoTrainer icon will appear in the Applications folder.",
  },
  {
    id: 4,
    downloads: "Click on the CapnoTrainer icon.",
  },
  {
    id: 5,
    downloads: "A sign-in window will appear.",
  },
  {
    id: 6,
    downloads:
      "If you are a new Client, Renter or Trainer, enter your EMAIL address and your temporary PASSWORD.",
    text6: "IF YOU ARE A CLIENT, RENTER, OR TRAINER, STOP HERE.",
  },
  {
    id: 7,
    downloads:
      "If you are NEW GO CUSTOMER, click on CREATE account to activate your software.",
  },
  {
    id: 8,
    downloads: "Enter your EMAIL.",
  },
  {
    id: 9,
    downloads: "Create a PASSWORD.",
  },
  {
    id: 10,
    downloads: "Enter your SERIAL NUMBER.",
  },
  {
    id: 11,
    downloads: "Click on CREATE YOUR ACCOUNT.",
  },
];

const GoDownload = () => {
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://apis.capnolearning.com/api/get/Specific/software/data`
      );
      if (res.status === 200) {
        console.log(res.data.software_key);
        setData(res.data.software_key);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .text_1": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "18px",
            // lineHeight: "normal",
            color: "#49194E",
            "& span": {
              fontWeight: "600",
            },
          },

          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },

          "& .icon_box": {
            background: "#fff",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            p: "1rem 1.5rem",
            borderRadius: "10px",
            textAlign: "center",
            textDecoration: "none",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={7} xs={12}>
              <Typography className="title" pb={"1rem"}>
                GO Downloads
              </Typography>
              <Typography className="subTitle">
                Please follow the instructions to download your GO software,
                whether for the first time or for subsequent downloads. The
                instructions are the same for all versions of the GO: Personal
                (with Option C), Basic, and Professional.
              </Typography>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              sx={{ display: "flex", gap: "2rem", justifyContent: "center" }}
            >
              <Box className="icon_box" component={"a"} href={data?.windows}>
                <img src={windows} alt="windows" />
                <Typography className="subTitle" pt={"0.5rem"}>
                  Download for
                  <br /> Windows
                </Typography>
              </Box>
              <Box className="icon_box" component={"a"} href={data?.mac}>
                <img src={ios} alt="ios" />
                <Typography className="subTitle" pt={"0.5rem"}>
                  Download for
                  <br /> Mac
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box my={"2rem"}>
            <Box
              sx={{
                background: "rgb(120 41 128 / 5%)",
                p: "2.5rem 1.5rem",
                borderRadius: "5px",
                position: "relative",
                "& img": {
                  position: "absolute",
                  top: "-25px",
                  left: "5px",
                  display: { lg: "block", xs: "none" },
                },
              }}
            >
              <img src={info} alt="info" width={"45px"} />
              <Typography className="text_1">
                If you are a <span>NEW CUSTOMER</span>, please follow the ten
                steps shown below.
              </Typography>

              <Typography
                className="text_1"
                sx={{ width: { lg: "80%" }, py: "0.8rem" }}
              >
                If you are <span>ALREADY A CUSTOMER</span> and wish to download
                the software to another computer, follow only steps 1 - 6 as
                described below.
              </Typography>
              <Typography className="text_1">
                If you are a <span>CLIENT</span>, a <span>RENTER</span>, or a{" "}
                <span>TRAINER</span> follow only steps 1 - 6 as described below.
              </Typography>
            </Box>
            <br />
            {goDemoData.map((e, index) => (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems:
                      e.text1 || e.text2 || e.text4 ? "start" : "center",
                    pb: "1rem",
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "100%",
                      background: "#C8A7CB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: { sm: "24px", xs: "20px" },
                      color: "#782980",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}
                  </Box>
                  <Typography className="subTitle">
                    {e.downloads}
                    {e.text1 && (
                      <Typography
                        className="subTitle"
                        sx={{ color: "#782980 !important" }}
                      >
                        {e.text1}
                      </Typography>
                    )}
                    {e.text2 && (
                      <Typography
                        className="subTitle"
                        sx={{ color: "#782980 !important" }}
                      >
                        {e.text2}
                      </Typography>
                    )}
                    {e.text4 && (
                      <Typography className="subTitle">{e.text4}</Typography>
                    )}
                  </Typography>
                </Box>
                {e.text6 && (
                  <Box
                    sx={{
                      //   background: "#fff",
                      //   p: "2rem 1.5rem",
                      //   borderRadius: "10px",
                      "& .MuiDivider-root::before": {
                        width: "0%",
                      },
                      "& .MuiDivider-root::after": {
                        borderTop: "1.5px solid #782980",
                      },
                      "& .MuiDivider-wrapper": {
                        pl: "0",
                      },
                    }}
                  >
                    <Divider
                      textAlign="left"
                      sx={{
                        pb: "1.5rem",
                        pt: "0.7rem",
                        textWrap: { md: "nowrap", xs: "wrap" },
                      }}
                    >
                      <Typography
                        className="subTitle"
                        sx={{ color: "#000 !important", fontWeight: "600" }}
                      >
                        {e.text6}
                      </Typography>
                    </Divider>
                  </Box>
                )}
              </>
            ))}
          </Box>
        </Container>

        <Box py={"2rem"}>
          <ProductNewsLetter
            text={
              "Have questions or need further clarification? You can reach us at:"
            }
          />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default GoDownload;
