import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import phone from "../../Assets/Images/phone_dark.svg";
import gmail from "../../Assets/Images/gmail_dark.svg";
import header from "../../Assets/Images/book/header.png";
import book_img from "../../Assets/Images/book/book_img.png";
import curve from "../../Assets/Images/book/curve.png";
import bp_icon from "../../Assets/Images/book/bp_icon.png";
import tag from "../../Assets/Images/book/tag.png";
import num from "../../Assets/Images/book/num.png";

const Book = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          //   mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_w": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#fff",
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid item lg={4} xs={12}>
            <Box
              sx={{
                background: "#782980",
                p: { md: "6rem", xs: "3rem" },
                position: "relative",
                height: "100%",
              }}
            >
              <Typography
                component={"img"}
                src={num}
                sx={{
                  position: "absolute",
                  top: "6rem",
                  left: "0",
                  width: { md: "auto", xs: "100%" },
                }}
              />
              <Box pt={"10rem"}>
                <Typography
                  sx={{
                    fontFamily: "AvenirLTStdBlack",
                    fontSize: "36px",
                    lineHeight: "normal",
                    color: "#fff",
                  }}
                >
                  CapnoLearning™
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "AvenirLTStdBlack",
                    fontSize: "27px",
                    lineHeight: "normal",
                    color: "#fff",
                  }}
                >
                  An Introductory Guide
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{
                    color: "#C8A7CB !important",
                    py: "1rem",
                  }}
                >
                  Breathing habits, optimal respiration,
                  <br />
                  and acid-base physiology.
                </Typography>
              </Box>
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  p: { md: "2rem 2.5rem", xs: "2rem 1.5rem" },
                  position: "relative",
                  
                }}
              >
                <Typography
                  component={"img"}
                  src={bp_icon}
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    right: "0",
                    width: "110px",
                  }}
                />
                <Typography
                  className="subTitle"
                  sx={{
                    color: "#782980 !important",
                    pb: "0.5rem",
                  }}
                >
                  Authored by,
                </Typography>

                <Typography component={"img"} src={header} width={"100%"} />
                <Typography
                  className="subTitle"
                  sx={{
                    py: "0.5rem",
                  }}
                >
                  <span style={{ color: "#782980", fontWeight: "bold" }}>
                    Peter M. Litchfield,
                  </span>{" "}
                  Ph.D.
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{
                    // pb:"0.5rem",
                    color: "#782980 !important",
                    fontWeight: "600",
                  }}
                >
                  Sandra Reamer
                </Typography>
                <Typography className="subTitle">
                  MFA, MS, CBBA, CBBP, CSOM, CBT
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                background: "#782980",
                p: { md: "6rem", xs: "3rem" },
                position: "relative",
                background:
                  "radial-gradient(circle, #782980 20%, #541D5A 100%)",
                height: "100%",
                width: "100%",
                display: "flex",
                gap: "2rem",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "10px",
                    p: { md: "2rem", xs: "2rem 1rem" },
                  }}
                >
                  <Typography className="subTitle_w">
                    Buy the book and support the <br />
                    <b>Breathing Science Journal</b> <br />
                    by making
                    <br />
                    <b>a tax-deductible{" "} <a style={{textDecoration:"none",color:"inherit"}} href="https://www.thebsj.org/capnolearning?ref=https%3A%2F%2Fwww.thebsj.org%2Fa%2F2147732394%2FGYhjaEjN" target="_blank" rel="noreferrer">purchase</a></b>
                    <br />
                    from the publisher.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "2rem",
                    border: "1px solid #fff",
                    borderRadius: "10px",
                    p: { md: "2rem", xs: "2rem 1rem" },
                  }}
                >
                  <Typography className="subTitle_w">
                    <b>Breathing Science, Inc.</b>
                    <br />
                    A 501(c)(3) nonprofit corporation <br />
                    Cheyenne, Wyoming USA
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundImage: `url(${tag})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100%",
                    backgroundSize: "cover",
                    width: "180px",
                    height: "90px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    m: "3rem auto auto",
                    position: "relative",
                  }}
                >
                  <Typography
                    component={"img"}
                    src={curve}
                    sx={{
                      display: { lg: "block", xs: "none" },
                      position: "absolute",
                      right: "-137px",
                      bottom: "44px",
                    }}
                  />
                  <Box
                    sx={{
                      ml: "-15px",
                      mt: "5px",
                    }}
                  >
                    <Typography className="subTitle_w">
                      <b> e-book</b>
                    </Typography>
                    <Typography
                      className="subTitle_w"
                      sx={{
                        fontSize: "32px !important",
                      }}
                    >
                      <b> $35</b>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  component={"img"}
                  src={book_img}
                  sx={{
                    width: {
                      md: "333px",
                      xs: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Container>
          <Box pt={"5rem"}>
            <Typography
              className="title"
              sx={{
                fontSize: "24px !important",
                pb: "1rem",
              }}
            >
              Breathing habits, optimal respiration, and acid-base physiology
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              Breathing is a much bigger subject than you might have ever
              imagined. It is about much more than simple mechanics, which are
              good or not so good, e.g., breathing with the diaphragm, or not.
              It is about much more than oxygen in and carbon dioxide out.
              Merely focusing on breathing techniques to make you feel better,
              e.g., slow breathing for relaxation, overlooks the enormity of
              role of breathing as behavior in health and performance.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              Breathing is behavior and is driven psychologically, not just
              physically. This means that we all learn breathing habits, bad
              ones and good ones. Good breathing optimizes respiration and
              balances acid-base physiology. It requires precision
              reflex-management of CO2 in the blood (and elsewhere) that
              regulates body pH from moment to moment. Bad breathing habits
              compromise this physiology and can trigger insidiously
              debilitating outcomes, e.g., a panic attack. Good breathing habits
              can optimize this physiology and enhance health and performance
              both immediately and in the long term, e.g., a successful public
              presentation.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              Breathing and respiration are not the same thing. Breathing is
              behavior. Respiration is reflexive. Breathing habits operate based
              on their triggers, motivation and psychological outcomes. When
              these habits are not aligned with respiratory chemistry, there is
              trouble, lots of it. Are your habits working for you, or against
              you? How are they affecting you? What can you do about them?
              Unfortunately, there are many misunderstandings about breathing
              and respiration that are the basis of misguided breathing
              self-help techniques everywhere.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              CapnoLearning brings together physiology, psychology, and
              technology to create powerful tools for identifying good and bad
              breathing habits, for learning to disengage habits that compromise
              physical and mental competence, and for learning new breathing
              habits that promote health and performance. Understanding
              breathing as behavior makes these objectives possible through
              implementing the principles and applications of breathing behavior
              analysis. This guide provides both an introduction to
              CapnoLearning and 14 different practicums using a CapnoTrainer.
            </Typography>
          </Box>
        </Container>
        <Box
          mt={"4.5rem"}
          mb={"2rem"}
          sx={{
            background: "#fff",
          }}
        >
          {/* <Container> */}
          <Grid container spacing={0}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
                //   justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  p: { sm: "3.5rem", xs: "3.5rem 1.5rem" },
                  "@media (min-width:1600px)": {
                    pl: "12rem",
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    //   lineHeight: { md: "50px !important" },
                    fontSize: { lg: "55px !important" },
                  }}
                >
                  Curious to
                  <br /> Learn More?
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{ width: { lg: "80%" }, pt: "1rem" }}
                >
                  Have questions or want more details about the CapnoLearning:
                  An Introductory Guide? We're here to help! Whether you're
                  curious about the book's contents, how it can benefit you, or
                  if you need assistance with your order, feel free to reach
                  out. Our team is happy to assist you with any queries you
                  have.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "-2px 1px 5px rgba(0, 0, 0, 0.3)",
                  height: "100%",
                  // p:"1rem"
                }}
              >
                <Box
                  sx={{
                    // background: "#782980",
                    // borderRadius: "10px",
                    p: { sm: "2rem 3rem", xs: "2rem 1.5rem" },
                    display: "grid",
                    "& .img_box": {
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      // border: "1px solid #C8A7CB",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    },
                    "& .text_1": {
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                      opacity: "0.7",
                    },
                    "& .text_2": {
                      color: "#49194E",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .main_box": {
                      display: "flex",
                      gap: "10px",
                      my: "1rem",
                      alignItems: "center",
                    },
                    "& a": {
                      textDecoration: "none",
                    },
                    "& .main_wrapper": {
                      // p: "0.5rem",
                      // transition: "0.3s all",
                      // borderRadius: "5px",
                      // "&:hover": {
                      //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                      // },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdRoman",
                      pb: { sm: "1rem", xs: "1rem" },
                      // letterSpacing: "2px",
                    }}
                  >
                    You can reach us at:
                  </Typography>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Tel Support</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Sales & Product Inquiries
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:instruments@betterphysiology.com"
                        >
                          instruments@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Tech Support & Assistance
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:tech@betterphysiology.com"
                        >
                          tech@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* </Container> */}
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Book;
