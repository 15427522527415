import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import training from "../../Assets/Images/training/training.png";
import chat from "../../Assets/Images/training/icon_1.svg";
import user from "../../Assets/Images/training/icon_2.svg";
import CommonSolutionSection from "../../Components/CommonSolutionSection";
import book from "../../Assets/Images/common/book.png";
import interview from "../../Assets/Images/common/interview.png";
import course from "../../Assets/Images/common/course.png";
import lecture from "../../Assets/Images/common/lecture.png";
import training_1 from "../../Assets/Images/training/training_1.png";
import training_2 from "../../Assets/Images/training/training_2.png";
import training_3 from "../../Assets/Images/training/training_3.png";
import training_4 from "../../Assets/Images/training/training_4.png";
import training_5 from "../../Assets/Images/training/training_5.png";
import training_6 from "../../Assets/Images/training/training_6.png";
import phone from "../../Assets/Images/phone_dark.svg";
import website from "../../Assets/Images/website.svg";
import video from "../../Assets/Images/video.svg";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component={"img"}
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const Training = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
          },
          // "& .grid": {
          //   mt: { md: "-60px", xs: "0" },
          // },
          "& .main_box": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            background: "#fff",
            p: "5px 15px",
            borderRadius: "5px",
            my: "1rem",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={4} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={7} xs={12}>
              <Typography className="title" pb={"1rem"}>
                Training
              </Typography>
              <Typography className="subTitle" sx={{ width: { md: "80%" } }}>
                The Professional School Of Behavioral Health Sciences offers a
                Professional Certificate in the Science of Breathing Behavior
                Analysis, Certification as Certified Breathing Behavior Analyst,
                and a Professional Diploma in Behavioral Breathing Science to
                qualified applicants.
              </Typography>
              <Box
                mt={"2rem"}
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  width: { lg: "90%" },
                }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        p: "1rem",
                      }}
                    >
                      <Typography
                        className="title"
                        sx={{
                          lineHeight: { md: "50px !important" },
                        }}
                      >
                        If you are <br />
                        interested in <br />
                        formal training,
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box
                      sx={{
                        background: "#fff",
                        boxShadow: "-2px 1px 5px rgba(0, 0, 0, 0.3)",
                        borderRadius: {
                          md: "0 10px 10px 0",
                          xs: "0px 0 10px 10px",
                        },
                        // p:"1rem"
                      }}
                    >
                      <Box
                        sx={{
                          // background: "#782980",
                          // borderRadius: "10px",
                          // p: "2rem 3rem",
                          display: "grid",
                          "& .img_box": {
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            // border: "1px solid #C8A7CB",
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                          },
                          "& .text_1": {
                            color: "#782980",
                            fontSize: "16px",
                            fontFamily: "AvenirLTStdBook",
                          },
                          "& .text_2": {
                            color: "#49194E",
                            fontSize: "16px",
                            fontFamily: "AvenirLTStdBook",
                          },
                          "& .main_box": {
                            display: "flex",
                            gap: "10px",
                          },
                          "& a": {
                            textDecoration: "none",
                          },
                          "& .main_wrapper": {
                            // p: "0.5rem",
                            // transition: "0.3s all",
                            // borderRadius: "5px",
                            // "&:hover": {
                            //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                            // },
                          },
                        }}
                      >
                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={phone} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">Call</Typography>
                              <Typography className="text_2">
                                +1.800.850.9795
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={website} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">Visit</Typography>
                              <Typography
                                className="text_2"
                                component={"a"}
                                target="_blank"
                                href="https://www.bp.edu"
                              >
                                www.bp.edu
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="main_wrapper">
                          <Box className="main_box">
                            <Typography className="img_box">
                              <Typography component={"img"} src={video} />
                            </Typography>
                            <Box>
                              <Typography className="text_1">
                                Zoom appointment
                              </Typography>
                              <Typography
                                className="text_2"
                                component={"a"}
                                target="_blank"
                                href="https://e-campus-registration.bp.edu/"
                              >
                                www.e-campus.bp.edu
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item md={5} xs={12}>
              <Box
                sx={{
                  lineHeight: "0",
                  position: "relative",
                  background: "#C8A7CB",
                  borderRadius: "10px",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                  textAlign: "center",
                }}
              >
                {/* Icon Boxes */}
                <IconBox
                  src={chat}
                  sx={{
                    top: { md: "10px", xs: "0px" },
                    left: { md: "-65px", xs: "-20px" },
                  }}
                />
                <IconBox
                  src={user}
                  sx={{
                    bottom: { md: "-30px", xs: "-10px" },
                    right: { md: "-30px", xs: "-10px" },
                  }}
                />

                {/* Demo Image */}
                <Typography
                  component={"img"}
                  src={training}
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                    left: "-10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{
              mt: { lg: "20px", md: "20px", xs: "0" },
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: { lg: "3rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p": {
                    fontFamily: "AvenirLTStdBook !important",
                  },
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Typography className="title" pb={"0.5rem"}>
                  An Unseen Problem
                </Typography>
                <Typography className="subTitle">
                  Statistics suggest that tens of millions of people worldwide
                  suffer from the profound and misunderstood symptoms and
                  deficits of learned dysfunctional breathing habits.
                  Unfortunately, these habits are rarely identified by
                  practitioners, their effects mistakenly attributed to other
                  causes, and their resolutions prescriptive in nature where the
                  focus is on symptoms rather than on causes.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "180px", xs: "70px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "23%",
                    left: "-25px",
                    p: "0.5rem",
                    transform: "translateY(-23%)",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: "#782980",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: {
                      lg: "318px",
                      md: "280px",
                      sm: "300px",
                      xs: "200px",
                    },
                    height: {
                      lg: "440px",
                      md: "380px",
                      sm: "410px",
                      xs: "230px",
                    },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "20%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "3rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        component={"img"}
                        src={training_1}
                        sx={{ width: { lg: "100%", xs: "100%" } }}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box>
                        <Typography
                          component={"img"}
                          src={training_2}
                          sx={{ width: { lg: "92%", xs: "100%" } }}
                        />
                      </Box>
                      <Box mt={"0.8rem"}>
                        <Typography
                          component={"img"}
                          src={training_3}
                          sx={{ width: { lg: "92%", xs: "100%" } }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{
              mt: { lg: "0", md: "20px", xs: "0" },
            }}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: { lg: "3rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p": {
                    fontFamily: "AvenirLTStdBook !important",
                  },
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Typography className="title" pb={"0.5rem"}>
                  Our Solution in Action
                </Typography>
                <Typography className="subTitle">
                  The Professional School,
                </Typography>
                <ul>
                  <Typography className="subTitle" component={"li"}>
                    trains healthcare practitioners, human service
                    professionals, health educators, and performance enhancement
                    consultants to integrate breathing learning services into
                    their practices and businesses.
                  </Typography>
                  <Typography
                    className="subTitle"
                    component={"li"}
                    pt={"0.2rem"}
                  >
                    trains breathing specialists, e.g., breathworkers, to
                    integrate the principles of behavior analysis into their
                    practices.
                  </Typography>
                  <Typography
                    className="subTitle"
                    component={"li"}
                    pt={"0.2rem"}
                  >
                    trains high performance professionals such as athletes,
                    performers, artists, and pilots, to bring breathing science
                    applications into their work. To this end, the School offers
                    two breathing behavior analysis programs.
                  </Typography>
                </ul>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "180px", xs: "70px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    bottom: { lg: "5%", xs: "15%" },
                    left: "-25px",
                    p: "0.5rem",
                    transform: {
                      lg: "translateY(-5%)",
                      xs: "translateY(-15%)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: "#782980",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: {
                      lg: "318px",
                      md: "280px",
                      sm: "300px",
                      xs: "200px",
                    },
                    height: {
                      lg: "440px",
                      md: "380px",
                      sm: "410px",
                      xs: "230px",
                    },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "20%",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "4rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        component={"img"}
                        src={training_4}
                        sx={{ width: { lg: "100%", xs: "100%" } }}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box>
                        <Typography
                          component={"img"}
                          src={training_5}
                          sx={{ width: { lg: "95%", xs: "100%" } }}
                        />
                      </Box>
                      <Box mt={"0.8rem"}>
                        <Typography
                          component={"img"}
                          src={training_6}
                          sx={{ width: { lg: "95%", xs: "100%" } }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box pt={"2rem"}>
          <CommonSolutionSection
            text={
              <>
                Explore <br /> Our
                <br /> Other <br />
                Resources
              </>
            }
            btn_1={"Course"}
            btn_2={"Book"}
            btn_3={"Interviews"}
            btn_4={"Lectures"}
            img_1={course}
            img_2={book}
            img_3={interview}
            img_4={lecture}
            link_1={"/course"}
            link_2={"/book"}
            link_3={"/interview"}
            link_4={"/lectures"}
          />
        </Box>
        <Box
          pb={"2rem"}
          sx={{
            "& a": {
              fontWeight: "500 !important",
            },
          }}
        >
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Training;
