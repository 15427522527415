import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import EastIcon from "@mui/icons-material/East";
import FaqModal from "./FaqModal";

const FaqComponent = ({ data }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Box>
      <Typography className="subTitle" sx={{
        display:"inline-block"
      }} onClick={()=>setOpenModal(true)}>
        {data?.question}{" "}
        <EastIcon
          sx={{
            verticalAlign: "middle",
          }}
        />
      </Typography>
      <FaqModal setOpenModal={setOpenModal} openModal={openModal} data={data} />
    </Box>
  );
};

export default FaqComponent;
