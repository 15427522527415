import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

const Title = ({text}) => {
  return (
    <Box
    sx={{
      background: "#fff",
      p: {sm:"2rem 2rem 0",xs:"1rem 1rem 0"},
      borderRadius: "10px",
      "& .MuiDivider-root::before": {
        width: "0%",
      },
      "& .MuiDivider-root::after": {
        borderTop: "1.5px solid #782980",
      },
      "& .MuiDivider-wrapper": {
        pl: "0",
      },
      "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: {sm:"16px",xs:"16px"},
            fontWeight: "600",
            color: "#782980",
          },
    }}
  >
    <Divider textAlign="left">
    <Typography className="title">Distributors in {text}</Typography>
  </Divider>
  </Box>
  )
}

export default Title