import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ServicesSection from "./ServicesSection";
import GoBackButton from "../../Components/GoBackButton";

const GoBrochure = () => {
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .grid": {
            mt: { md: "-60px", xs: "0" },
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Box
            sx={{
              mt:"2rem",
              display: "flex",
              gap: "0.5rem",
              justifyContent: { sm: "space-between", xs: "center" },
              flexWrap: "wrap",
            }}
          >
            <Typography className="title" pb={"1rem"}>
              GO Brochure
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                pr: { lg: "8px" },
                alignSelf: "start",
                "&:hover": {
                  "& .hoverable": {
                    transform: "translateX(8px)",
                  },
                },
              }}
            >
              <Button
                className={"btn"}
                sx={{
                  fontSize: "16px",
                  textTransform: "capitalize",
                  //   width: "120px",
                  fontFamily: "AvenirLTStdBook",
                }}
                component="a"
                href="https://better-physiology-videos.s3.amazonaws.com/CapnoTrainer+GO+Brochure.pdf"
                target="_blank"
              >
                Download Brochure
              </Button>
              <Button
                className={"btn hoverable"}
                component="a"
                href="https://better-physiology-videos.s3.amazonaws.com/CapnoTrainer+GO+Brochure.pdf"
                target="_blank"
                sx={{ transition: "0.3s all" }}
              >
                <ArrowForwardIcon sx={{ fontSize: "1.3rem" }} />
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#C8A7CB",
              p: { sm: "3rem", xs: "1rem" },
              borderRadius: "10px",
              mt: "1rem",
              mb: "3rem",
            }}
          >
            <embed
              src="/CapnoTrainer_GO_Brochure.pdf#toolbar=0"
              type="application/pdf"
              width="100%"
              height="700"
            ></embed>
          </Box>
        </Container>
        <Box pt={"2rem"}>
         <ServicesSection/>
        </Box>
        <Box pb={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight/>
        </Container>
      </Box>
    </>
  );
};

export default GoBrochure;
