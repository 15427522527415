import React from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import CopyRight from "../../Components/CopyRight";
import estd from "../../Assets/Images/history/estb.png";
import capno_kit from "../../Assets/Images/history/capno_kit.png";
import map from "../../Assets/Images/history/map.png";
import vertical from "../../Assets/Images/vertical.svg";
import NewsLetter from "../../Components/NewsLetter";
import GoBackButton from "../../Components/GoBackButton";

const History = () => {
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title,.title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "24px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_": {
            color: "#fff",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
        }}
      >
        <Container>
        <GoBackButton/>

          <Grid container spacing={1}>
            <Grid
              item
              sm={4.5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "start",
                mt: { md: "3rem" },
              }}
            >
              <Box>
                <Typography className="title" pb={"1rem"}>
                  Our Journey in Revolutionizing <br /> Breathing Science
                </Typography>

                <Typography
                  className="subTitle"
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <Typography component={"img"} src={vertical} />
                  BetterPhysiology has been dedicated to advancing the science
                  of breathing for health and performance.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={3}
              xs={12}
              sx={{
                textAlign: { md: "inherit", xs: "center" },
                alignSelf: "center",
              }}
            >
              <Typography
                component={"img"}
                src={estd}
                sx={{ width: { sm: "80%", xs: "60%" } }}
              />
            </Grid>
            <Grid
              item
              sm={4.5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "end",
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  p: "1rem",
                  mb: { md: "3.5rem", xs: "2rem" },
                }}
              >
                <Typography className="title" pb={"1rem"}>
                  <Typography
                    sx={{
                      height: "3.25px",
                      width: "40%",
                      background: "#782980",
                      mb: "10px",
                    }}
                  />
                  Incorporated in May 2000,
                </Typography>
                <Typography className="subTitle">
                  BetterPhysiology Ltd is a privately held USA Wyoming
                  corporation owned by 52 stockholders from around the world,
                  all of whom subscribe to the Company mission.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              md={5.5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "end",
              }}
            >
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  p: "1rem",
                  mb: { sm: "0rem", xs: "1rem" },
                }}
              >
                <Typography className="title" pb={"1rem"}>
                  Foundation and Purpose
                </Typography>
                <Typography className="subTitle">
                  Better Physiology Ltd was founded to address the widespread
                  issue of dysfunctional breathing habits. Millions of people
                  suffer from symptoms and deficits caused by these habits,
                  which are often misunderstood, misdiagnosed, and treated
                  symptomatically rather than addressing the root causes.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid
              item
              sm={8}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  // background:"#fff",
                  borderRadius: "10px",
                  p: "1rem",
                }}
              >
                <Typography className="title" pb={"1rem"}>
                  CapnoTrainer® Development
                </Typography>
                <Typography className="subTitle">
                  The CapnoTrainer® was developed as a tool to help
                  practitioners and their clients identify, explore, unlearn,
                  and learn new breathing habits. This innovative device uses
                  live and recorded presentations of breathing physiology based
                  on the principles of behavioral analysis.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={4}
              xs={12}
              sx={{
                textAlign: "center",
                // alignSelf:"center"
              }}
            >
              <Typography
                component={"img"}
                src={capno_kit}
                sx={{ width: { sm: "90%", xs: "100%" } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid
              item
              sm={7.5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  // background:"#fff",
                  borderRadius: "10px",
                  p: "1rem",
                }}
              >
                <Typography className="title" pb={"1rem"}>
                  Global Reach
                </Typography>
                <Typography className="subTitle">
                  Better Physiology has established a global presence, with
                  distributors located across Asia, Australia, Europe, North
                  America, and South America. Many of these distributors also
                  provide training and expertise in CapnoLearning.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sm={4.5}
              xs={12}
              sx={{
                textAlign: "center",
                // alignSelf:"center"
              }}
            >
              <Typography
                component={"img"}
                src={map}
                sx={{ width: { sm: "100%", xs: "100%" } }}
              />
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            background:
              "linear-gradient(306deg, rgba(185,63,197,1) 0%, rgba(120,41,128,1) 47%, rgba(227,142,235,1) 100%)",
            p: {sm:"1rem 2rem 4rem",xs:"1rem 0rem 4rem"},
            mt: "1rem",
          }}
        >
          <Container>
            <Typography className="title_" p={"2rem 1rem"}>
              Diverse Practitioners and Organizations
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "10px",
                    p: "4rem 3rem",
                    mb: { sm: "0rem", xs: "1rem" },
                    height: "100%",
                  }}
                >
                  <Typography className="title" pb={"1rem"}>
                    Customer Practitioners
                  </Typography>
                  <Typography
                    className="subTitle"
                    sx={{
                      width: { lg: "80%" },
                    }}
                  >
                    Practitioners from over 60 countries, including a wide range
                    of {" "}
                    <b>
                      health, human service, and performance training
                      professions,
                    </b>{" "}
                    utilize Better Physiology's tools and training methods.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "10px",
                    p: "4rem 3rem",
                    mb: { sm: "0rem", xs: "1rem" },
                  }}
                >
                  <Typography className="title" pb={"1rem"}>
                    Customer Organizations
                  </Typography>
                  <Typography
                    className="subTitle"
                    sx={{
                      width: { lg: "80%" },
                    }}
                  >
                    Customer organizations include a diverse array of {" "}
                    <b>
                      athletic clubs, clinics, consulting firms, corporations,
                      fitness centers, government agencies, hospitals, military
                      and police departments, private practices, schools,
                      universities, and yoga centers.
                    </b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container>
          <Grid container spacing={1}>
            <Grid
              item
              md={7.5}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "end",
              }}
            >
              <Box
                sx={{
                  //    background:"#fff",
                  borderRadius: "10px",
                  p: "1rem",
                  mb: "2rem",
                  mt: "3rem",
                }}
              >
                <Typography className="title" pb={"1rem"}>
                  Personal Users
                </Typography>
                <Typography className="subTitle">
                  Better Physiology’s products are also used by individuals from
                  diverse backgrounds, especially those interested in improving
                  their own breathing habits to enhance personal health and
                  performance.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
          <NewsLetter/>
         <Container>
         <Footer display="none" padding="0" />
         <CopyRight />
         </Container>
      </Box>
    </>
  );
};

export default History;
