import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Contact from "../Pages/Contact/Contact";
import Distributors from "../Pages/Distributors/Distributors";
import History from "../Pages/History/History";
import Mission from "../Pages/Mission/Mission";
import GoOverview from "../Pages/GoOverview/GoOverview";
import GoBrochure from "../Pages/GoBrochure/GoBrochure";
import GoDemo from "../Pages/GoDemo/GoDemo";
import GoDownload from "../Pages/GoDownload/GoDownload";
import GoManual from "../Pages/GoManual/GoManual";
import GoQuickstart from "../Pages/GoQuickstart/GoQuickstart";
import Instruments from "../Pages/Instruments/Instruments";
import GoBuy from "../Pages/Product/GoBuy";
import ProductDetail from "../Pages/Product/ProductDetail";
import Consultation from "../Pages/Consulation/Consultation";
import Cart from "../Pages/Cart/Cart";
import BookOrder from "../Pages/BookOrder/BookOrder";
import GoSupport from "../Pages/GoSupport/GoSupport";
import GoVideo from "../Pages/GoVideo/GoVideo";
import Training from "../Pages/Training/Training";
import Interviews from "../Pages/Interviews/Interviews";
import Lectures from "../Pages/Lectures/Lectures";
import References from "../Pages/References/References";
import ProfessionalSchool from "../Pages/ProfessionalSchool/ProfessionalSchool";
import Subscribe from "../Pages/Subscribe/Subscribe";
import Faq from "../Pages/Faq/Faq";
import Course from "../Pages/Course/Course";
import Book from "../Pages/Book/Book";
import ScrollToTop from "../Components/ScrollToTop";
import FounderPage from "../Pages/Founder/FounderPage";


function AllRoutes() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/distributors" element={<Distributors />} />
        <Route path="/history" element={<History />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/go-overview" element={<GoOverview />} />
        <Route path="/go-brochure" element={<GoBrochure />} />
        <Route path="/go-demo" element={<GoDemo />} />
        <Route path="/go-download" element={<GoDownload />} />
        <Route path="/go-manual" element={<GoManual />} />
        <Route path="/go-quickstart" element={<GoQuickstart />} />
        <Route path="/download-5.0" element={<Instruments />} />
        <Route path="/go-buy" element={<GoBuy />} />
        <Route path="/go-detail/:id" element={<ProductDetail />} />
        <Route path="/consultation" element={<Consultation />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/place-order" element={<BookOrder />} />
        <Route path="/go-support" element={<GoSupport />} />
        <Route path="/go-video" element={<GoVideo />} />
        <Route path="/training" element={<Training />} />
        <Route path="/interview" element={<Interviews />} />
        <Route path="/lectures" element={<Lectures />} />
        <Route path="/references" element={<References />} />
        <Route path="/professional-education" element={<ProfessionalSchool />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/course" element={<Course />} />
        <Route path="/book" element={<Book />} />
        <Route path="/founder" element={<FounderPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
