import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { motion } from 'framer-motion';

const fadeDown = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
};

const fadeUp = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0 },
};

const fadeLeft = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0 },
};

const SliderBox = ({ e, isActive }) => {
  return (
    <Container>
      <motion.div
        initial="hidden"
        animate={isActive ? "visible" : "hidden"}
        transition={{ duration: 0.8 }}
        variants={fadeDown}
      >
        <Typography
          sx={{
            color: "#C8A7CB",
            fontSize: {lg:"80px",sm:"50px",xs:"40px"},
            fontFamily: "AvenirLTStdRoman",
            fontWeight: "600",
            lineHeight: "normal",
            width: { lg: "900px" },
            textAlign:{sm:"start",xs:"center"}
          }}
        >
          {e.title}
        </Typography>
      </motion.div>

      <Grid container spacing={3} my={"1rem"}>
        <Grid item md={6} sm={6} xs={12} textAlign={"center"}>
          <motion.div
            initial="hidden"
            animate={isActive ? "visible" : "hidden"}
            transition={{ duration: 0.5, delay: 0.5 }}
            variants={fadeUp}
          >
            <Typography
              component={"img"}
              src={e.img}
              width={e.width ? e.width : "90%"}
            />
          </motion.div>
        </Grid>
        <Grid
          item
          md={6}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <motion.div
            initial="hidden"
            animate={isActive ? "visible" : "hidden"}
            transition={{ duration: 0.8, delay: 0.5 }}
            variants={fadeLeft}
          >
            <Typography
              sx={{
                color: "#F0EDF6",
                fontSize: {sm:"30px",xs:"20px"},
                fontFamily: "AvenirLTStdRoman",
                textAlign: {sm:"end",xs:"center"},
                pb:"1rem",
                width:{md:"95%"}
              }}
            >
              {e.para}
            </Typography>
          </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SliderBox;
