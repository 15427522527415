import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import icon_1 from "../../Assets/Images/course/icon_1.svg";
import icon_2 from "../../Assets/Images/course/icon_2.svg";
import video_btn from "../../Assets/Images/course/video_btn.png";
import phone from "../../Assets/Images/phone_dark.svg";
import gmail from "../../Assets/Images/gmail_dark.svg";
import course_img from "../../Assets/Images/course/course_img.png";
import img_1 from "../../Assets/Images/course/img_1.png";
import img_2 from "../../Assets/Images/course/img_2.png";
import img_3 from "../../Assets/Images/course/img_3.png";
import img_4 from "../../Assets/Images/course/img_4.png";
import img_5 from "../../Assets/Images/course/img_5.png";
import img_6 from "../../Assets/Images/course/img_6.png";
import img_7 from "../../Assets/Images/course/img_7.png";
import img_8 from "../../Assets/Images/course/img_8.png";
import GoBackButton from "../../Components/GoBackButton";

const courseData = [
  {
    text: "Learning basic respiratory psychophysiology",
    image: img_1,
  },
  {
    text: "Using CapnoTrainer technology",
    image: img_2,
  },
  {
    text: "Applying methods for identifying breathing habits",
    image: img_3,
  },
  {
    text: "Learning techniques for disengaging troublesome breathing habits",
    image: img_4,
  },
  {
    text: "Learning techniques for acquiring new breathing habits",
    image: img_5,
  },
  {
    text: "Using self-interventions for managing symptoms triggered by habits",
    image: img_6,
  },
  {
    text: "Aligning breathing mechanics with respiratory chemistry",
    image: img_7,
  },
  {
    text: "Restoring healthy CO2 concentrations for improving performance",
    image: img_8,
  },
];

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component={"img"}
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const Course = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_c": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
          },
          "& .subTitle_w": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#fff",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box>
                <Typography
                  className="title"
                  sx={{
                    color: "#C8A7CB !important",
                  }}
                >
                  An Audio and Video Recorded Course
                </Typography>
                <Typography
                  sx={{
                    color: "#782980",
                    fontFamily: "BakbakOneRegular",
                    fontSize: { md: "70px", xs: "40px" },
                  }}
                >
                  CapnoLearning™
                </Typography>
                <Typography className="subTitle_c">
                  Presented By
                  <br />
                  Peter M. Litchfield, Ph.D.
                  <br />
                  Sandra Reamer, MFA, MS, CBBA, CBBP, CSOM, CBT
                </Typography>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={6} xs={12} textAlign={"center"}>
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <IconBox
                  src={icon_1}
                  sx={{
                    bottom: { md: "10px", xs: "10px" },
                    left: { md: "15px", xs: "15px" },
                  }}
                />
                <IconBox
                  src={icon_2}
                  sx={{
                    bottom: { md: "10px", xs: "10px" },
                    right: { md: "5px", xs: "5px" },
                  }}
                />
                <Typography
                  component="img"
                  src={course_img}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              <Typography component={"img"} src={video_btn} width={"200px"} />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ my: { md: "2rem", xs: "2rem" } }}>
            <Grid item md={8} xs={12}>
              <Typography className="subTitle" pb={"1rem"}>
                Learn about the principles of Breathing Behavior Analysis and
                their practical
                <br /> implementation for optimizing respiration and acid-base
                physiology, including:
              </Typography>
              <Grid container spacing={4}>
                {courseData?.map((data, i) => (
                  <Grid item md={6} xs={12} key={i}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "7px",
                          height: "7px",
                          borderRadius: "50%",
                          background: "#782980",
                          mr: "8px",
                        }}
                      />
                      <Typography className="subTitle" sx={{ flex: 1 }}>
                        {data.text}
                      </Typography>
                      <Typography
                        component={"img"}
                        src={data.image}
                        sx={{ width: "100%", maxWidth: "220px" }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  background: "#782980",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                  p: { md: "2rem", xs: "1.5rem 1rem" },
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "10px",
                    p: { md: "2rem", xs: "2rem 1rem" },
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      p: "0.5rem 1rem",
                      background: "#C8A7CB",
                      borderRadius: "7px",
                      width: "106px",
                      textAlign: "center",
                      lineHeight: "0",
                      position: "absolute",
                      bottom: "-1px",
                      right: "-1px",
                    }}
                  >
                    <Typography
                      className="title"
                      sx={{
                        fontSize: "20px !important",
                        lineHeight: "normal",
                      }}
                    >
                      $50.00
                    </Typography>
                  </Box>
                  <Typography className="subTitle_w">CE Option:</Typography>

                  <Typography
                    sx={{
                      color: "#C8A7CB",
                      fontSize: { md: "64px", xs: "35px" },
                      lineHeight: "normal",
                      fontFamily: "AvenirLTStdBlack",
                    }}
                  >
                    10 Hours
                  </Typography>
                  <Typography
                    className="subTitle_w"
                    sx={{
                      mt: { md: "-10px", xs: "-5px" },
                    }}
                  >
                    of professional continuing education credit from the
                    Professional School of Behavioral Health Sciences
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: "3rem",
                    mb: "2rem",
                    border: "1px solid #fff",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    className="subTitle_w"
                    sx={{
                      p: { md: "2rem", xs: "2rem 1rem" },
                    }}
                  >
                    Buy the course and support
                    <br />
                    the <b>Breathing Science Journal</b> <br />
                    by making <br />
                    <b>a tax-deductible{" "} <a style={{textDecoration:"none",color:"inherit"}} href="https://www.thebsj.org/offers/DpW8m8MA/checkout" target="_blank" rel="noreferrer">purchase</a></b> from the publisher.
                    <br />
                    ($199.00)
                  </Typography>
                  <Box
                    sx={{
                      p: { md: "2rem", xs: "2rem 1rem" },
                      background: "#C8A7CB",
                      borderTop: "1px solid #fff",
                      borderRadius: "10px 10px 10px 10px",
                    }}
                  >
                    <Typography
                      className="subTitle_w"
                      sx={{
                        color: "#000 !important",
                      }}
                    >
                      The eBook is included in the price of the course.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #fff",
                    borderRadius: "10px",
                    p: { md: "2rem", xs: "2rem 1rem" },
                  }}
                >
                  <Typography className="subTitle_w">
                    <b>Publisher</b>
                    <br />
                    <b>Breathing Science, Inc.</b>
                    <br />A 501(c)(3) non profit corporation Cheyenne, Wyoming
                    USA
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box pt={"2rem"}>
            <Typography
              className="title"
              sx={{
                fontSize: "24px !important",
                pb: "1rem",
              }}
            >
              Breathing habits, optimal respiration, and acid-base physiology
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              Breathing Science, Inc., a 501(c)(3) nonprofit USA corporation and
              publisher of the Breathing Science Journal{" "}
              <a
                style={{ fontWeight: "bold", color: "#782980" }}
                href="https://www.thebsj.org/"
                target="_blank"
                rel="noreferrer"
              >
                www.theBSJ.org
              </a>{" "}
              , is pleased to offer you an eye-opening 10-hour on-line course
              based on the book, <b>CapnoLearning: An Introductory Guide</b>{" "}
              authored by Peter M Litchfield and Sandra Reamer. Learn all about
              breathing habits, optimal respiration, and acid-base physiology
              from a practical perspective, personal and professional.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              The authors read their book to you, 51 very short chapters,
              chapter by chapter, and then speak to you about specific practical
              applications, e.g., pregnancy and performance, based on the book
              in a series of ten short videos. The course includes description
              of 14 practicums which are templates for designing solutions to
              your own and/or your client's specific breathing challenges.
              Listening and viewing times will be tracked so that when you have
              completed (that is, listened to) each chapter as well as having
              watched the ten videos, you will immediately receive a Certificate
              of Attendance from us (Breathing Science, Inc.).
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              You will also receive by email an option to earn 10 hours of{" "}
              <b>Professional Continuing Education (CE) Credit</b> and receive a{" "}
              <b>Certificate of Completion</b> and an <b>Academic Transcript</b>{" "}
              documenting your credit from the{" "}
              <b>Professional School of Behavioral Health Sciences</b>, a USA
              state licensed post-secondary professional school. Earning this
              credit requires completion of a multiple-choice examination
              administered by the Professional School. If you pass the
              examination, you will be requested to complete an on-line
              registration form and to make payment of a $50.00 tuition fee.
              Upon submission of the registration, you will immediately receive
              both the <b>Certificate of Completion</b> and an{" "}
              <b>official transcript</b> from the Professional School. If you
              fail the examination you may continue to take it until you pass.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              CapnoLearning is best accomplished by using CapnoTrainer GO
              technology where the book practicums are built into the software
              (and for which there is a patent pending), but can also be
              accomplished with generic capnometers or to some extent even
              without accompanying technology. The practicum chapters in the
              book and the practical applications described in the videos refer
              specifically to CapnoTrainer GO technology manufactured by Better
              Physiology Ltd. Visit{" "}
              <a
                style={{ fontWeight: "bold", color: "#782980" }}
                href="https://www.betterphysiology.com/"
                target="_blank"
                rel="noreferrer"
              >
                www.betterphysiology.com
              </a>{" "}
              for Information about this technology and its purchase, or email
              your questions to{" "}
              <a
                style={{ fontWeight: "bold", color: "#782980" }}
                href="mailto:instruments@betterphysiology.com"
              >
                instruments@betterphysiology.com
              </a>{" "}
              . Visit{" "}
              <a
                style={{ fontWeight: "bold", color: "#782980" }}
                href="https://www.respimatters.com/"
                target="_blank"
                rel="noreferrer"
              >
                www.CapnoLearning.org
              </a>{" "}
              for rental of this same technology and/or for appointments with
              Breathing Behavior Analysts who can provide you with CapnoLearning
              professional consultation.
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              You may be wondering, “can I benefit from the course without using
              technology?” The answer, of course, is a resounding YES. The
              ultimate objective of breathing behavior analysis work is NOT
              about learning to use technology for manipulating breathing with
              physical techniques, i.e., an outside-in paradigm, but rather it
              is about learning new breathing habits that automatically optimize
              respiration, that is, aligning breathing with respiration based on
              the principles of self-regulation, an inside-out paradigm. The
              CapnoTrainer GO technology is a powerful tool for making this
              transition from an outside-in to an inside-out paradigm, that is,
              for identifying existing habits and learning new ones.
            </Typography>
          </Box>
        </Container>
        <Box
          mt={"4.5rem"}
          mb={"2rem"}
          sx={{
            background: "#fff",
          }}
        >
          {/* <Container> */}
          <Grid container spacing={0}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
                //   justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  p: { sm: "3.5rem", xs: "3.5rem 1.5rem" },
                  "@media (min-width:1600px)": {
                    pl: "12rem",
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    //   lineHeight: { md: "50px !important" },
                    fontSize: { lg: "55px !important" },
                  }}
                >
                  Want to <br />
                  Explore More?
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{ width: { lg: "80%" }, pt: "1rem" }}
                >
                  Excited to learn more about our Audio & Video Course on
                  Breathing Behavior Analysis? We're thrilled to help you take
                  your understanding of optimal respiration to the next level.
                  Whether you're a health professional, coach, or simply someone
                  interested in improving your breathing habits, our course
                  provides practical tools and insights that you can easily
                  incorporate into your daily life.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "-2px 1px 5px rgba(0, 0, 0, 0.3)",
                  height: "100%",
                  // p:"1rem"
                }}
              >
                <Box
                  sx={{
                    // background: "#782980",
                    // borderRadius: "10px",
                    p: { sm: "2rem 3rem", xs: "2rem 1.5rem" },
                    display: "grid",
                    "& .img_box": {
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      // border: "1px solid #C8A7CB",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    },
                    "& .text_1": {
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                      opacity: "0.7",
                    },
                    "& .text_2": {
                      color: "#49194E",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .main_box": {
                      display: "flex",
                      gap: "10px",
                      my: "1rem",
                      alignItems: "center",
                    },
                    "& a": {
                      textDecoration: "none",
                    },
                    "& .main_wrapper": {
                      // p: "0.5rem",
                      // transition: "0.3s all",
                      // borderRadius: "5px",
                      // "&:hover": {
                      //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                      // },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdRoman",
                      pb: { sm: "1rem", xs: "1rem" },
                      // letterSpacing: "2px",
                    }}
                  >
                    You can reach us at:
                  </Typography>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Tel Support</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Sales & Product Inquiries
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:instruments@betterphysiology.com"
                        >
                          instruments@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Tech Support & Assistance
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:tech@betterphysiology.com"
                        >
                          tech@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* </Container> */}
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Course;
