import React from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CopyRight from "../../Components/CopyRight";
import NewsLetter from "../../Components/NewsLetter";
import mission_1 from "../../Assets/Images/mission/mission_1.png";
import mission_2 from "../../Assets/Images/mission/mission_2.png";
import mission_3 from "../../Assets/Images/mission/mission_3.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import GoBackButton from "../../Components/GoBackButton";

const Mission = () => {
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title,.title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_": {
            color: "#fff",
          },
          "& .title_1": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle,.subTitle_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_": {
            color: "#C8A7CB",
            fontWeight: "600",
          },
          "& .grid": {
            mt: { md: "-60px", xs: "0" },
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Box pb={"2rem"} mt={"2rem"}>
            <Typography className="title" pb={"1rem"}>
              Our Mission
            </Typography>

            <Typography
              className="subTitle"
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              At BetterPhysiology, we merge physiology, psychology, and
              technology to create powerful learning tools. Our goal is to help
              individuals identify and correct bad breathing habits, fostering
              improved physical and mental well-being.
            </Typography>
          </Box>
          <Typography className="title" py={"1rem"}>
            Our Approach
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography component={"img"} src={mission_1} width={"45%"} />
                <Box
                  sx={{
                    p: { sm: "3rem 1.5rem", xs: "1.5rem 1rem" },
                    borderRadius: "5px",
                    border: "1px solid #782980",
                    alignSelf: "center",
                    ml: { sm: "-15px", xs: "-10px" },
                  }}
                >
                  <Typography className="title_1" pb={"1rem"}>
                    Physiology:
                  </Typography>

                  <Typography className="subTitle">
                    We delve into the science of the human body to understand
                    the mechanics of breathing.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="grid">
            <Grid
              item
              md={5}
              xs={12}
              sx={{ display: { md: "block", xs: "none" } }}
            ></Grid>
            <Grid item md={7} xs={12}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    p: { sm: "3rem 1.5rem", xs: "1.5rem 1rem" },
                    borderRadius: "5px",
                    border: "1px solid #782980",
                    alignSelf: "center",
                    mr: { sm: "-20px", xs: "-10px" },
                    position: "relative",
                  }}
                >
                  <Typography className="title_1" pb={"1rem"}>
                    Physiology:
                  </Typography>

                  <Typography className="subTitle">
                    We explore the mental and emotional aspects that influence
                    our breathing patterns.
                  </Typography>
                </Box>
                <Typography component={"img"} src={mission_2} width={"40%"} />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="grid">
            <Grid item md={7} xs={12}>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Typography component={"img"} src={mission_3} width={"40%"} />
                <Box
                  sx={{
                    p: { sm: "3rem 1.5rem", xs: "1.5rem 1rem" },
                    borderRadius: "5px",
                    border: "1px solid #782980",
                    alignSelf: "center",
                    ml: { sm: "-15px", xs: "-10px" },
                  }}
                >
                  <Typography className="title_1" pb={"1rem"}>
                    Physiology:
                  </Typography>

                  <Typography className="subTitle">
                    We harness cutting-edge tools and advanced techniques to
                    provide real-time feedback and effective learning
                    experiences, ensuring our methods are both innovative and
                    practical.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          mt={"4rem"}
          // mb={"2rem"}
          sx={{
            background: "#782980",
            p: "3.5rem 0",
          }}
        >
          <Container>
            <Typography className="title_">Why It Matters</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography className="subTitle_" sx={{ alignSelf: "center" }}>
                Improper breathing can lead to various health issues, from
                reduced physical performance to increased stress and anxiety.
                <br />
                By correcting these habits, you can experience significant
                improvements in your overall health and quality of life.
              </Typography>
              <Typography
                sx={{ height: "83px", width: "2px", background: "#C8A7CB" }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  gap: "2px",
                  "&:hover": {
                    "& .hoverable": {
                      transform: "translateX(10px)",
                    },
                  },
                }}
              >
                <Button
                  className={"btn"}
                  sx={{
                    fontSize: "16px",
                    width: "180px",
                    textTransform: "capitalize",
                    background: "#fff !important",
                    color: "#782980 !important",
                  }}
                   component={Link}
                  to="/contact"
                >
                  Contact Us
                </Button>
                <Button
                  className={"btn hoverable"}
                  component={Link}
                  to="/contact"
                  sx={{
                    fontSize: "15px",
                    background: "#fff !important",
                    color: "#782980 !important",
                    transition: "0.2s all",
                  }}
                >
                  <ArrowForwardIcon />
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box pb={"2rem"}>
          <NewsLetter />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight  />
        </Container>
      </Box>
    </>
  );
};

export default Mission;
