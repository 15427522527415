import { Box, Typography } from "@mui/material";
import React from "react";

const GoBasicInfo = () => {
  return (
    <Box
      sx={{
        "& p": {
          textAlign: "justify",
          fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
        },
      }}
    >
      <Typography pb={"1.5rem"}>
        The CapnoTrainer GO Basic is for solo practitioners, trainers, and
        consultants who teach breathing, relaxation, stress management, yoga,
        meditation, and other self-interventions who are interested in combining
        knowledge of respiration and its chemistry with what they are already
        doing. Make monitoring and optimizing respiration an important part of
        what you do! Has your client learned breathing habits that compromise
        respiration and its associated acid-base physiology? If so, you can
        learn to assist your clients in identifying habits, modifying them, and
        learning new ones. Learn to do this with the digital version of the
        book, <b>CapnoLearning: An Introductory Guide</b> (Breathing habits,
        optimal respiration, and acid-base physiology) that comes with the
        instrument.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Basic is <b>not for professionals who</b> own more than one
        instrument, or will be using it jointly with other practitioners, or
        plan to track physiology in the field with a client.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Basic is battery-operated, Bluetooth, wearable, light weight <br/>(185
        grams), small (fits in your pocket), and nearly silent (slight ticking
        sound). It operates on PC and Apple computers, tablets, I-pads,
        Android and i-phones. The GO Basic software connects to most third-party
        HRV Bluetooth devices<br /> (e.g., Polar Belt) such that users can observe and
        record HRV physiology along<br />with breathing physiology.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Basic includes the GO Basic software and a HIPAA compliant
        database in the Cloud for easy review of data and downloading of PDF
        reports. It includes numerous software features such as live and history
        displays, client management, taking snapshots, taking session notes,
        recording audio notes, breathing behavior testing and challenge
        applications, and remote monitoring (where physiology is presented in a
        small dialog box while working on a computer). It includes automated
        (built-in) practicums for trainers and their clients based on the book,{" "}
        <b>CapnoLearning: An Introductory Guide.</b>{" "}
        Data for any number of clients can be stored in your HIPAA compliant
        Cloud account.
      </Typography>
      <Typography pb={"1.5rem"}>
        GO Basic users may upgrade to GO Professional at any time ($1,450.00).
      </Typography>
    </Box>
  );
};

export default GoBasicInfo;
