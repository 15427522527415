import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import news_letter from "../../Assets/Images/news_letter.png"

const NewsLetter = () => {
  return (
    <Box
    sx={{
      backgroundImage: `url(${news_letter})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 100%",
      backgroundSize: "100% 100%",
    //   height: {sm:"320px",xs:"250px"},
    //   borderRadius: "5px",
      p: "5.5rem 1rem",


    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
         <Typography
            sx={{
              color: "#782980",
              fontSize: "40px",
              fontFamily: "AvenirLTStdRoman",
              fontWeight: "600",
              // letterSpacing: "2px",
            }}
          >
            Be Part of Our Inner Circle!
          </Typography>
          <Typography
            sx={{
              color: "#49194E",
              fontSize: "20px",
              fontFamily: "AvenirLTStdRoman",
          pb:"2rem",
              // letterSpacing: "2px",
            }}
          >
            Subscribe now to receive the latest updates, exclusive content and special offers straight to your inbox.
          </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { sm: "row", xs: "column" },
          gap: "0.5rem",
          pt:"3rem"
        
        }}
      >
        <TextField
          autoComplete="off"
          fullWidth
          variant="standard"
          type="text"
          placeholder="Enter your email here..."
          id="fname"
          name="fname"
          sx={{
            borderRadius: "5px",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
            border: "1px solid #782980",
            width: { sm: "400px", xs: "100%" },
            background: "rgb(255 255 255 / 32%)",
            color: "#fff",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&:focus-Visible": {
              outline: "none !important",
              border: "1px solid #782980",
            },
            "&:hover": {
              border: "1px solid #782980",
            },
            "& .MuiInputBase-root": {
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            },

            "&:focus": {
              boxShadow: "none !important",
              border: "1px solid #782980",
              borderRadius: "10px !important ",
            },
            "& input": {
              padding: "10px",
              width: "100%",
              color: "#fff",
              fontSize:"18px",
              fontFamily:"AvenirLTStdBook",
              "&:focus": {
                outline: "none",
              },
              "&::placeholder": {
                color: "#000",
                //   color: "#494949",
              },
            },
          }}
          // value={formik.values.fname}
          // onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
        />
        <Button
          sx={{
            background: "#782980 !important",
            color: "#fff !important",
            fontFamily: "AvenirLTStdBook",
            padding: "9px 8px !important",
            lineHeight: "initial !important",
            fontSize: "17px",
            width: { sm: "200px", xs: "70%" },
            "&:hover": {
              background: "#782980",
            },
            display: "block",
            m: "0 auto",
            border: "1px solid transparent",
            borderRadius: "6px !important",
            textTransform: "capitalize",
          }}
        >
         Stay Informed
        </Button>
      </Box>
    </Box>
  </Box>
  )
}

export default NewsLetter