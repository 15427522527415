import { Box, Button } from '@mui/material'
import React from 'react'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const GoBackButton = () => {
  return (
    <Box display={"inline-block"}>
    <Button
      className={"btn"}
      sx={{
        fontSize: "16px",
        textTransform: "capitalize",
        lineHeight: "normal",
        padding: "8px 10px !important",
        fontFamily: "AvenirLTStdBook",
        display: "flex",
        gap: "5px",
        alignItems: "center",
      }}
      onClick={()=>window.history.back()}
    >
      <ArrowBackIcon
        sx={{
          fontSize: "1.2rem",
        }}
      />{" "}
      Go Back
    </Button>
  </Box>
  )
}

export default GoBackButton