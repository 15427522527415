import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import GoBackButton from "../../Components/GoBackButton";



const GoQuickstart = () => {
    const goQuickstartData=[
        {
          id:1,
          title:"Get started with our comprehensive quickstart guide.",
          link:"https://betterphysiology-media.s3.amazonaws.com/Quick+Start+GO+fold+version+-+October+7+-+2023.pdf"
          // link:"https://better-physiology-videos.s3.amazonaws.com/Quick-Start-GO-fold-version-July-2023.pdf"
        },
        {
          id:2,
          title:"Explore the range of services we offer.",
          link:"https://betterphysiology-media.s3.amazonaws.com/GO+Support.pdf"
          // link:"https://better-physiology-videos.s3.amazonaws.com/GO-Support.pdf"
        },
    
      ]

  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
            fontWeight: "600",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
         
            <Grid item lg={7} md={8} xs={12}>
              <Typography className="title" pb="1rem">
              GO Quickstart
              </Typography>
              <Box mt="1rem">
                {goQuickstartData.map((quick) => (
                  <Box
                    key={quick.id}
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      p: "0.7rem 1rem",
                      width: { md: "90%" },
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "1rem",
                      mb: "0.7rem",
                    }}
                  >
                    <Typography className="subTitle">{quick.title}</Typography>
                    <Button className={"btn"}
                      sx={{
                        fontSize: "16px",
                        textTransform: "capitalize",
                        fontFamily: "AvenirLTStdBook",
                      }}
                      component="a"
                      href={quick.link}
                      target="_blank"
                    >
                      View & Download
                    </Button>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              background: "#C8A7CB",
              p: { sm: "3rem", xs: "1rem" },
              borderRadius: "10px",
             my:"3rem"
            }}
          >
            <embed
              src="https://betterphysiology-media.s3.amazonaws.com/Quick+Start+GO+fold+version+-+October+7+-+2023.pdf#toolbar=0"
              type="application/pdf"
              width="100%"
              height="700"
            ></embed>
          </Box>
        </Container>

        <Box py="4rem">
          <ProductNewsLetter text="You can reach us at:" />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default GoQuickstart;
