import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import peter from "../../Assets/Images/peter.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const Founder = () => {
  return (
    <Container>
      <Typography
        sx={{
          color: "#782980",
          fontSize: { lg: "80px", xs: "40px" },
          fontFamily: "AvenirLTStdRoman",
          fontWeight: "600",
          lineHeight: "1.2",
        }}
      >
        Meet the Mind
        <br />
        Behind the Science
      </Typography>
      <Typography
        sx={{
          color: "#49194E",
          fontSize: "20px",
          fontFamily: "AvenirLTStdRoman",
          //   fontWeight: "600",
        }}
      >
        Unveiling the expertise behind revolutionary breathing science.
      </Typography>
      <Box
        pt={"2rem"}
        sx={{
          "& .title": {
            color: "#782980",
            fontSize: "24px",
            fontFamily: "AvenirLTStdRoman",
            fontWeight: "600",
            p: "1rem",
            borderRadius: "5px 5px 0 0",
            background: "#C8A7CB",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.32)",
          },
          "& .content": {
            color: "#49194E",
            fontSize: "16px",
            fontFamily: "AvenirLTStdRoman",
            p: "1.5rem",
          },
          "& .wrapper": {
            background:
              "linear-gradient(to bottom, rgb(255 255 255) 5%, #C8A7CB 104%)",
            borderRadius: "0 0 5px 5px",
            m: "0 auto 0",
            width: "80%",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <Box className="wrapper">
              <Typography className="title">Decades of Expertise</Typography>

              <Typography className="content">
                With decades of experience, Peter has been at the forefront of
                respiratory science, transforming lives through innovative
                solutions.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Typography component={"img"} src={peter} />
            <Typography
              className="title"
              sx={{
                m: "-4.2rem auto 0",
                width: "80%",
              }}
            >
              Peter M Litchfield
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Box className="wrapper">
              <Typography className="title">Passion for Wellness</Typography>

              <Typography className="content">
                Driven by a passion for wellness, Peter continues to advance the
                understanding of optimal breathing for better health outcomes.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography
          sx={{
            pt: "1rem",
            color: "#F0EDF6",
            fontSize: { sm: "50.8px", xs: "30px" },
            fontFamily: "AvenirLTStdBlack",
            letterSpacing: { lg: "6px", sm: "6px", xs: "6px" },
            "@media (min-width:1300px)": {
              letterSpacing: "10px !important",
            },
            textShadow: `
      1px 1px 1px #782980,
      -1px 1px 1px #782980,
      1px -1px 1px #782980,
      -1px -1px 1px #782980
    `, // Creates a 4-directional shadow to simulate an outline
          }}
        >
          CEO & Co-Founder of BetterPhysiology
        </Typography>
       <Box display={"inline"}>
       <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "2px",
            mt: "1rem",
            "&:hover": {
              "& .hoverable": {
                transform: "translateX(8px)",
              },
            },
          }}
         
        >
          <Button
            className={"btn"}
            sx={{
              fontSize: "16px",
              textTransform: "capitalize",
            }}
            component={Link}
            to={"/founder"}
          >
            Discover Peter’s Full Journey
          </Button>
          <Button
            className={"btn hoverable"}
            sx={{
              fontSize: "15px",
              transition: "0.2s",
            }}
            component={Link}
          to={"/founder"}
          >
            <ArrowForwardIcon />
          </Button>
        </Box>
       </Box>
      </Box>
    </Container>
  );
};

export default Founder;
