import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import cart_empty from "../../Assets/Images/cart_empty.png";
import { useSelector } from "react-redux";
import SuccesAlert from "../../Components/SuccesAlert";
import MaxQtyMessage from "../../Components/MaxQtyMessage";
import PriceDetail from "../../Components/PriceDetail";
import CartItem from "./CartItem";
import ReminderModal from "./ReminderModal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import GoBackButton from "../../Components/GoBackButton";

const Cart = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [maxQnty, setMaxQnty] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [addSuccessMsg, setAddSuccessMsg] = useState("");
  const [reminder, setReminder] = useState(false);

  const { data: result } = useSelector((state) => state.cart);

  useEffect(() => {
    if (result?.data) {
      const totalPriceAdd = result.data.reduce(
        (total, item) => total + (item.total_price || 0),
        0
      );
      setTotalPrice(totalPriceAdd);
    }
  }, [result]);
  // const { loading } = useSelector((state) => state.remove);
  useEffect(() => {
    if (result?.data?.length > 0) {
      if (result?.data?.some((item) => item.product_id == 50)) {
        setReminder(false);
      } else if (!reminder) {
        //   setTimeout(() => {
        setReminder(true);
        //   }, 2500);
      }
    }
  }, [result?.data]);
  return (
    <>
      <SuccesAlert showSnackbar={showSnackbar} addSuccessMsg={addSuccessMsg} />
      <MaxQtyMessage maxQnty={maxQnty} />
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
            fontWeight: "600",
          },
          "& .subTitle_cart": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "24px",
            color: "#782980",
            // fontWeight: "600",
          },
        }}
      >
   
        <Container>
        <GoBackButton/>
        {result?.data?.length === (0 || undefined) ? 
        (
          <Box
            sx={{
              background: "#F7F7F9",
              borderRadius: "8px",
              textAlign: "center",
              padding: "3rem 2rem",
              my: "2rem",
            }}
          >
            {" "}
            <Typography
              component={"img"}
              src={cart_empty}
              sx={{ width: { lg: "25%",sm:"35%", xs: "50%" } }}
            />
            <Typography className="subTitle_cart">
            Oops! Your cart is empty.
            </Typography>
            <Typography className="subTitle_cart" sx={{color: "#49194E !important",pt:"1rem",fontSize:"18px !important"}}>
            But don’t worry, the perfect items are just a click away!
            </Typography>
          <Box sx={{display:"inline-block",pt:"1rem"}}>
          <Box
              sx={{
                display: "flex",
                gap: "2px",
                pr: { lg: "10px" },
                "&:hover": {
                  "& .hoverable": {
                    transform: "translateX(10px)",
                  },
                },
              textDecoration:"none"
              }}
               component={Link}
                to="/go-buy"
            >
              <Button
                className={"btn"}
                sx={{
                  fontSize: "16px",
                  textTransform: "capitalize",
                  fontWeight:"600",
                    // width: "180px",
                    px:"20px !important",
                    background:"#E4D5F4 !important",
                    color:"#782980 !important",
                  fontFamily: "AvenirLTStdBook",
                }}
               
              >
               Start Shopping
              </Button>
              <Button
                className={"btn hoverable"}
                sx={{
                  fontSize: "15px",
                  transition: "0.2s",
                  fontWeight:"600",
                  background:"#E4D5F4 !important",
                  color:"#782980 !important",
                }}
              >
                <ArrowForwardIcon sx={{fontSize:"1.2rem"}} />
              </Button>
            </Box>
          </Box>
          </Box>
        ):(
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={8} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  pb: "1rem",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                <Typography className="title">
                  Shopping cart{" "}
                  <span style={{ color: "#C8A7CB" }}>{`(${
                    result?.data?.length || 0
                  } items)`}</span>
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{
                    color: "#782980",
                    fontWeight: "600",
                    fontSize: "20px !important",
                  }}
                >
                  {`Total: $ ${totalPrice}`}
                </Typography>
              </Box>

              <Box>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "none",
                    "& th": {
                      color: "#782980",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdRoman",
                    },
                    "& td": {
                      color: "#49194E",
                      fontFamily: "AvenirLTStdRoman",
                      // width:"150px"
                    },
                  }}
                >
                  <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="center" sx={{width:{lg:"120px"}}}>Unit Price</TableCell>
                        <TableCell align="center" sx={{width:{lg:"120px"}}}>Quantity</TableCell>
                        <TableCell align="center" sx={{width:{lg:"120px"}}}>Total Price</TableCell>
                        <TableCell align="center" sx={{width:{lg:"120px"}}}>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result?.data?.map((item, index) => (
                        <CartItem
                          item={item}
                          key={index}
                          setMaxQnty={setMaxQnty}
                          setShowSnackbar={setShowSnackbar}
                          setAddSuccessMsg={setAddSuccessMsg}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={4} xs={12}>
            
              <PriceDetail result={result} totalPrice={totalPrice} />
            </Grid>
          </Grid>
        )}
        </Container>


        <Box py="4rem">
          <ProductNewsLetter text="You can reach us at:" />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
      <ReminderModal setReminder={setReminder} reminder={reminder} />
    </>
  );
};

export default Cart;
