import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import phone from "../Assets/Images/phone_dark.svg";
import gmail from "../Assets/Images/gmail_dark.svg";

const ProductNewsLetter = ({ text }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        p: {sm:"4rem 1rem",xs:"3rem 0"},
      }}
    >
      <Container>
        <Box>
          <Typography
            sx={{
              color: "#782980",
              fontSize: "16px",
              fontFamily: "AvenirLTStdRoman",
              pb: { sm: "1rem", xs: "1rem" },
              // letterSpacing: "2px",
            }}
          >
            {text}
          </Typography>
        </Box>
        <Box
          sx={{
            "& .text_1": {
              color: "#782980",
              fontSize: "16px",
              opacity: "70%",
              // fontWeight: "600",
              fontFamily: "AvenirLTStdBook",
            },
            "& .text_2,a": {
              color: "#49194E",
              fontSize: "16px",
              fontWeight:"500",
              fontFamily: "AvenirLTStdBook",
              textDecoration: "none",
            },
            "& .main_box": {
              display: "flex",
              gap: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "space-between", xs: "start" },
              gap: "1.5rem",
              height: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box className="main_box">
              <Typography className="img_box">
                <Typography component={"img"} src={phone} />
              </Typography>
              <Box>
                <Typography className="text_1">Tel Support</Typography>
                <Typography className="text_2">+1.800.850.9795</Typography>
              </Box>
            </Box>
            <Box className="main_box">
              <Typography className="img_box">
                <Typography component={"img"} src={gmail} />
              </Typography>
              <Box>
                <Typography className="text_1">
                  Sales & Product Inquiries
                </Typography>
                <Typography className="text_2">
                  <a href="mailto:instruments@betterphysiology.com">
                    instruments@betterphysiology.com
                  </a>
                </Typography>
              </Box>
            </Box>
            <Box className="main_box">
              <Typography className="img_box">
                <Typography component={"img"} src={gmail} />
              </Typography>
              <Box>
                <Typography className="text_1">
                  Tech Support & Assistance
                </Typography>
                <Typography className="text_2">
                  <a href="mailto:tech@betterphysiology.com">
                    tech@betterphysiology.com
                  </a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ProductNewsLetter;
