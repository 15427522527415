import React, { useState } from "react";
import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import Title from "./Title";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { sm: "2rem", xs: "1rem" } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const DistributorTabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabData = [
    { label: "USA" },
    { label: "ARAB COUNTRIES" },
    { label: "ASIAN COUNTRIES" },
    { label: "AUSTRALIA" },
    { label: "AUSTRIA" },
    { label: "CANADA" },
    { label: "FRANCE" },
    { label: "GERMANY" },
    { label: "INDIA" },
    { label: "ISRAEL" },
    { label: "JAPAN" },
    { label: "NEW ZEALAND" },
    { label: "SCANDINAVIA" },
    { label: "SPAIN" },
    { label: "SWITZERLAND" },
    { label: "THE NETHERLANDS" },
    { label: "TURKEY" },
    { label: "UNITED KINGDOM" },
  ];
  return (
    <Box
      py={"2rem"}
      sx={{
        "& .text": {
          fontFamily: "AvenirLTStdRoman",
          fontSize: { sm: "16px", xs: "15px" },
          color: "#49194E",
        },
        "& a": {
          fontFamily: "AvenirLTStdRoman",
          fontWeight: "600",
          color: "#782980",
          textDecoration: "none",
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          lg={2}
          sm={3}
          xs={12}
          sx={{
            "& .MuiTouchRipple-root": {
              display: "none",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .MuiTab-root.Mui-selected": {
              background: "#782980",
              color: "#fff",
              "&:hover":{
                background:"#782980"
              }
            },
            "& .MuiTab-root": {
              color: "#49194E",
              textTransform: "capitalize",
              alignItems: "flex-start",
              fontFamily: "AvenirLTStdRoman",
              minHeight: "0",
              "&:hover":{
                background:"#fff"
              }
            },
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Distributor Tabs"
          >
            {tabData.map((tab, index) => (
              <Tab key={index} label={tab.label} />
            ))}
          </Tabs>
        </Grid>

        <Grid item lg={10} sm={9} xs={12}>
          <Box
            sx={{
              background: "#fff",
              height: "100%",
            }}
          >
            <Title text={tabData[value].label} />
            <TabPanel value={value} index={0}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    CORPORATE OFFICES
                    <br />
                    BETTER PHYSIOLOGY LTD, Peter M. Litchfield, Ph.D.
                    <br />
                    109 E. 17th Street, Cheyenne, WY 82001 USA
                    <br />
                    Tel: 1.307.773.0888 . Fax: 1.307.773.0887 .
                    <br />
                    Email: 
                    <a href="mailto:instruments@betterphysiology.com">
                      instruments@betterphysiology.com
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    SANTA FE OFFICE
                    <br />
                    7 Camino de Rey Cir
                    <br />
                    Santa Fe, NM 87506 USA
                    <br />
                    Tel: 1.505.820.3376
                    <br />
                    Email: 
                    <a href="mailto:pl@betterphysiology.com">
                      pl@betterphysiology.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={"0rem"}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    FLORIDA
                    <br />
                    NEUROACROBATICS, LLC, Megan Baerg
                    <br />
                    3504 W Paxton Ave, Tampa, Florida 33611
                    <br />
                    Tel: 929-949-9110
                    <br />
                    Email: 
                    <a href="mailto:megan@neuroacrobatics.com">
                      megan@neuroacrobatics.com
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    NEW MEXICO
                    <br />
                    BREATHING SCIENCE INSTITUTE, Denise Campbell
                    <br />
                    2404 S. Locus Street, Suite #5, Las Cruces, NM 88001
                    <br />
                    Tel: 575-405-7210 .
                    <br />
                    Email: 
                    <a href="mailto:denise@swsportandspine.com">
                      denise@swsportandspine.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={"0rem"}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    NEW YORK
                    <br />
                    BIOFEEDBACK RESOURCES INTERNATIONAL, Harry Campbell
                    <br />
                    109 Croton Ave, 2nd Floor, Suite #5, Ossining, NY 10562
                    <br />
                    Tel: 1.914-762-4646 . Fax:914-762-2281 .
                    <br />
                    Email: 
                    <a href="mailto:sales@biofeedbackinternational.com">
                      sales@biofeedbackinternational.com
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    Respiratory Fitness, LLC., Alexander Stewart
                    <br />
                    244 5th Ave, Ste 2508, New York, NY 10001
                    <br />
                    Tel: 646.652.6302, 212.462.9397 .
                    <br />
                    Email: 
                    <a href="mailto:respiratoryfitness@gmail.com">
                      respiratoryfitness@gmail.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={3} mt={"0rem"}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    KNOSIS Physiotherapy & Wellness , Tracey Vincel
                    <br />
                    7 West 22nd 8th Floor
                    <br />
                    New York, NY 10010
                    <br />
                    Tel: 212-906-4440 .
                    <br />
                    Email: 
                    <a href="mailto:tvincel@knosiswellness.com">
                      tvincel@knosiswellness.com
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    TEXAS
                    <br />
                    APERION CENTER, Danica Warren
                    <br />
                    919 Congress Ave. Ste 525, Austin, TX 78701
                    <br />
                    Email: 
                    <a href="mailto:danica@apeironcenter.com ">
                      danica@apeironcenter.com
                    </a>
                    <br />
                    Tel: 512-522-0966
                    <br />
                    Website: 
                    <a
                      href="https://www.apeironcenter.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.apeironcenter.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    INTER MARKETS LIMITED, Omar Chtioui
                    <br />
                    BLK A, 15/F Hillier Commercial,
                    <br />
                    Bldg 65-67 Bonham Strand East, Sheung Wan, H.K.
                    <br />
                    UAE, +1.852.66512620
                    <br />
                    Email: 
                    <a href="mailto:info@intermarketslimited.com">
                      info@intermarketslimited.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    DOCTOR AND CO CORPORATION LTD., Dr. Amornpong Vachiramon
                    <br />
                    128/366 L.1 Phayathai Plaza Building 33th Floor, Phayathai
                    Road,
                    <br />
                    Tung Phayathai, Ratchathewi, Bangkok Thailand 10400
                    <br />
                    Tel: 021099906
                    <br />
                    Email:
                    <a href="mailto:contact@drandco.co.th">
                      contact@drandco.co.th
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    BREATHE ME, Angie & Shane Saunders
                    <br />
                    1 Parrot Court, Gilston QLD 4221
                    <br />
                    Tel: +61429777729
                    <br />
                    Email:
                    <a href="mailto:info@breatheme.com">info@breatheme.com</a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    BREATHING WELL, LLC, Roger Price
                    <br />
                    6a/3 Campbell Pde, Bondi Beach NSW 2026
                    <br />
                    Tel: 0423-589-588 
                    <br />
                    Email:
                    <a href="mailto:rogerlprice@gmail.com">
                      rogerlprice@gmail.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    ATEMKOMPETENZ, Wolfgang J. Fellner, Ph.D.
                    <br />
                    Villengasse 15, 2214 Auersthal, Austria
                    <br />
                    Tel: 0043.677.64050501.
                    <br />
                    Email:
                    <a href="mailto:instrumente@atemkomptenz.at">
                      instrumente@atemkomptenz.at
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    PROACTIVE EDUCATION, Laurie McLaughlin, DScPT
                    <br />
                    94 North Shore Blvd W, Burlington. Ontario L7T 4G3, Canada
                    <br />
                    Tel: 1.905.338.2898
                    <br />
                    Email:
                    <a href="mailto:laurie@proactivehealth.ca">
                      laurie@proactivehealth.ca
                    </a>
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    CONSPIRE HEALTH, Lisa V. Kusch
                    <br />
                    #35-3814 Burron Ave
                    <br />
                    Saskatoon, SK S7P0E4, CANADA
                    <br />
                    Tel: +1.306.612.3777
                    <br />
                    Email:
                    <a href="mailto:lv@amplifyrehab.ca">lv@amplifyrehab.ca</a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    OLIVIER MORTARA
                    <br />
                    3 rue Emile Zola, 40510 Seignosse, France
                    <br />
                    Tel: +33 630727534
                    <br />
                    Website: 
                    <a
                      href="https://oliviermortara.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://oliviermortara.com
                    </a>
                    <br />
                    Email: 
                    <a href="mailto:om@oliviermortara.com">
                      om@oliviermortara.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    AtmungAktiv, Michael Schmidt
                    <br />
                    Erlengasse 5, 63322 Roedermark (Frankfurt am Main)
                    <br />
                    Website: 
                    <a
                      href="https://www.atmungaktiv.de"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.atmungaktiv.de
                    </a>
                    <br />
                    Tel Hotline: +49 (0) 800 000 9448
                    <br />
                    Tel Mobile: +49 (0) 177 6272054
                    <br />
                    Tel Showroom: +49 (0) 6074 698 68 70
                    <br />
                    Email: 
                    <a href="mailto:info@atmungaktiv.de">info@atmungaktiv.de</a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    HIMOTECH GLOBAL PVT LTD, Yasir Khan
                    <br />
                    B-01, Sector-2, D-84, Noida, Gautam Budh Nagar, UP 201301
                    <br />
                    Tel: +91-8053143035
                    <br />
                    Email: 
                    <a href="mailto:info@himotechglobal.com">
                      info@himotechglobal.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={9}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    SKILLS DEVELOPMENT CENTER, Dan Tune
                    <br />
                    9 Halilach Street, Herzlia 46601, Israel.
                    <br />
                    Tel and Fax: 972.9.9583372 .
                    <br />
                    Email: 
                    <a href="mailto:tuneedna@bezeqint.net">
                      tuneedna@bezeqint.net
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={10}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    CAPNOTRAINER JAPAN, Dr. Kohei Miyoshi
                    <br />
                    2179-4-104Kamimizo, Chuoku, Sagamihara, Kanagawa, Japan
                    <br />
                    Tel: 042.733.8186 . Fax: 042.733.8186 .
                    <br />
                    Email: 
                    <a href="mailto:miyoshisun@yahoo.co.jp">
                      miyoshisun@yahoo.co.jp
                    </a>
                    <br />
                    Website: 
                    <a
                      href="https://www.capnotrainer.jp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.capnotrainer.jp
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={11}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    BUTEYKO BREATHING CLINICS LTD, Glenn White
                    <br />
                    20 Arthur Street, Freemans Bay, Auckland 1011, New Zealand
                    <br />
                    Tel: 64.9.360.6291 .
                    <br />
                    Email: 
                    <a href="mailto:glenn@buteykobreathing.nz">
                      glenn@buteykobreathing.nz
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={12}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    BUTEYKO BREATHING CLINICS LTD, Glenn White
                    <br />
                    RESPIRATORY FITNESS INSTITUTE AS, Alexander Stewart
                    <br />
                    Gustav Vigelands Vei 15B, 0274 Oslo, Norway
                    <br />
                    Tel: 47.92647747 .
                    <br />
                    Email: 
                    <a href="mailto:respiratoryfitness@gmail.com">
                      respiratoryfitness@gmail.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={13}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    AtmungAktiv España, Michael Schmidt
                    <br />
                    Av. Son Rigo 23, 07610 Palma de Mallorca
                    <br />
                    Website: 
                    <a
                      href="https://www.atmungaktiv.de"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.atmungaktiv.de
                    </a>
                    <br />
                    Tel Mobile: +49 (0) 177 6272054
                    <br />
                    Tel Showroom: +49 (0) 6074 698 68 70
                    <br />
                    Email: 
                    <a href="mailto:info@atmungaktiv.de">info@atmungaktiv.de</a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={14}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    ARA.HEALTH SARL, Chris Christiansson
                    <br />
                    Rue de Village 40, 1659 Rougemont
                    <br />
                    Email: <a href="mailto:info@ara.health">info@ara.health</a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={15}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    PHYSIOMOTION, Stephanie Leyh and Joost van Mierlo.
                    <br />
                    Sint Jansshof 8, 3011 SG Rotterdam, The Netherlands
                    <br />
                    Email: 
                    <a href="mailto:info@physiomotion.nl">
                      info@physiomotion.nl
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={16}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    NEVSAH ENSTITU LTD, Nevsah Karamehmet
                    <br />
                    Withco Çınarlı, 1572/1. Sk. No:33,
                    <br />
                    35170 Konak/İzmir, Türkiye
                    <br />
                    Tel: 90.530.469.1111 .
                    <br />
                    Email: 
                    <a href="mailto:fidannevsah@gmail.com">
                      fidannevsah@gmail.com
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={17}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className="text">
                    CAPNOTRAINER UK, VJ Odedra
                    <br />
                    27 Russell Road, London N20 0TN, England
                    <br />
                    Tel: +44 (0) 7848 300000
                    <br />
                    Email:  
                    <a href="mailto:VJ@CapnoTrainer.co.uk">
                      VJ@CapnoTrainer.co.uk
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DistributorTabs;
