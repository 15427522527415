import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import news_letter from "../../Assets/Images/subscribe.png";
import GoBackButton from "../../Components/GoBackButton";

const Subscribe = () => {
  return (
    <>
      <Navbar />
      <Box>
        <Box
          sx={{
            position: "relative", // Make sure the overlay can be positioned inside this Box
            backgroundImage: `url(${news_letter})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "100% 100%",
            backgroundSize: "100% 100%",
            py: "7rem",
            px: "1rem",
            zIndex: 1, // This ensures the overlay is above the background but below the text
          }}
        >
          {/* Overlay to darken the background */}
       
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.1)", // Darken the background with a slight black overlay
              zIndex: 0, // Place overlay behind the text
            }}
          ></Box>

         <Container>
         <GoBackButton/>
         <Box
            sx={{
              mt:"2rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              zIndex: 1, // Ensure the text stays above the overlay
            }}
          >
            <Typography
              sx={{
                color: "#782980",
                fontSize: { sm: "57px", xs: "30px" },
                fontFamily: "AvenirLTStdRoman",
                fontWeight: "600",
                zIndex: 1,
              }}
            >
              Be Part of Our Inner Circle!
            </Typography>
            <Typography
              sx={{
                color: "#49194E",
                fontSize: "20px",
                fontFamily: "AvenirLTStdRoman",
                textAlign: "center",
                width: { lg: "40%" },
                zIndex: 1,
              }}
            >
              Subscribe now to receive the latest updates, exclusive content, and special offers straight to your inbox.
            </Typography>
            <Box
              sx={{
                background: "rgba(255,255,255,0.6)",
                  borderRadius: "5px",
                width: { md: "44%", sm: "75%" },
                position: "relative",
                margin: "1.5rem auto 0",
                textAlign: "center",
                "& button": {
                  fontSize: "16px",
                  textTransform: "capitalize",
                  fontFamily: "AvenirLTStdBook",
                  m: "0 auto",
                  fontWeight: "600",
                  py: "20px !important",
                  border: "1px solid transparent !important",
                  background: "#782980 !important",
                  color: "#fff !important",
                  "&:hover": {
                    background: "#782980 !important",
                    color: "#fff !important",
                  },
                },
                "& p,span,label": {
                  fontFamily: "AvenirLTStdBook !important",
                  fontSize: "15px !important",
                  color: "#49194E !important",
                },
                "& .ctct-gdpr-text,.ctct-form-text": {
                  fontFamily: "AvenirLTStdBook !important",
                  fontSize: "14.5px !important",
                  color: "#49194E !important",
                  fontWeight: "bold !important",
                  // background: "rgba(255,255,255,0.7)",
                  // borderRadius: "5px",
                  // p: "10px",
                  lineHeight: "20px !important",
                },
                "& a":{
                  display:"block",
                },
                "& input": {
                  fontFamily: "AvenirLTStdBook !important",
                  borderColor: "#49194E !important",
                  fontSize: "16px !important",
                  fontWeight:"600",
                  "&:focus-visible": {
                    outline: "none !important",
                  },
                },
              }}
            >
              <div
                class="ctct-inline-form"
                data-form-id="c92f3cf7-5f23-44f7-908c-bf70d551a4f8"
              ></div>
            </Box>
          </Box>
         </Container>
        </Box>

        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Subscribe;
