import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import manual from "../../Assets/Images/manual.png";
import icon_1 from "../../Assets/Images/icon_1.svg";
import icon_2 from "../../Assets/Images/icon_2.svg";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component="img"
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const GoManual = () => {
  const goManualData = [
    {
      id: 1,
      title: "GO App Manual",
      link: "https://betterphysiology-media.s3.amazonaws.com/GO+App+Manual+2024.pdf",
    },
    {
      id: 2,
      title: "GO Basic Operating Manual",
      link: "https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Basic-3.0.pdf",
    },
    {
      id: 3,
      title: "GO Professional Operating Manual",
      link: "https://better-physiology-videos.s3.amazonaws.com/Operating+Manual+GO+Professional-4.0.pdf",
    },
  ];

  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
            fontWeight: "600",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6} xs={12}>
              <Typography className="title" pb="1rem">
                GO Manuals
              </Typography>
              <Box mt="1rem">
                {goManualData.map((manual) => (
                  <Box
                    key={manual.id}
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      p: "0.7rem 1rem",
                      width: { md: "90%" },
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "1rem",
                      mb: "0.7rem",
                    }}
                  >
                    <Typography className="subTitle">{manual.title}</Typography>
                    <Button className={"btn"}
                      sx={{
                        fontSize: "16px",
                        textTransform: "capitalize",
                        fontFamily: "AvenirLTStdBook",
                      }}
                      component="a"
                      href={manual.link}
                      target="_blank"
                    >
                      View & Download
                    </Button>
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  background: "#C8A7CB",
                  borderRadius: "10px",
                  lineHeight: "0",
                  textAlign: "center",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                }}
              >
                <IconBox
                  src={icon_1}
                  sx={{
                    top: { md: "-15px", xs: "0px" },
                    left: { md: "-40px", xs: "-20px" },
                  }}
                />
                <IconBox
                  src={icon_2}
                  sx={{
                    bottom: { md: "-30px", xs: "-10px" },
                    right: { md: "-30px", xs: "-10px" },
                  }}
                />
                <Typography
                  component="img"
                  src={manual}
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                    left: "-10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box py="4rem">
          <ProductNewsLetter text="You can reach us at:" />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default GoManual;
