import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";

const ServiceCard = ({ data }) => {
  return (
    <Box
      p={"1rem"}
      sx={{
        width: {md:"350px",xs:"300px"},
      }}
    >
      <Typography component={"img"} src={data?.image} />
      <Typography
        sx={{
          color: "#782980",
          fontSize: "24px",
          fontFamily: "AvenirLTStdRoman",
          fontWeight: "600",
          py: "10px",
          // letterSpacing: "2px",
        }}
      >
        {data.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "2px",
          pt: "0.5rem",
          "&:hover":{
              "& .hoverable":{
              transform:"translateX(8px)"
            }
            }
        }}
      >
        <Button
          className={"btn"}
          sx={{
            fontSize: "16px",
            width: "200px",
            textTransform: "capitalize",
            background: "rgb(49 49 49 / 22%) !important",
            color: "#49194E !important",
            fontWeight:"600"
        
          }}
          component={Link}
          to={data?.link}
        >
          Know More
        </Button>
        <Button
          className={"btn hoverable"}
          sx={{
            fontSize: "15px",
            background: "rgb(49 49 49 / 22%) !important",
            color: "#49194E !important",
            transition:"0.2s all",
          
          }}
          component={Link}
          to={data?.link}
        >
          <ArrowForwardIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default ServiceCard;
