import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import SolutionSection from "./SolutionSection";
import capno_set from "../../Assets/Images/products/capno_set.png";
import start_kit from "../../Assets/Images/products/start_kit.png";
import sampling_line from "../../Assets/Images/products/sampling_line.png";
import sampling_line_6 from "../../Assets/Images/products/sampling_line_6.png";
import sampling_line_5 from "../../Assets/Images/products/sampling_line_5.png";
import filter from "../../Assets/Images/products/filter.png";
import upgrade_anytime from "../../Assets/Images/products/upgrade_anytime.png";
import extention_line_6 from "../../Assets/Images/products/extention_line_6.png";
import water_trap from "../../Assets/Images/products/water_trap.png";
import bp_icon from "../../Assets/Images/products/bp_icon.svg";
import expanded_warrenty from "../../Assets/Images/products/expanded_warrenty.png";
import belt from "../../Assets/Images/products/belt.png";
import dryer from "../../Assets/Images/products/dryer.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import ProductCard from "./ProductCard";
import GoCard from "./GoCard";
import ProductModal from "./ProductModal";
import GuideModal from "./GuideModal";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../redux/Constant";
import axios from "axios";
import { getCart } from "../../store/cartSlice";
import SuccesAlert from "../../Components/SuccesAlert";
import { getProducts } from "../../store/ProductSlice";
import Loader from "../../Components/Loader";
import GoBackButton from "../../Components/GoBackButton";

const GoBuy = () => {
  const [openModal, setOpenModal] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loadingKit, setLoadingKit] = useState(false);
  const [category_1, setCategory_1] = useState([]);
  const [category_3, setCategory_3] = useState([]);
  const [category_2, setCategory_2] = useState([]);
  const [addSuccessMsg, setAddSuccessMsg] = useState("");
  const [addErrorMsg, setAddErrorMsg] = useState("");
  const dispatch = useDispatch(); 

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  const { data: result } = useSelector((state) => state.products);
  const getProductByCategory_1 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/9`);
      if (res.status === 200) {
        setCategory_1(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getProductByCategory_3 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/20`);
      if (res.status === 200) {
        setCategory_3(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getProductByCategory_2 = async () => {
    try {
      const res = await axios.get(`${API_URL}/get/specified/product/by/8`);
      if (res.status === 200) {
        setCategory_2(res.data.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getProductByCategory_1();
    getProductByCategory_2();
    getProductByCategory_3();
  }, []);
  const addToCartFunc = async (productId, quantity) => {
    setLoadingKit(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        setOpenModal(false)
        setLoadingKit(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddSuccessMsg("");
        }, 2000);
        setAddSuccessMsg(res.data.message);
        const userId = res.data.user_id ;
        localStorage.setItem("user", res.data.user_id);
        dispatch(getCart(userId));
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoadingKit(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddErrorMsg("");
        }, 2000);
        setAddErrorMsg("Out of stock");
      }
    }
  };


  return (
    <>
       <SuccesAlert
        showSnackbar={showSnackbar}
        addSuccessMsg={addSuccessMsg}
        addErrorMsg={addErrorMsg}
      />
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_upgrade": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: { md: "70px", xs: "40px" },
            lineHeight: "normal",
            fontWeight: "600",
            color: "#fff",
          },
          "& .capno_title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: { md: "42px", xs: "30px" },
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_1": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "24px",
            color: "#F0EDF6",
          },
          "& .card": {
            height: "100%",
            background: "#fff",
            borderRadius: "10px",
            p: "2rem 1rem",
            textAlign: "center",
          },
          "& .btn_hover_box": {
            transition: "0.3s all",
            display: "flex",
            gap: "2px",
            pr: { lg: "8px" },
            "&:hover": {
              "& .hoverable": {
                transition: "0.3s all",
                transform: "translateX(8px)",
              },
            },
            alignSelf: "center",
          },
          "& .btn_main_box": {
            display: "flex",
            gap: "0.5rem",
            justifyContent: { sm: "space-between", xs: "center" },
            flexWrap: "wrap",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Typography className="title" pb={"1rem"} mt={"2rem"}>
            GO Buy
          </Typography>

          <Grid container spacing={4}>
            <Grid item md={4} xs={12}>
              <Box
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  p: "1rem",
                  textAlign: "center",
                  "& img": {
                    width: "80%",
                  },
                }}
              >
                <Typography component={"img"} src={capno_set} alt="capno_set" />
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              <Box
                sx={{
                  p: { md: "2rem",xs:"0 0 2rem 0" },
                }}
              >
                <Typography className="capno_title" pb={"0.5rem"}>
                  CapnoTrainer GO Instruments
                </Typography>
                <Typography className="subTitle">
                  Choose the perfect tool for your respiratory training needs.
                  <br />
                  Be sure to order a Starter Kit with your CapnoTrainer.
                </Typography>
              </Box>
              <Box
                sx={{
                  p: { md: "1.5rem" },
                }}
              >
                <Grid container spacing={4}>
                  <Grid item md={4} xs={12}>
                    <Box className="card" position={"relative"}>
                      <InfoIcon
                        sx={{
                          position: "absolute",
                          top: "-20px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          fontSize: "3rem",
                          color: "#782980",
                        }}
                      />
                      <Typography className="subTitle" py={"1rem"}>
                        Actual Size of the <br /> CapnoTrainer
                      </Typography>
                      <Typography
                        className="subTitle"
                        sx={{ color: "#782980 !important" }}
                      >
                        9.7 X 7.6 X 3.2 cm
                        <br />
                        (3.8” X 3.0” X 1.25”)
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Box
                      className="card"
                      sx={{ textAlign: "start !important" }}
                    >
                      <Box className="btn_main_box">
                        <Typography className="subTitle">
                          To order a Starter Kit
                          <br /> with your CapnoTrainer,
                        </Typography>
                        <Box className="btn_hover_box">
                          <Button
                            className={"btn"}
                            sx={{
                              fontSize: "16px",
                              textTransform: "capitalize",
                              lineHeight: "normal",
                              padding: "6px 20px !important",
                              fontFamily: "AvenirLTStdBook",
                            }}
                            onClick={() => setOpenModal(true)}
                            
                          >
                            Click Here
                          </Button>
                          <Button className={"btn hoverable"}>
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "1.3rem",
                              }}
                              onClick={() => setOpenModal(true)}
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box pt={"2rem"} className="btn_main_box">
                        <Typography className="subTitle">
                          To see which GO might
                          <br /> be best for you,
                        </Typography>
                        <Box className="btn_hover_box">
                          <Button
                            className={"btn"}
                            sx={{
                              fontSize: "16px",
                              textTransform: "capitalize",
                              //   width: "120px",
                              padding: "6px 20px !important",
                              fontFamily: "AvenirLTStdBook",
                            }}
                            onClick={() => setGuideModal(true)}
                          >
                            Click Here
                          </Button>
                          <Button className={"btn hoverable"}>
                            <ArrowForwardIcon
                              sx={{
                                fontSize: "1.3rem",
                              }}
                              onClick={() => setGuideModal(true)}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {result?.data ? (
          <Grid container spacing={2} mt={1}>
            {result.data?.map((data, index) => (
              <Grid item md={2.4} xs={12} key={index}>
                <ProductCard data={data} setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg} />
              </Grid>
            ))}
          </Grid>
        ) : (
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          )}
        </Container>
        <Box
          sx={{
            background:
              "linear-gradient(306deg, rgba(185,63,197,1) 0%, rgba(120,41,128,1) 47%, rgba(227,142,235,1) 100%)",
            p: "2rem 0",
            mb: "1rem",
            mt: "4rem",
          }}
        >
          <Container>
            <Grid container spacing={3}>
              <Grid
                item
                md={6}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography className="title_upgrade" pb={"1rem"}>
                    Upgrade Anytime!
                  </Typography>
                  <Typography className="subTitle_1">
                    You can upgrade your GO Basic or GO Personal at any time by
                    logging into your CapnoLearning Cloud Database account and
                    clicking on SUBSCRIPTION. This includes Option C, Option M,
                    GO Basic (both C and M) and GO Professional.
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box
                  sx={{
                    position: "relative",
                    "& .img": {
                      width: "65%",
                    },
                    "& .bp_img": {
                      width: {md:"250px",xs:"150px"},
                      position: "absolute",
                      top: "35%",
                      right: "15%",
                      transform: "translateY(-40%)",
                    },
                  }}
                >
                  <Typography
                    component={"img"}
                    className="img"
                    src={upgrade_anytime}
                  />
                  <Typography
                    component={"img"}
                    src={bp_icon}
                    className="bp_img"
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container>
          <Typography className="title" pt={"3.5rem"}>
            Supplies for CapnoTrainer GO
          </Typography>
          {
            category_2?.length>0?
            <Grid container spacing={4} mt={1}>
            {category_2?.map((data, index) => (
              <Grid item md={3} xs={12} key={index}>
                <GoCard  data={data}
                        setShowSnackbar={setShowSnackbar}
                        setAddSuccessMsg={setAddSuccessMsg}
                        setAddErrorMsg={setAddErrorMsg} />
              </Grid>
            ))}
          </Grid>
            :
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          }
       
          <Typography className="title" pt={"3.5rem"}>
            Supplies for CapnoTrainer P6.0
          </Typography>
          {
            category_1?.length>0?
          <Grid container spacing={4} mt={1}>
            {category_1?.map((data, index) => (
              <Grid item md={3} xs={12} key={index}>
                <GoCard data={data} setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg} />
              </Grid>
            ))}
          </Grid>
            :
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          }
          <Typography className="title" pt={"3.5rem"}>
            Supplies for CapnoTrainer 5.0
          </Typography>
          {
            category_3?.length>0?
          <Grid container spacing={4} mt={1} mb={"3rem"}>
            {category_3?.map((data, index) => (
              <Grid item md={3} xs={12} key={index}>
                <GoCard data={data} setShowSnackbar={setShowSnackbar}
                    setAddSuccessMsg={setAddSuccessMsg}
                    setAddErrorMsg={setAddErrorMsg} />
              </Grid>
            ))}
          </Grid>
          :
          <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>

          }
        </Container>
        <Box pt={"2rem"}>
          <SolutionSection />
        </Box>
        <Box pb={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight padding="0" />
        </Container>
      </Box>
      <ProductModal setOpenModal={setOpenModal} openModal={openModal} addToCartFunc={addToCartFunc} loadingKit={loadingKit} />
      <GuideModal setOpenModal={setGuideModal} openModal={guideModal} />
    </>
  );
};

export default GoBuy;
