export const videoData=[
    {
        id:1,
        title:"Full Tutorials",
        lists:[
            {
                text:"Data Collection",
                link:"https://admin.betterphysiology.com/trecordings/63473d1792c5dData%20collection%20&%20Records%20Video%20P6.0.mp4"
            },
            {
                text:"Cloud Database Introduction",
                link:"https://admin.betterphysiology.com/trecordings/62f54555f374bCloud%20Database%20Intoductory%20Tutorial.mp4"
            },
            {
                text:"P6.0 Software Introduction",
                link:"https://admin.betterphysiology.com/trecordings/6196b651d2345P6.0%20Software%20Introductory%20Tutorial.mp4"
            },
            {
                text:"Task Management Protocols",
                link:"https://admin.betterphysiology.com/trecordings/6196b6e236e91Task%20Management%20Video%20P6.0.mp4"
            },
            {
                text:"Breathing Testing Protocols",
                link:"https://admin.betterphysiology.com/trecordings/6196b7d76b27aBreathing%20Testing%20Video%20P6.0.mp4"
            },
        ]
    },
    {
        id:2,
        title:"Webinars",
        lists:[
            {
                text:"CapnoTrainer GO – Computer Software",
                link:"https://admin.betterphysiology.com/trecordings/645e8075b1979CapnoTrainer_Go_Software_Webinar.mp4"
            },
            {
                text:"CapnoTrainer software",
                link:"https://admin.betterphysiology.com/trecordings/63473438ef801CapnoTrainer_Software_webinar.mp4"
            },
        ]
    },
  
    {
        id:3,
        title:"How To",
        lists:[
            {
                text:"Use Mobile Phone App",
                link:"https://admin.betterphysiology.com/trecordings/62cf0072ec58bInstall_And_Use_Mobile_App.mp4"
            },
            {
                text:"Auto Update CapnoTrainer Computer Software",
                link:"https://admin.betterphysiology.com/trecordings/62cf00366a096Auto_Update_The_Software.mp4"
            },
            {
                text:"Test Water Traps",
                link:"https://admin.betterphysiology.com/trecordings/62cefff9a24daTest_Water_Traps.mp4"
            },
            {
                text:"Use HRV Option",
                link:"https://admin.betterphysiology.com/trecordings/62ceffbdbc4c0Use_HRV_Option.mp4"
            },
            {
                text:"Perform Zero Calibration (P6.0)",
                link:"https://admin.betterphysiology.com/trecordings/62ceff38af87fZero_Calibration.mp4"
            },
            {
                text:"Download Images And Notes",
                link:"https://admin.betterphysiology.com/trecordings/6193a22b90581download_images_and_notes.mp4"
            },
            {
                text:"Record Session",
                link:"https://admin.betterphysiology.com/trecordings/6193a25b30b35record_a_session.mp4"
            },
            {
                text:"Create New Trainer Profile",
                link:"https://admin.betterphysiology.com/trecordings/6193a275b7875new_trainer_profile.mp4"
            },
            {
                text:"Create New Client Profile",
                link:"https://admin.betterphysiology.com/trecordings/6193a2876428enew_client_profile.mp4"
            },
            {
                text:"Create New Client Profile",
                link:"https://admin.betterphysiology.com/trecordings/6193a2876428enew_client_profile.mp4"
            },
            {
                text:"Unregister CapnoTrainer P6.0",
                link:"https://admin.betterphysiology.com/trecordings/6193a2a84b23dunregister_capnotrainers.mp4"
            },
            {
                text:"Register CapnoTrainer P6.0",
                link:"https://admin.betterphysiology.com/trecordings/6193a2bc390e4register_capnotrainers.mp4"
            },
        ]
    },
]