import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import SolutionSection from "./SolutionSection";
import demo from "../../Assets/Images/go-brochure/demo.png";
import chat from "../../Assets/Images/go-brochure/chat.svg";
import user from "../../Assets/Images/go-brochure/user.svg";
import gmail from "../../Assets/Images/gmail_dark.svg";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component={"img"}
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const GoDemo = () => {
  const goDemoData = [
    {
      id: 1,
      instruments: "enhanced efficacy of your work;",
      serialNo: "1",
    },
    {
      id: 2,
      instruments: "exponential growth of your marketplace; and",
      serialNo: "2",
    },
    {
      id: 3,
      instruments: "significant increase in your potential income.",
      serialNo: "3",
    },
  ];
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .grid": {
            mt: { md: "-60px", xs: "0" },
          },
          "& .main_box": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            background: "#fff",
            p: "5px 15px",
            borderRadius: "5px",
            my: "1rem",
          },
          "& .text_2,a": {
            color: "#49194E",
            fontSize: "16px",
            fontFamily: "AvenirLTStdBook",
            textDecoration: "none",
            // fontWeight: "600",
          },
          "& .img_box": {
            lineHeight: "0",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6} xs={12}>
              <Typography className="title" pb={"1rem"}>
                Request Demonstration
              </Typography>
              <Typography className="subTitle" sx={{ width: { md: "80%" } }}>
                We arrange live demonstrations by appointment on our Zoom
                Webinar platform. You can operate the instrument located in our
                offices from your own location. In this way, you can see for
                yourself how assessment and learning sessions can be
                accomplished over the Internet with clients and trainees.
              </Typography>
              <Box
                mt={3}
                sx={{
                  background: "#fff",
                  borderRadius: "10px",
                  p: { md: "2.5rem 2rem", xs: "1.5rem 1rem" },
                  width: { md: "90%" },
                  position: "relative",
                  bottom: { md: "-2rem" },
                }}
              >
                <Typography className="subTitle" pb={"2rem"}>
                  <span style={{ fontWeight: "600", color: "#782980" }}>
                    Renting instruments
                  </span>{" "}
                   to clients for learning in the field and offering breathing
                  services over the Internet means:
                </Typography>
                {goDemoData.map((e, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      pb: "1rem",
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "100%",
                        background: "#C8A7CB",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: { sm: "24px", xs: "20px" },
                        color: "#782980",
                        fontWeight: "600",
                      }}
                    >
                      {e.serialNo}
                    </Box>
                    <Typography
                      className="subTitle"
                      sx={{ color: "#000 !important" }}
                    >
                      {e.instruments}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>

            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  lineHeight: "0",
                  position: "relative",
                  background: "#C8A7CB",
                  borderRadius: "10px",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                  textAlign: "center",
                }}
              >
                {/* Icon Boxes */}
                <IconBox
                  src={chat}
                  sx={{
                    top: { md: "10px", xs: "0px" },
                    left: { md: "-65px", xs: "-20px" },
                  }}
                />
                <IconBox
                  src={user}
                  sx={{
                    bottom: { md: "-30px", xs: "-10px" },
                    right: { md: "-30px", xs: "-10px" },
                  }}
                />

                {/* Demo Image */}
                <Typography
                  component={"img"}
                  src={demo}
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                    left: "-10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              m: "0 auto",
              width: { md: "80%", xs: "100%" },
              background: "#782980",
              borderRadius: "10px",
              p: { md: "5rem 4rem 4rem", xs: "3rem 2rem 2rem" },
              "& a":{
                fontWeight:"500 !important"
              }
            }}
          >
            <Typography className="title" sx={{ color: "#fff !important" }}>
              To schedule a live demonstration, please send an email to
            </Typography>
            <Box display={"inline-block"}>
              <Box
                className="main_box"
                component={"a"}
                href="mailto:pl@betterphysiology.com"
              >
                <Typography className="img_box">
                  <Typography component={"img"} src={gmail} />
                </Typography>

                <Typography className="text_2">
                  <a href="mailto:pl@betterphysiology.com">
                    pl@betterphysiology.com
                  </a>
                </Typography>
              </Box>
            </Box>
            <Typography
              className="subTitle"
              sx={{ color: "#fff !important", fontSize: "24px !important" }}
            >
              Our team will promptly arrange an appointment to showcase how we
              can elevate your practice.
            </Typography>
          </Box>
        </Container>
        <Box pt={"2rem"}>
          <SolutionSection />
        </Box>
        <Box pb={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default GoDemo;
