import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import logo_banner from "../../Assets/Images/big_logo.png";
import services from "../../Assets/Images/services_.png";
import track_record from "../../Assets/Images/track_record.png";
import consultation from "../../Assets/Images/consultation.svg";
import goSupport from "../../Assets/Images/goSupport.svg";
import goVideo from "../../Assets/Images/goVideo.svg";
import training from "../../Assets/Images/training.svg";
import BP_icon from "../../Assets/Images/BP_icon.png";

import client_1 from "../../Assets/Images/client_1.png";
import client_2 from "../../Assets/Images/client_2.png";
import client_3 from "../../Assets/Images/client_3.png";
import client_4 from "../../Assets/Images/client_4.png";
import bp_text from "../../Assets/Images/bp_text.png";
import bg_client from "../../Assets/Images/bg_client.png";
import gmail from "../../Assets/Images/gmail.svg";
import phone from "../../Assets/Images/phone.svg";
import ebook from "../../Assets/Images/ebook.svg";
import course from "../../Assets/Images/course.svg";
import ServiceCard from "./ServiceCard";
import Clients from "./Clients";
import TrackClient from "./TrackClient";
import NewsLetter from "./NewsLetter";
import GetInTouch from "./GetInTouch";
import CopyRight from "../../Components/CopyRight";
import Footer from "../../Components/Footer";
import Founder from "./Founder";
import StorySlider from "./StorySlider";
import CapnoDevice from "./CapnoDevice";
import Banner from "./Banner";
import BreathingSlider from "./BreathingSlider";
import ImageGallery from "./ImageGallery";

const data = [
  {
    image: consultation,
    title: "CONSULTATION",
    link:"/consultation"
  },
  {
    image: goSupport,
    title: "GO SUPPORT",
     link:"/go-support"
  },
  {
    image: goVideo,
    title: "GO VIDEO",
    link:"/go-video"

  },
  {
    image: training,
    title: "TRAINING",
    link:"/training"

  },
];
const track = [
  {
    num: "24+",
    title: "Years of Experience",
  },
  {
    num: 76,
    title: "Products Distributed",
  },
  {
    num: 92,
    title: "Trusted Clients",
  },
  {
    num: 34,
    title: "Awards & Recognitions",
  },
];
const client = [
  {
    image: client_1,
  },
  {
    image: client_2,
  },
  {
    image: client_3,
  },
  {
    image: client_4,
  },
];
const Home = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Box>
      <Banner />
      <Box
        sx={{
          background: "linear-gradient(to top, #DBD5E8 0%, #C8A7CB 100%)",
          // height: { lg: "500px" },
          // "@media (min-width:1400px)": {
          //   height: "629px",
          // },
          textAlign: "center",
          p: "1rem",
          position: "relative",
        }}
      >
        <Box
          className="main_div"
          sx={{
            position: { md: "absolute", xs: "static" },
            top: "-42%",
            right: { md: "5%", xs: "0" },
            width: { md: "370px", xs: "100%" },

            // transform: { md: "translate(-5%,-70%)", xs: "translate(-0%,-70%)" },
            zIndex: "1000",
            background: {
              md: isHovered ? "#fff" : "rgb(45 45 45 / 18%)",
              xs: isHovered ? "#fff" : "rgb(45 45 45 / 38%)",
            },
            // background: isHovered ? "#fff" : "rgb(45 45 45 / 18%)",
            backdropFilter: "blur(15px)",
            transition: "0.2s all",
            borderRadius: "3px",
          }}
        >
          <Box
            sx={{
              p: "2.5rem 1.2rem",
              position: "relative",
              "& .ebook_h": {
                transition: "0.2s all",
                background: "#fff",
                p: "1.5rem 1.5rem 0rem 0rem",
                borderRadius: "0.2rem",
                display: "none",
                position: "absolute",
                left: "-38%",
                transform: "translateX(-38%)",
                top: "0%",
                "&::after": {
                  position: "absolute",
                  right: "-11%",
                  display: "block",
                  top: "20%",
                  transform: "translateY(-20%)",
                  background: "#fff",
                  height: "15px",
                  content: '""',
                  fontSize: 0,
                  lineHeight: 0,
                  width: "50px",
                },
              },
              "& .course_h": {
                transition: "0.2s all",
                background: "#fff",
                p: "1.5rem 0 0rem 0rem",
                borderRadius: "0.2rem",
                display: "none",
                position: "absolute",
                left: "-40%",
                transform: "translateX(-40%)",
                bottom: "2%",
                "&::after": {
                  position: "absolute",
                  right: "-8%",
                  display: "block",
                  top: "40%",
                  transform: "translateY(-40%)",
                  background: "#fff",
                  height: "15px",
                  content: '""',
                  fontSize: 0,
                  lineHeight: 0,
                  width: "50px",
                },
              },
            
            }}
          >
            <Typography
              sx={{
                color: isHovered?"#000":"#ffff",
                fontSize: "15px",
                letterSpacing: "2px",
                fontFamily: "AvenirLTStdRoman",
                // fontWeight: "bold",
                pb: "2rem",
                "& a": {
                  color: "#C8A7CB",
                  fontWeight: "bold",
                },
                "&:hover": {
                  "& .ebook_h": {
                    display: "block",
                  },
                },
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Typography
                component={"span"}
                className="ebook"
                sx={{ color: "#C8A7CB", fontSize: "20px", fontWeight: "600" }}
              >
                E-BOOK:
              </Typography>{" "}
              CapnoLearning <a href="https://www.thebsj.org/capnolearning?ref=https%3A%2F%2Fwww.thebsj.org%2Fa%2F2147732394%2FGYhjaEjN" target="_blank" rel="noreferrer">(Purchase)</a> An Introductory Guide{" "}
              <b>($35)</b>
              <Box className="ebook_h">
                <Typography component={"img"} src={ebook} />
              </Box>
            </Typography>
            <Typography
              sx={{
                color: isHovered?"#000":"#ffff",
                fontSize: "15px",
                letterSpacing: "2px",
                fontFamily: "AvenirLTStdRoman",
                // fontWeight: "bold",
                pb: "2rem",
                "& a": {
                  color: "#C8A7CB",
                  fontWeight: "bold",
                },
                "&:hover": {
                  "& .course_h": {
                    display: "block",
                  },
                },
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Typography
                component={"span"}
                className="course"
                sx={{ color: "#C8A7CB", fontSize: "20px", fontWeight: "600" }}
              >
                COURSE:
              </Typography>{" "}
              CapnoLearning <a href="https://www.thebsj.org/offers/DpW8m8MA/checkout" target="_blank" rel="noreferrer">(Purchase)</a> Audio & Video Course{" "}
              <b>($199)</b>
              <Box className="course_h">
                <Typography component={"img"} src={course} />
              </Box>
            </Typography>
            <Typography
              sx={{
                color: isHovered?"#000":"#ffff",
                fontSize: "15px",
                letterSpacing: "2px",
                fontFamily: "AvenirLTStdRoman",
              }}
            >
              Make a tax-deductible purchase from Breathing Science, Inc. A
              501(c)(3) nonprofit corporation.
            </Typography>
          </Box>
        </Box>
        <Container>
          <Box
            sx={{
              width: { md: "85%", xs: "100%" },
              m: "0 auto",
              p: { lg: "5rem 0 11rem", xs: "5rem 0 8rem" },
            }}
          >
            <Typography
              component={"img"}
              src={logo_banner}
              sx={{
                width: "100%",
              }}
            />
            <Typography
              sx={{
                mt: { lg: "-3rem" },
                color: "#49194E",
                fontSize: { md: "20px", xs: "18px" },
                // letterSpacing: "2px",
                fontFamily: "AvenirLTStdRoman",
                textAlign: "end",
                fontWeight: "500",
              }}
            >
              {/* -Focused on enhancing health and performance through advanced
              respiratory
              <br />
              technology and scientifically backed breathing techniques. */}
              CapnoLearning technology, hardware and software that weaves together
              <br />
              physiology and psychology for learning breathing habits that optimize respiration
            </Typography>
          </Box>
        </Container>
      </Box>
      <CapnoDevice />
      <BreathingSlider />
      <Box
        py={"2rem"}
        sx={
          {
            // background: "#E4D5F4",
          }
        }
      >
        <Grid container spacing={3} p={"1rem"}>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              component={"img"}
              src={services}
              sx={{
                width: { lg: "90%", xs: "100%" },
              }}
            />
          </Grid>
          <Grid
            item
            lg={8}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <Typography
              component={"img"}
              src={BP_icon}
              sx={{
                position: "absolute",
                top: { md: "5%", xs: "10%" },
                right: { md: "5%", xs: "-2%" },
                width: { md: "250px", xs: "150px" },
              }}
            />

            <Box
              sx={{
                "@media (min-width:1400px)": {
                  width: "80%",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#782980",
                  fontSize: "24px",
                  fontFamily: "AvenirLTStdRoman",
                  fontWeight: "600",
                  // letterSpacing: "2px",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    display: "inline-block",
                    width: "50px",
                    height: "1px",
                    backgroundColor: "#782980",
                    marginRight: "3px",
                  }}
                />{" "}
                Our Services
              </Typography>
              <Typography
                sx={{
                  pt: "15px",
                  color: "#49194E",
                  fontSize: "16px",
                  fontFamily: "AvenirLTStdRoman",
                  width: { sm: "50%", xs: "100%" },
                }}
              >
                Bringing you the best in breathing solutions for a healthier,
                happier you.
              </Typography>

              <Box my={"1rem"}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: { sm: "2rem", xs: "1rem" },
                  }}
                >
                  {data?.map((data, i) => (
                    <ServiceCard key={i} data={data} />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box my={"8rem"} textAlign={"center"}>
          <Founder />
        </Box>
        <Box pt={"5rem"} mb={"6rem"} textAlign={"center"}>
          <Typography
            sx={{
              color: "#782980",
              fontSize: "24px",
              fontFamily: "AvenirLTStdRoman",
              fontWeight: "600",
              // letterSpacing: "2px",
            }}
          >
            Meet Our Clients
          </Typography>

          <Typography
            sx={{
              color: "#49194E",
              fontSize: "16px",
              fontFamily: "AvenirLTStdRoman",
              fontWeight: "600",
              // letterSpacing: "2px",
            }}
          >
            Empowering Healthcare Together
          </Typography>
          <Box mt={"2rem"}>
            <Grid container spacing={2}>
              {client?.map((data, i) => (
                <Grid item lg={3} sm={6} xs={12}>
                  <Clients key={i} data={data} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Track record section start */}
      <Box
        // my={"4rem"}

        sx={{
          background: "#E4D5F4",
          p: { lg: "2rem 0", xs: "1rem 0" },
          position: "relative",
        }}
      >
        <StorySlider />
      </Box>
      <Box
        my={"5rem"}
        sx={{
          display:{lg:"block",xs:"none"}
        }}
      >
       <ImageGallery/>
      </Box>
      <Box
        py={"2rem"}
        sx={{
          background: "#C8A7CB",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bg_client})`,
            backgroundPosition: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: { sm: "100% 100%", xs: "cover" },
          }}
        >
          <Grid container spacing={3} p={"1rem"}>
            <Grid item lg={5} xs={12} textAlign={"center"}>
              <Typography
                component={"img"}
                src={track_record}
                sx={{
                  width: { md: "70%", xs: "100%" },
                }}
              />
            </Grid>
            <Grid
              item
              lg={7}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: { sm: "3rem", xs: "2rem" },

                    "@media (min-width:1400px)": {
                      width: "80%",
                    },

                    justifyContent: "center",
                  }}
                >
                  {track?.map((data, i) => (
                    <TrackClient key={i} data={data} />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* Track record section end */}
      {/* news letter section start */}
      <Box
        mb={"3rem"}
        sx={{
          background: "#C8A7CB",
        }}
      >
        <NewsLetter />
      </Box>
      {/*  news letter  section end */}
      {/* contact section start */}
      <Box p={"1rem"}>
        <Typography
          component={"img"}
          src={bp_text}
          sx={{
            width: "100%",
          }}
        />
        <Box
          sx={{
            background: "#782980",
            borderRadius: "5px",
            p: { sm: "3rem 2rem 2rem", xs: "1rem" },
            width: { lg: "75%", sm: "90%", xs: "100%" },
            m: { lg: "-5rem auto 0", xs: "-1.5rem auto 0" },
            position: "relative",
            zIndex: "1",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.52)",
          }}
        >
          <Grid container spacing={3}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { sm: "30px", xs: "25px" },
                  fontFamily: "AvenirLTStdRoman",
                  fontWeight: "600",
                }}
              >
                We’d Love to HEAR FROM YOU!
              </Typography>

              <Typography
                sx={{
                  color: "#E2E2E2",
                  fontSize: "20px",
                  fontFamily: "AvenirLTStdRoman",
                  fontWeight: "600",
                  pb: "2rem",
                }}
              >
                Fill out the form for tailored advice on improving your
                respiratory health.
              </Typography>
              <GetInTouch />
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderLeft: { md: "1px solid #fff" },
                  my: "2rem",
                  p: { sm: "1.2rem", xs: "0rem" },
                  "& img": {
                    width: "25px",
                    verticalAlign: "middle",
                  },
                  "& span": {
                    color: "#E2E2E2",
                    fontSize: { lg: "17px", xs: "16px" },
                    fontFamily: "AvenirLTStdRoman",
                  },
                  display: "grid",
                  gap: "1.5rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { sm: "30px", xs: "25px" },
                    fontFamily: "AvenirLTStdRoman",
                    fontWeight: "600",
                  }}
                >
                  Contact Information
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Typography>
                    <Typography component={"img"} src={phone} />
                  </Typography>{" "}
                  <span>Tel Support: +1.800.850.9795</span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    "& a":{
                      textDecoration:"none",
                      color:"inherit"
                    }
                  }}
                >
                  <Typography>
                    <Typography component={"img"} src={gmail} />
                  </Typography>{" "}
                  <span>Email: <a href="mailto:instruments@betterphysiology.com">instruments@betterphysiology.com</a></span>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/*  footer  section end */}
      <Box>
        <Footer />
      </Box>
      {/*  footer  section end */}
      <CopyRight />
    </Box>
  );
};

export default Home;
