import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import StorySliderBox from "./StorySliderBox";


const StorySlider = () => {
  return (
    <>
      <Typography
        sx={{
          fontFamily: "ArchivoBlack",
          fontSize: { lg: "107px",sm:"60px", xs: "33px" },
          background: "#F0EDF6",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "#F0EDF6", // Make the text transparent to show background color
          backgroundClip: "text",
          textFillColor: "#F0EDF6",
          position: "absolute",
          top: { sm: "-90px", xs: "-30px" },
          left: "0",
          width: "100%",
          textAlign: "center",
          textShadow: "2px 4px 5px rgba(0, 0, 0, 0.4)",
        }}
      >
        Stories of Success
      </Typography>
      <Container>
        <Grid container spacing={4} p={"1rem"} my={"2rem"} sx={{
            "& .swiper-pagination-bullet-active":{
                background:"#782980"
            },
            "& .swiper-pagination":{
                position:"static"
            },
        }}>
          <Grid item lg={3.5} xs={12} sx={{
            display:"flex",
            alignItems:"center"
          }}>
            <Typography
              sx={{
                color: "#49194E",
                fontSize: { sm: "20px", xs: "20px" },   
                fontFamily: "AvenirLTStdRoman",
                //   fontWeight: "600",
                textAlign:{lg:"start",xs:"center"}
              }}
            >
              Be inspired by the journeys of users and discover why CapnoTrainer
              is the leading choice for those dedicated to excellence in
              healthcare.
            </Typography>
          </Grid>
          <Grid item lg={8.5} xs={12} sx={{
             "& .swiper-button-prev, .swiper-button-next": {
            color: "#782980 ",
            background: "#F2EAF2",
            boxShadow: '0px 4px 10px rgba(120, 41, 128, 0.7)',
            width: "40px",
            height: "40px",
            borderRadius: "50%",
          },
          "& .swiper-button-prev:after, .swiper-button-next:after": {
            fontSize: "18px",
            fontWeight: "bold",
          },
          }}>
            <Swiper
              spaceBetween={20}
              slidesPerView={2.5}
            //   rewind={true}
            //   loop={true}
            //   autoplay={{
            //     delay: 1500,
            //     disableOnInteraction: false,
            //   }}
              speed={600}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                400: {
                  slidesPerView: 1.5,
                  spaceBetween: 10,
                },
                750: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 10,
                },
                1300: {
                  slidesPerView: 2.2,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 2.55,
                  spaceBetween: 10,
                },
              }}
              navigation
              pagination={{
          clickable: true,
        }}
              modules={[Autoplay, Navigation, Pagination]}
              className="mySwiper"
            >
              {[0, 1, 3,4,5].map((e, i) => (
                <SwiperSlide key={i}>
                 <StorySliderBox/>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default StorySlider;
