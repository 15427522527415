import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DistributorTabs from "./DistributorTabs";
import NewsLetter from "../../Components/NewsLetter";
import GoBackButton from "../../Components/GoBackButton";

const Distributors = () => {
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .heading": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            fontWeight: "600",
            color: "#782980",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Box
            sx={{
              background: "rgb(255 255 255 / 60%)",
              p: "1rem",
              mt:"2rem",
              display: "flex",
              gap: "0.5rem",
              justifyContent: { sm: "space-between", xs: "center" },
              flexWrap: "wrap",
            }}
          >
            <Typography className="heading">Distributors</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "2px",
                pr: { lg: "8px" },
                "&:hover": {
                  "& .hoverable": {
                    transform: "translateX(8px)",
                  },
                },
              }}
            >
              <Button
                className={"btn"}
                sx={{
                  fontSize: "18px",
                  textTransform: "capitalize",
                  //   width: "120px",
                  fontFamily: "AvenirLTStdBook",
                }}
                component="a"
                href="https://distributor.capnolearning.com/login"
                target="_blank"
                rel="noreferrer"
              >
                Distributor login
              </Button>
              <Button
                className={"btn hoverable"}
                sx={{
                  fontSize: "15px",
                  transition: "0.2s",
                }}
                component="a"
                href="https://distributor.capnolearning.com/login"
                target="_blank"
                rel="noreferrer"
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Box>
         <DistributorTabs/>
        </Container>
        <Box py={"2rem"}>
        <NewsLetter/>
        </Box>
        <Container>
        <Footer display="none" padding="0" />
        <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default Distributors;
