import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GoPersonalM = () => {
  return (
    <Box
      sx={{
        height:{md:"60vh"},
        "& p": {
          textAlign: "justify",
          fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
        },
      }}
    >
      <Typography pb={"1.5rem"}>
        The CapnoTrainer GO Personal with <b>Option M (for Multiple users)</b>{" "}
        provides for more than one user, where data can be independently
        collected for each user, including family, friends, and clients.
        Individual data records and PDF reports for all users may be reviewed or
        downloaded at any time.
      </Typography>

      <Typography pb={"1.5rem"}>
        Click on CapnoTrainer,{" "}
        <Link style={{ textDecoration: "underline",color:"#782980" }} to={"/go-Detail/12"}>
          <b>GO Personal</b>
        </Link>{" "}
        for more details.
      </Typography>
      <Typography pb={"1.5rem"}>
        GO Personal , Option M users may upgrade to GO Basic at any time by
        adding Option C ($300.00).
      </Typography>
    </Box>
  );
};

export default GoPersonalM;
