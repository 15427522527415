import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../redux/Constant";
import ReminderPopup from "./ReminderPopup";
import { getCart } from "../../store/cartSlice";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ProductCard = ({   data,
  setShowSnackbar,
  setAddSuccessMsg,
  setAddErrorMsg, }) => {
    const navigate=useNavigate()
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [productId, setProductId] = useState(null);
    const [productQuantity, setProductQuantity] = useState(null);
    const dispatch = useDispatch();
    const increment = () => {
      setCount(count + 1);
    };
    const decrement = () => {
      if (count > 0) {
        setCount(count - 1);
      }
    };
  
    const addToCartFunc = async (productId, quantity) => {
      setProductId(productId)
      setProductQuantity(quantity)
    if(productId==12||productId==14){
   setReminder(true)
    }else{
      addToCart(productId, quantity)
    }
  
    };
  
    const addToCart = async (productId, quantity) => {
     
      setLoading(true);
      try {
        const res = await axios.post(`${API_URL}/add-to-cart`, {
          user_id: localStorage.getItem("user")
            ? localStorage.getItem("user")
            : "",
          product_ids: [productId],
          quantities: [productQuantity||quantity],
        });
        if (res.status === 200) {
          console.log(res);
          setLoading(false);
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
            setAddSuccessMsg("");
          }, 2000);
          setAddSuccessMsg(res.data.message);
          const userId = res.data.user_id ;
          localStorage.setItem("user", res.data.user_id);
          dispatch(getCart(userId));
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 404) {
          setLoading(false);
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
            setAddErrorMsg("");
          }, 2000);
          setAddErrorMsg("Out of stock");
        }
      }
    }
  return (
    <Box
      sx={{
        
        background: "#fff",
        borderRadius: "10px",
        p: "1.5rem 1rem",
        transition: "0.3s all",
        "&:hover": {
          transform: "translateY(-8px)",
        },
      }}
     
    >
      <Typography
       onClick={()=>navigate(`/go-detail/${data?.id}`)}
        className="subTitle"
        sx={{ color: "#782980 !important", pb: "0.5rem", fontWeight: "600",height: "56px",cursor:"pointer", }}
      >
        {data?.name}
      </Typography>
      <Typography className="subTitle" sx={{ pb: "0.5rem", height: "56px" }}>
        {data?.subtitle}
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "space-between"}}
      >
        <Typography className="subTitle" sx={{ color: "#782980 !important" }}>
          Price
        </Typography>
        <Typography
          className="subTitle"
          sx={{ color: "#782980 !important", fontWeight: "600" }}
        >
          $ {data.price}
        </Typography>
      </Box>
      <Box
          pb={"1rem"}
          pt={"0.5rem"}
        sx={{
          
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          className="subTitle"
        >
          Quantity
        </Typography>
        <Box
      display="flex"
      alignItems="center"
      gap={2}
  
    >
      {/* Decrement Button */}
      <Button
        variant="outlined"
        disableRipple
        onClick={decrement}
        disabled={count==0} // Disable button when count is 1
        sx={{
          minWidth: "35px",
          height: "25px",
          padding: "0",
          fontSize: "1.5rem",
          pb:"0.2rem",
          color:"#49194E",
          borderColor:"#49194E",
          "&:hover":{
            borderColor:"#49194E",
          }
          // lineHeight: "0rem",
        }}
      >
        -
      </Button>

      {/* Count Display */}
      <Typography  className="subTitle" fontWeight={"600"}>{count}</Typography>

      {/* Increment Button */}
      <Button
      disableRipple
        variant="outlined"
        onClick={increment}
        sx={{
          minWidth: "35px",
          height: "25px",
          padding: "0",
          fontSize: "1.5rem",
          color:"#49194E",
          borderColor:"#49194E",
          // lineHeight: "0rem",
          "&:hover":{
            borderColor:"#49194E",
          }
        }}
      >
        +
      </Button>
    </Box>
      </Box>
     
      <Button
        className={"btn"}
        sx={{
          fontSize: "16px",
          textTransform: "capitalize",
          width: "100%",
          //   padding: "6px 20px !important",
          fontFamily: "AvenirLTStdBook",
          "&.Mui-disabled": { background: "#782980a1 !important" },
        }}
        disabled={count === 0}
                onClick={() => addToCartFunc(data?.id, count)}
      >
        {loading ? (
                  <CircularProgress
                    sx={{
                      width: "21px !important",
                      height: "21px !important",
                      color: "#e4dbe7",
                    }}
                  />
                ) : (
                  "Add to Cart"
                )}
      </Button>
      <ReminderPopup reminder={reminder} setReminder={setReminder} product_Id={productId} addToCart={addToCart} productQuantity={count}/>
    </Box>
  );
};

export default ProductCard;
