import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import consultation_img from "../../Assets/Images/consultation_img.png";
import icon_1 from "../../Assets/Images/consult_icon_1.svg";
import icon_2 from "../../Assets/Images/consult_icon_2.svg";
import NewsLetter from "../../Components/NewsLetter";
import consultation_1 from "../../Assets/Images/consultation_1.png";
import consultation_2 from "../../Assets/Images/consultation_2.png";
import consultation_3 from "../../Assets/Images/consultation_3.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import phone from "../../Assets/Images/phone_dark.svg";
import email from "../../Assets/Images/gmail_dark.svg";
import video from "../../Assets/Images/video.svg";
import CommonSection from "../../Components/CommonSection";
import videos from '../../Assets/Images/go-brochure/videos.png';
import support from '../../Assets/Images/go-brochure/support.png';
import training from '../../Assets/Images/go-brochure/training.png';
import GoBackButton from "../../Components/GoBackButton";


const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component="img"
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const Consultation = () => {
  return (
    <>
      <Navbar />
      <Box
     
        sx={{
          mt:{sm:"6rem",xs:"4rem"},
          "& .title,.title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .title_1": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle,.subTitle_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_": {
            color: "#C8A7CB",
            fontWeight: "600",
          },
       
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6} xs={12} sx={{
              display:"flex",
              alignSelf:"center"
            }}>
            <Box>
              <Typography className="title" pb="1rem">
                CapnoLearning <br /> CONSULTATION SERVICES
              </Typography>
              <Typography
                className="subTitle"
                pb="1rem"
                sx={{ width: { lg: "70%" },fontFamily: "AvenirLTStdBook !important", }}
              >
                CapnoLearning consultation services are available through the
                Behavioral Physiology Institute. To access these services,
              </Typography>
              <Box
                sx={{
                  // background: "#782980",
                  // borderRadius: "10px",
                  // p: "2rem 3rem",
                  my: "1rem",
                  display: "grid",
                  gap: "1.5rem",
                  "& .img_box": {
                    width: "45px",
                    height: "45px",
                    borderRadius: "50%",
                    // border: "1px solid #C8A7CB",
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                  },
                  "& .text_1": {
                    color: "#782980",
                    fontSize: "16px",
                    fontFamily: "AvenirLTStdBook",
                  },
                  "& .text_2": {
                    color: "#49194E",
                    fontSize: "16px",
                    fontFamily: "AvenirLTStdBook",
                  },
                  "& .main_box": {
                    display: "flex",
                    gap: "10px",
                  },
                  "& a": {
                    textDecoration: "none",
                  },
                  "& .main_wrapper": {
                    // p: "0.5rem",
                    // transition: "0.3s all",
                    // borderRadius: "5px",
                    // "&:hover": {
                    //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                    // },
                  },
                }}
              >
                <Box className="main_wrapper">
                  <Box className="main_box">
                    <Typography className="img_box">
                      <Typography component={"img"} src={phone} />
                    </Typography>
                    <Box>
                      <Typography className="text_1">Call</Typography>
                      <Typography className="text_2">
                        +1.800.850.9795
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box className="main_wrapper">
                  <Box className="main_box">
                    <Typography className="img_box">
                      <Typography component={"img"} src={email} />
                    </Typography>
                    <Box>
                      <Typography className="text_1">Email</Typography>
                      <Typography
                        className="text_2"
                        component={"a"}
                        href="mailto:sr@bp.edu"
                      >
                        sr@bp.edu
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="main_wrapper">
                  <Box className="main_box">
                    <Typography className="img_box">
                      <Typography component={"img"} src={video} />
                    </Typography>
                    <Box>
                      <Typography className="text_1">
                        Set an appoinment
                      </Typography>
                      <Typography
                        className="text_2"
                        component={"a"}
                        target="_blank"
                        href="https://www.respimatters.com/"
                      >
                        www.CapnoLearning.org.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  // background: "#C8A7CB",
                  // borderRadius: "10px",
                  // lineHeight: "0",
                  // textAlign: "center",
                  // mt: { md: "0", xs: "1.5rem" },
                  // ml: { md: "0px", xs: "10px" },
                  // mr: { md: "15px", xs: "0px" },
                }}
              >
                <IconBox
                  src={icon_1}
                  sx={{
                    bottom: { md: "10px", xs: "10px" },
                    left: { md: "15px", xs: "15px" },
                  }}
                />
                <IconBox
                  src={icon_2}
                  sx={{
                    bottom: { md: "10px", xs: "10px" },
                    right: { md: "5px", xs: "5px" },
                  }}
                />
                <Typography
                  component="img"
                  src={consultation_img}
                  sx={{
                    width: "100%",
               
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={0} sx={{
            mt: { lg: "20px",md:"20px",xs: "0" }
          }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: {lg:"4rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p":{
                    fontFamily:"AvenirLTStdBook !important"
                  }
                }}
              >
                <Typography className="subTitle">
                  The Institute provides breathing assessment and learning
                  sessions for Personal GO users, CapnoLearning instructional
                  services for Professional GO practitioners who need
                  assistance, and case review for practitioners wanting advice
                  and feedback on specific cases.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "160px", xs: "100px" },
                    position: "absolute",
                    borderLeftWidth: { md: "0 !important" },
                    border: "1px solid #782980",
                    top: "50%",
                    left: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    width: { lg: "318px",md:"280px",sm:"300px", xs: "200px" },
                    height: { lg: "430px",md:"380px",sm:"410px", xs: "220px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "4rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign:"center"
                  }}
                >
                  <Typography
                    component={"img"}
                    src={consultation_1}
                    sx={{ width: { lg: "90%", xs: "100%" } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{
            mt: { lg: "20px",md:"20px",xs: "0" }
          }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: { lg:"4rem",sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p":{
                    fontFamily:"AvenirLTStdBook !important"
                  }
                }}
              >
                <Typography className="subTitle">
                  The Institute provides breathing assessment and learning
                  The institute provides CapnoLearning practicum supervision for the Professional School of Behavioral Health Sciences that trains practitioners to become Certified as Breathing Behavior Analysts as well as those seeking the Professional Diploma in Behavioral Breathing Science.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "160px", xs: "100px" },
                    position: "absolute",
                    borderLeftWidth: { md: "0 !important" },
                    border: "1px solid #782980",
                    top: "50%",
                    left: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    width: { lg: "318px",md:"280px",sm:"300px", xs: "200px" },
                    height: { lg: "430px",md:"380px",sm:"410px", xs: "220px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "4rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign:"center"
                  }}
                >
                  <Typography
                    component={"img"}
                    src={consultation_2}
                    sx={{ width: { lg: "90%", xs: "100%" } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={0} sx={{
            mt: { lg: "20px",md:"20px",xs: "0" }
          }}>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Box
                sx={{
                  p: {lg:"4rem", sm: "3rem", xs: "1.5rem" },
                  borderRadius: "5px",
                  background: "#fff",
                  height: "100%",
                  "& p":{
                    fontFamily:"AvenirLTStdBook !important"
                  }
                }}
              >
                <Typography className="subTitle">
                The Institute manages a network of Certified Breathing Behavior Analysts worldwide, who can provide breathing learning services for those with dysfunctional breathing habits and/or for those interested in optimizing respiration and its associated acid-base physiology.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box position={"relative"}>
                <Box
                  sx={{
                    width: { sm: "160px", xs: "80px" },
                    height: { sm: "160px", xs: "100px" },
                    position: "absolute",
                    borderLeftWidth: { md: "0 !important" },
                    border: "1px solid #782980",
                    top: "50%",
                    left: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    width: { lg: "318px",md:"280px",sm:"300px", xs: "200px" },
                    height: { lg: "430px",md:"380px",sm:"410px", xs: "220px" },
                    position: "absolute",
                    border: "1px solid #782980",
                    top: "50%",
                    right: "0",
                    transform: "translateY(-50%)",
                  }}
                />
                <Box
                  sx={{
                    p: { md: "4rem", sm: "3rem", xs: "2rem" },
                    "& img": {
                      position: "relative",
                    },
                    textAlign:"center"
                  }}
                >
                  <Typography
                    component={"img"}
                    src={consultation_3}
                    sx={{ width: { lg: "90%", xs: "100%" } }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box pt={"2rem"}>
          <CommonSection title={<>
            Explore<br/> Our <br/> Other <br/>Services
          </>}  btn_1={"Go Videos"} btn_2={"GO Support"} btn_3={"Training"} img_1={videos} img_2={support} img_3={training} link_1={"/go-video"} link_2={"/go-support"} link_3={"/training"}/>
        </Box>
        <Box pb={"2rem"}>
        <ProductNewsLetter text={"Have questions or need further clarification? you can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight/>
        </Container>
      </Box>
    </>
  );
};

export default Consultation;
