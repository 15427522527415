import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../Assets/Images/new_logo.png";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = ({display,padding}) => {
  return (
    <>
      <Box
        sx={{
          p: padding?padding:"2rem",
          background: display?display:"#fff",
          lineHeight: "0",
          "& a": {
            color: "#782980",
          },
        }}
      >
        <Grid container spacing={2} pt={"3.5rem"}>
          <Grid item lg={2} md={3} sm={6} xs={12}>
            <Box>
              <Link to={"/"}>
                {" "}
                <Typography component={"img"} src={logo} width={"100%"} />
              </Link>
            </Box>
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <Box
              sx={{
                width: { sm: "50%", xs: "100%" },
                margin: "0 auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "22px", xs: "20px" },

                  color: "#782980",
                  fontWeight: "bold",
                  fontFamily: "AvenirLTStdRoman",
                }}
              >
                Company
              </Typography>
              <Box
                sx={{
                  "& a": {
                    fontSize: { md: "15px", xs: "14px" },
                    color: "#782980",
                    py: "0.3rem",
                    fontFamily: "AvenirLTStdRoman",
                    textDecoration: "none",
                  },
                }}
              >
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/contact"}
                >
                  Contact
                </Typography>
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/distributors"}
                >
                  Distributors
                </Typography>
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/history"}
                >
                  History
                </Typography>
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/mission"}
                >
                  Mission
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <Box>
              <Typography
                sx={{
                  fontSize: { md: "22px", xs: "20px" },

                  color: "#782980",
                  fontWeight: "bold",
                  fontFamily: "AvenirLTStdRoman",
                }}
              >
                Products
              </Typography>
              <Box
                sx={{
                  "& p,a": {
                    fontSize: { md: "15px", xs: "14px" },

                    color: "#782980",
                    py: "0.3rem",
                    fontFamily: "AvenirLTStdRoman",
                    textDecoration: "none",
                  },
                }}
              >
                <Typography sx={{ display: "block" }} component={"a"} href="/go-brochure">
                  GO Brochure
                </Typography>
                <Typography sx={{ display: "block" }} component={"a"} href="/go-buy">
                  GO Buy
                </Typography>
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/go-demo"}
                >
                  GO Demo
                </Typography>
                <Typography
                  sx={{ display: "block" }}
                  component={"a"}
                  href={"/go-overview"}
                >
                  GO Overview
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <Typography
              sx={{
                fontSize: { md: "22px", xs: "20px" },

                color: "#782980",
                fontWeight: "bold",
                fontFamily: "AvenirLTStdRoman",
              }}
            >
              Education
            </Typography>
            <Box
              sx={{
                "& p,a": {
                  fontSize: { md: "15px", xs: "14px" },

                  py: "0.3rem",
                  color: "#782980",
                  fontFamily: "AvenirLTStdRoman",
                  textDecoration: "none",
                },
              }}
            >
              <Typography sx={{ display: "block" }} component={"a"} href={"/book"}>
                Book
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/course"}>
                Course
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/interview"}>
                Interviews
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/lectures"}>
                Lectures
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/references"}>
                References
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/professional-education"}>
                Professional School
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <Typography
              sx={{
                fontSize: { md: "22px", xs: "20px" },

                color: "#782980",
                fontWeight: "bold",
                fontFamily: "AvenirLTStdRoman",
              }}
            >
              Services
            </Typography>
            <Box
              sx={{
                "& p,a": {
                  fontSize: { md: "15px", xs: "14px" },

                  py: "0.3rem",
                  color: "#782980",
                  fontFamily: "AvenirLTStdRoman",
                  textDecoration: "none",
                },
              }}
            >
              <Typography sx={{ display: "block" }} component={"a"} href={"/consultation"}>
                Consultation
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/go-support"}>
                GO Support
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/go-video"}>
                GO Videos
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/training"}>
                Training
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <Typography
              sx={{
                fontSize: { md: "22px", xs: "20px" },

                color: "#782980",
                fontWeight: "bold",
                fontFamily: "AvenirLTStdRoman",
              }}
            >
              Software
            </Typography>
            <Box
              sx={{
                "& p,a": {
                  fontSize: { md: "15px", xs: "14px" },

                  py: "0.3rem",
                  color: "#782980",
                  fontFamily: "AvenirLTStdRoman",
                  textDecoration: "none",
                },
              }}
            >
              <Typography sx={{ display: "block" }} component={"a"} href={"/go-download"}>
                GO Downloads
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/go-quickstart"}>
                GO Quickstart
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/go-manual"}>
                GO Manuals
              </Typography>
              <Typography sx={{ display: "block" }} component={"a"} href={"/download-5.0"}>
                5.0 Instruments
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} py={"1.5rem"}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
          <Box sx={{
            display:"flex",
            flexWrap:"wrap",
            gap:"1.5rem",
            alignItems:"center",
            justifyContent:"center"
          }}>
          <Typography
              sx={{
                fontSize: { lg: "35px", xs: "25px" },
                color: "#782980",
                fontWeight: "bold",
                fontFamily: "AvenirLTStdRoman",
              }}
            >
              STAY IN LOOP
            </Typography>
         
          </Box>
          </Grid>
          <Grid
            item
            lg={9}
            md={8}
            sm={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap:"wrap",
              justifyContent:{lg:"start",xs:"center"},
              gap:"1rem"
            }}
          >
             <Box sx={{
                "& svg":{
                    color:"#6D4FA0",
                    fontSize:{lg:"2.2rem",xs:"1.5rem"},
                    mr:"0.5rem"
                }
            }}>
            <a href="https://www.instagram.com/betterphysiology/?igsh=MWtpOXR6bHQ0MnB2ag%3D%3D&utm_source=qr" target="_blank" rel="noreferrer"><InstagramIcon /></a>
            <a href="https://www.linkedin.com/company/better-physiology-ltd/" target="_blank" rel="noreferrer"><LinkedInIcon /></a>
              
              
            </Box>
            <Typography
              sx={{
                width: {lg:"85%",xs:"100%"},
                // m:"10px auto 0",
                background: "#782980",
                height: "2px",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
