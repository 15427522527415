import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const GoProfessionalInfo = () => {
  return (
    <Box
      sx={{
        "& p": {
          textAlign: "justify",
          fontFamily: "AvenirLTStdRoman",
          fontSize: "16px",
          color: "#49194E",
        },
        "& li": {
          textAlign: "justify",
          fontFamily: "AvenirLTStdRoman",
          fontSize: "16px",
          color: "#49194E",
        },
  
      }}
    >
     <Box>
     <Typography pb={"1.5rem"}>
        The GO Professional is for practitioners, trainers, and consultants who
        provide breathing services as an integral part of their businesses,
        e.g., physiotherapy, athletic coaching, performance counseling. The
        Professional System includes the GO hardware and the GO Professional
        software, a comprehensive breathing service management system which
        includes a sophisticated HIPAA compliant database account in the Cloud.
      </Typography>
      <Typography pb={"1.5rem"}>
        The GO Professional is battery-operated, Bluetooth, wearable, light
        weight <br />
        (185 grams), small (fits in your pocket), and nearly silent (slight
        ticking sound). It operates on PC and Apple computers, tablets, I-pads,
        Android and i-phones. The GO software connects to most third-party HRV
        (e.g., Polar Belt) and SpO2 Bluetooth devices and will operate up to six
        GO, HRV, and SpO2 devices simultaneously in its Group mode; users may
        observe this additional physiology along with breathing physiology.
      </Typography>
     </Box>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography pb={"0.5rem"}>
            Unlike the GO Basic system, it uniquely provides for the following:
          </Typography>
          <Typography component={"ul"} pb={"1.5rem"}>
            <Typography component={"li"}>live tech support;</Typography>
            <Typography component={"li"}>
              advanced software features, e.g., HRV algorithms;
            </Typography>
            <Typography component={"li"}>
              management of multiple instruments ;
            </Typography>
            <Typography component={"li"}>
              management of multiple practitioners ;
            </Typography>
            <Typography component={"li"}>
              subaccounts and logins for clients and trainers;
            </Typography>
            <Typography component={"li"}>
              live training with clients in-the-field, (e.g., Zoom);
            </Typography>
            <Typography component={"li"}>
              tethering with clients who own their own GO instruments;
            </Typography>
            <Typography component={"li"}>
              in-the-field instrument rental management;
            </Typography>
            <Typography component={"li"}>
              reviewing homework assignments completed in-the-field;
            </Typography>
            <Typography component={"li"}>
              monitoring independent learning by clients in-the-field;
            </Typography>
            <Typography component={"li"}>
              conducting groupwork, working with up to six GO units at a time;
            </Typography>
            <Typography component={"li"}>
              automated (built-in) practicums for trainers and their clients;
            </Typography>
            <Typography component={"li"}>
              customizing data reports and their easy management;
            </Typography>
            <Typography component={"li"}>
              built-in forms for evaluating and learning breathing behavior;
            </Typography>
            <Typography component={"li"}>
              auto-assembly report functions; and
            </Typography>
            <Typography component={"li"}>
              integrating 3rd party Bluetooth physiological monitoring
              technology,
              <br /> e.g., EMG.
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              background: "#fff",
              borderRadius: "10px",
              p: "3rem 2rem",
              // float:"right",
              // width:"50%"
            }}
          >
            <Typography className="title" pb={"0.5rem"}>
              Do you need a GO Professional System? If ONE or more of the
              following is true, you will need the GO Professional System.
            </Typography>
            <Typography className="subTitle">
              “I want to take advantage of its advanced features, e.g., HRV
              algorithms.”
              <br />
              “More than one person will be using the GO for work with clients.”
              <br />
              “We will be using more than one GO in our practice.”
              <br />
              “Our clients will monitor themselves and collect data in the
              field.”
              <br />
              “We plan to rent instruments to clients.”
              <br />
              “We plan to do group work (i.e., work with multiple clients
              simultaneously).”
              <br />
              “We want to customize data reports and write report notes.”
              <br />
              “Clients and/or trainers will need their own logins to see their
              data.”
              <br />
              “We need additional assessment tools, such as interview forms.”
              <br />
              “We are using the GO for research purposes.”
            </Typography>
          </Box>
        </Grid>
      </Grid>
  
    </Box>
  );
};

export default GoProfessionalInfo;
