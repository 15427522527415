import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import contact_img from "../../Assets/Images/contact/contact_img.png";
import location from "../../Assets/Images/contact/location.png";
import phone from "../../Assets/Images/contact/phone.svg";
import email from "../../Assets/Images/contact/email.svg";
import fax from "../../Assets/Images/contact/fax.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GoBackButton from "../../Components/GoBackButton";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      fname: "",
      // lname: "",
      email: "",
      // phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      fname: Yup.string()
        .trim("Name should not contain spaces")
        .required("Required"),
      // lname: Yup.string()
      //   .trim("Last Name should not contain spaces")
      //   .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email")
        .required("Required"),
      message: Yup.string()
        .trim("Message should not contain spaces")
        .required("Required"),
      // phone: Yup.number().required("Required"),
    }),
    onSubmit: async (
      values,
      { setErrors, setSubmitting, resetForm, errors }
    ) => {
      console.log(values);

      if (errors) {
        setErrors(errors);
      }
      // createContact()
    },
  });
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "24px",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_2": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={1} my={"2rem"}>
            <Grid
              item
              lg={4}
              md={5}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography className="title" p={"1.2rem"}>
                  Reach Out to Us
                </Typography>
                <Box
                  sx={{
                    background: "#fff",
                    p: "1.5rem 1.2rem",
                    borderRadius: "10px",
                    width: { lg: "110%", xs: "100%" },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Box>
                        <Typography className="title">
                          Corporate Headquarters
                        </Typography>
                        <Typography className="title_2">
                          109 East 17th Street,
                          <br />
                          Cheyenne, WY 82001 USA
                        </Typography>
                      </Box>
                      <Box pt={"2rem"}>
                        <Typography className="title">
                          Santa Fe Office
                        </Typography>
                        <Typography className="title_2">
                          7 Camino de Rey Cir
                          <br />
                          Santa Fe, NM 87506 USA
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        component={"img"}
                        src={location}
                        sx={{ width: { lg: "80%", xs: "100%" } }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={8}
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                component={"img"}
                src={contact_img}
                width={"100%"}
                height={"100%"}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              background: "transparent",
              p: "2rem 1.5rem",
              position:"relative",
              borderRadius: "10px",
              "& .MuiDivider-root::before": {
                width: "0%",
              },
              "& .MuiDivider-root::after": {
                borderTop: "1.5px solid #782980",
              },
              "& .MuiDivider-wrapper": {
                pl: "0",
              },
            }}
          >
          <Box sx={{
            background: "#fff",
              p: "2rem 1.5rem",
              height:"100%",
              borderRadius:"10px",
              width:{md:"65%",xs:"100%"},
              position:"absolute",
              left:"0",
              top:"0"
          }} />
            <Divider textAlign="left" sx={{ pb: "1.5rem",position:"relative" }}>
              <Typography className="title">Reach Out to Our Team</Typography>
            </Divider>
            <Grid container spacing={3} position={"relative"}>
              <Grid
                item
                lg={7}
                md={6}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    "& .bin1": {
                      borderRadius: "5px",
                      border: "1px solid transparent",
                       boxShadow: "none",
                      width: "100%",
                      background: "#F0F0F0",
                      color: "#49194E",
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                      "& input[type=number]::-webkit-outer-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "& input[type=number]::-webkit-inner-spin-button": {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "&:focus-Visible": {
                        outline: "none !important",
                        border: "none !important",
                      },
                      "&:hover": {
                        borderBottom: "1px solid transparent !important",
                      },
                      "& .MuiInputBase-root": {
                        "&::before": {
                          display: "none",
                        },
                        "&::after": {
                          display: "none",
                        },
                      },

                      "&:focus": {
                        boxShadow: "none !important",
                        border: "1px solid #782980 !important",
                        borderRadius: "10px !important ",
                      },
                      "& input": {
                        padding: "10px",
                        width: "100%",
                        color: "#49194E",
                        fontSize: "18px",
                        fontWeight: "600",
                        fontFamily: "AvenirLTStdBook !important",
                        "&:focus": {
                          outline: "none",
                        },
                        "&::placeholder": {
                          color: "#49194E !important",
                          fontFamily: "AvenirLTStdBook !important",
                          fontSize: "16px",
                        },
                      },
                    },
                    "& .bin_2": {
                      background: "#F0F0F0",
                      borderRadius: "5px",
                      border: "1px solid transparent !important",
                      width: "100%",
                      padding: "10px",
                      height: "120px !important",
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#49194E",
                      fontFamily: "AvenirLTStdBook !important",
                      "&::placeholder": {
                        color: "#49194e5e !important",
                        fontFamily: "AvenirLTStdBook !important",
                        fontSize: "16px",
                      },
                      "&:focus": {
                        boxShadow: "none !important",
                        outline: "none",
                      },
                    },
                    "& .error": {
                      marginTop: "0.4rem !important",
                      fontSize: "13px !important",
                      color: "red !important",
                      fontFamily: "AvenirLTStdBook !important",
                      textAlign: "start",
                    },
                  }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            id="fname"
                            name="fname"
                            type="text"
                            className="bin1"
                            placeholder="Your Name"
                            variant="standard"
                            value={formik.values.fname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.fname && formik.errors.fname && (
                            <Typography variant="body1" className="error">
                              {formik.errors.fname}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            autoComplete="off"
                            fullWidth
                            id="email"
                            name="email"
                            type="email"
                            className="bin1"
                            placeholder="Your Email"
                            variant="standard"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <Typography variant="body1" className="error">
                              {formik.errors.email}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                          <TextareaAutosize
                            autoComplete="off"
                            fullWidth
                            id="message"
                            name="message"
                            type="text"
                            className="bin_2"
                            placeholder="Enter your message..."
                            variant="standard"
                            multiline={6}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.message && formik.errors.message && (
                            <Typography variant="body1" className="error">
                              {formik.errors.message}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>

                      <Box display={"inline-block"}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "2px",
                            pt: "1rem",
                            "&:hover": {
                              "& .hoverable": {
                                transform: "translateX(10px)",
                              },
                            },
                          }}
                        >
                          <Button
                            className={"btn"}
                            sx={{
                              fontSize: "18px",
                              textTransform: "capitalize",
                              width: "120px",
                              fontFamily: "AvenirLTStdBook",
                            }}
                          >
                            Send
                          </Button>
                          <Button
                            className={"btn hoverable"}
                            sx={{
                              fontSize: "15px",
                              transition: "0.2s",
                            }}
                            component={Link}
                            to="/"
                          >
                            <ArrowForwardIcon />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </Grid>
              <Grid item lg={5} md={6} xs={12}>
                <Box
                  sx={{
                    background: "#782980",
                    borderRadius: "10px",
                    p: {sm:"2rem 3rem",xs:"1rem 0.2rem"},
                    display: "grid",
                    gap: "10px",
                    "& .img_box": {
                      width: {sm:"45px",xs:"40px"},
                      height: {sm:"45px",xs:"40px"},
                      borderRadius: "50%",
                      border: "1px solid #C8A7CB",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                    "& .text_1": {
                      color: "#C8A7CB",
                      fontSize: {sm:"16px",xs:"14px"},
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .text_2": {
                      color: "#fff",
                      fontSize: {sm:"16px",xs:"14px"},
                      fontFamily: "AvenirLTStdBook",
                      textDecoration:"none"
                    },
                    "& .main_box": {
                      display: "flex",
                      gap: "10px",
                    },
                    "& .main_wrapper": {
                      p: "0.5rem",
                      transition: "0.3s all",
                      borderRadius: "5px",
                      "&:hover": {
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                      },
                    },
                  }}
                >
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Tel Support</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={fax} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Fax</Typography>
                        <Typography className="text_2">
                          +1.505.213.0351
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={email} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Sales & Product Inquiries
                        </Typography>
                        <Typography className="text_2" component={"a"} href="mailto:instruments@betterphysiology.com">
                          instruments@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={email} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Tech Support & Assistance
                        </Typography>
                        <Typography className="text_2" component={"a"} href="mailto:tech@betterphysiology.com">
                          tech@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Footer display="none" padding="0" />
          <CopyRight  />
        </Container>
      </Box>
    </>
  );
};

export default Contact;
