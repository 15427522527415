import { Box, Typography } from '@mui/material'
import React from 'react'

const CopyRight = ({padding}) => {
  return (
    <Box>
         <Typography
            sx={{
              color: "#782980",
              fontSize: "16px",
              fontFamily: "AvenirLTStdRoman",
              textAlign:"center",
              py:padding?padding:"1.5rem",
              px:padding?padding:"1rem"
              // fontWeight: "600",
              // letterSpacing: "2px",
            }}
          >
           Copyright © 2023-2024. All Rights Reserved.
          </Typography>
    </Box>
  )
}

export default CopyRight