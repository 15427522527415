import React from "react";
import Navbar from "../../Components/Navbar";
import { Box, Container, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import GoBackButton from "../../Components/GoBackButton";

const GoOverview = () => {
  return (
    <>
      <Navbar />
      <Box
        mt={"6rem"}
        sx={{
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
       
          "& .grid": {
            mt: { md: "-60px", xs: "0" },
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Typography className="title" pb={"1rem"} mt={"2rem"}>
            GO Overview
          </Typography>
          <Box
            sx={{
              background: "#C8A7CB",
              p: { sm: "3rem", xs: "1rem" },
              borderRadius: "10px",
              mt: "1rem",
              mb: "3rem",
            }}
          >
            <embed
              src="https://better-physiology-videos.s3.amazonaws.com/Professional-GO-Overview-1.pdf#toolbar=0"
              type="application/pdf"
              width="100%"
              height="700"
            ></embed>
          </Box>
        </Container>
        <Box py={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight/>
        </Container>
      </Box>
    </>
  );
};

export default GoOverview;
