import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import instrument from "../../Assets/Images/instrument.png";
import icon_1 from "../../Assets/Images/instrument_icon_1.svg";
import icon_2 from "../../Assets/Images/instrument_icon_2.svg";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component="img"
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const Instruments = () => {
    const goData=[
        {
          id:1,
          title:"5.0 SOFTWARE (REGULAR VERSION)",
          link:"https://better-physiology-videos.s3.amazonaws.com/Capno5_.exe",
        },
        {
          id:2,
          title:"5.0 SOFTWARE (GROUP VERSION)",
          link:"https://better-physiology-videos.s3.amazonaws.com/CapnoGroup.exe"
        },
        {
          id:3,
          title:"6.0 Software for 5.0 users",
          subtitle:"(no longer updated for 5.0 users)",
          link:"https://capno-software.s3.amazonaws.com/software_data/CapnoTrainer+Setup+2.2.2.exe"
        },
    
      ]

  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .title_": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
            fontWeight: "600",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={3} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6} xs={12}>
              <Typography className="title" pb="1rem">
              Download 5.0
              </Typography>
              <Typography className="title_" pb="1rem" sx={{width:{lg:"70%"}}}>
              CapnoTrainer 5.0 and earlier software is no longer being supported. You may download the last version here.
The last version only works on Windows PC.
              </Typography>
              <Box mt="1rem">
                {goData.map((data) => (
                  <Box
                    key={data.id}
                    sx={{
                    //   background: "#fff",
                      borderRadius: "10px",
                      p: "0.5rem 0rem",
                      width: { md: "90%" },
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "1rem",
                      mb: "0.7rem",
                    }}
                  >
                    <Typography className="subTitle">{data.title} {data.subtitle &&  <Typography className="subTitle" sx={{fontSize:"14px !important"}}>{data.subtitle}</Typography>}</Typography>
                  
                    <Button className={"btn"}
                      sx={{
                        fontSize: "16px",
                        p:"8px 20px !important",
                        textTransform: "capitalize",
                        fontFamily: "AvenirLTStdBook",
                      }}
                      component="a"
                      href={data.link}
                      target="_blank"
                    >
                      Download
                    </Button>
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  position: "relative",
                  background: "#C8A7CB",
                  borderRadius: "10px",
                  lineHeight: "0",
                  textAlign: "center",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                }}
              >
                <IconBox
                  src={icon_1}
                  sx={{
                    top: { md: "35%", xs: "0px" },
                    left: { md: "-50px", xs: "-20px" },
                  }}
                />
                <IconBox
                  src={icon_2}
                  sx={{
                    bottom: { md: "-30px", xs: "-10px" },
                    right: { md: "-30px", xs: "-10px" },
                  }}
                />
                <Typography
                  component="img"
                  src={instrument}
                  sx={{
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                    left: "-10px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>

        <Box py="4rem">
          <ProductNewsLetter text="You can reach us at:" />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight/>
        </Container>
      </Box>
    </>
  );
};

export default Instruments;
