import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Grow, Slide } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dummy from "../../Assets/Images/products/dummy.png"
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductModal({ setOpenModal, openModal,addToCartFunc,loadingKit }) {
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: "800px", // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background:"#F0EDF6"
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
        <Box
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton> */}
          <CancelOutlinedIcon   sx={{ position: "absolute", top: 2, right: 2,color:"#49194E",cursor:"pointer",fontSize:"1.8rem" }}
             onClick={() => setOpenModal(false)}/>
        </Box>
      <Box sx={{
        p:"1rem 2rem 2rem"
      }}>
      <Grid
          container
          spacing={3}
          sx={{
            "& .title": {
              fontFamily: "AvenirLTStdRoman",
              fontSize: "30px",
              lineHeight: "normal",
              fontWeight: "600",
              color: "#782980",
            },
            "& .btn_hover_box": {
              transition: "0.3s all",
              display: "flex",
              gap: "2px",
              pr: { lg: "8px" },
              "&:hover": {
                "& .hoverable": {
                  transition: "0.3s all",
                  transform: "translateX(8px)",
                },
              },
              alignSelf: "center",
            },
            "& .subTitle": {
              fontFamily: "AvenirLTStdRoman",
              fontSize: "14px",
              color: "#49194E",
            },
            "& .subTitle_small": {
              fontFamily: "AvenirLTStdRoman",
              fontSize: "14px",
              color: "#782980",
            },
          }}
        >
          <Grid item md={6} xs={12}>
            <Box>
                <Typography component={"img"} src={dummy} width={"100%"}/>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography className="title">Starter Kit</Typography>
            <Button
              className={"btn"}
              sx={{
                fontSize: "16px",
                textTransform: "capitalize",
                lineHeight: "normal",
                padding: "8px 20px !important",
                fontFamily: "AvenirLTStdBook",
              }}
            >
              $ 115
            </Button>
            <Typography className="subTitle_small" py={"1rem"}>
              Pack of 5 Water Traps, pack of 20 Sampling Lines, 1 Belt
            </Typography>
            <Typography className="subTitle" pb={"1rem"}>
              The Starter Kit should always be ordered with a new instrument. It
              consists one pack of Water Traps, 1 pack of 20 Sampling Lines, and
              a carry belt.
            </Typography>
            <Typography className="subTitle" pb={"2rem"}>
              It does not provide adequate supplies for users seeing many
              clients. We recommend four additional packs of 20 Sampling Lines
              (for a total of 100 Lines with the Starter Kit) and one additional
              pack of five water traps.
            </Typography>
            <Box className="btn_hover_box">
              <Button
                className={"btn"}
                sx={{
                  fontSize: "16px",
                  textTransform: "capitalize",
                  lineHeight: "normal",
                  padding: "6px 20px !important",
                  fontFamily: "AvenirLTStdBook",
                }}
                onClick={() => addToCartFunc(50, 1)}
              >
                {loadingKit ? "Processing..." :"Add to Cart"}
              </Button>
              <Button className={"btn hoverable"}>
                <ArrowForwardIcon
                  sx={{
                    fontSize: "1.3rem",
                  }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Dialog>
    </React.Fragment>
  );
}
