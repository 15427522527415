import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { useDispatch } from "react-redux";
import { getCart } from "../../store/cartSlice";
import ClearIcon from '@mui/icons-material/Clear';

const CartItem = ({ item, setMaxQnty, setShowSnackbar, setAddSuccessMsg }) => {
  const [productData, setProductData] = useState([]);
  const [count, setCount] = useState(0 || item?.quantity);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  let quantities =
    count > item?.quantity ? count - item?.quantity : -item?.quantity + count;
  const updateQuantity = async () => {
    try {
      const res = await axios.put(
        `${API_URL}/update/cart/by/${localStorage.getItem("user")}`,
        { cart_ids: [item?.id], quantities: [quantities] }
      );
      if (res.status === 200) {
        setShowSnackbar(true);
        setAddSuccessMsg(res.data.message);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      dispatch(getCart(localStorage.getItem("user")));
    } catch (err) {
      console.log("Error", err);
    }
  };

  const increment = () => {
    if (count < productData?.availability) {
      setCount(count + 1);
    } else {
      setMaxQnty(true);
      setTimeout(() => {
        setMaxQnty(false);
      }, 2000);
    }
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const getproductData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/get/product/by/${item?.product_id}`
      );
      if (res.status === 200) {
        setProductData(res.data.data?.[0]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const removeCart = async () => {
    setIsLoading(true);
    try {
      const res = await axios.delete(`${API_URL}/delete/cart/by/${item?.id}`);
      if (res.status === 200) {
        setIsLoading(false);
        setShowSnackbar(true);
        setAddSuccessMsg(res.data.message);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      dispatch(getCart(localStorage.getItem("user")));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (quantities !== 0) {
      updateQuantity();
    }
    // eslint-disable-next-line
  }, [quantities]);
  useEffect(() => {
    getproductData();
    // eslint-disable-next-line
  }, [item?.id]);

  function htmlToText(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="td" scope="row">
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Typography
              component={"img"}
              src={`${IMAGE_URL}/${productData?.product_images?.[0]}`}
              sx={{
                width: { sm: "116px", xs: "116px" },
                // height: { md: "175px", sm: "100%", xs: "100%" },
              }}
            />
            <Box>
              <Typography>
                {htmlToText(productData?.name)}
              </Typography>
              <Typography sx={{color:"#C8A7CB"}}>
                {productData?.subtitle ? htmlToText(productData?.subtitle) : ""}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="center">$ {item?.unit_price}</TableCell>
        <TableCell align="center">
          {" "}
          <Box display="flex" alignItems="center" justifyContent={"center"} gap={2}>
            {/* Decrement Button */}
            <Button
              variant="outlined"
              disableRipple
              onClick={decrement}
              disabled={count == 1} // Disable button when count is 1
              sx={{
                minWidth: "35px",
                height: "25px",
                padding: "0",
                fontSize: "1.5rem",
                pb: "0.2rem",
                color: "#49194E",
                borderColor: "#49194E",
                "&:hover": {
                  borderColor: "#49194E",
                },
                // lineHeight: "0rem",
              }}
            >
              -
            </Button>

            {/* Count Display */}
            <Typography>
              {count}
            </Typography>

            {/* Increment Button */}
            <Button
              disableRipple
              variant="outlined"
              onClick={increment}
              sx={{
                minWidth: "35px",
                height: "25px",
                padding: "0",
                fontSize: "1.5rem",
                color: "#49194E",
                borderColor: "#49194E",
                // lineHeight: "0rem",
                "&:hover": {
                  borderColor: "#49194E",
                },
              }}
            >
              +
            </Button>
          </Box>
        </TableCell>
        <TableCell align="center">$ {item?.total_price}</TableCell>
        <TableCell align="center">
        {isLoading ? (
              <CircularProgress
                sx={{
                  width: "16px !important",
                  height: "16px !important",
                  color: "#49194E",
                }}
              />
            ) :  <ClearIcon onClick={removeCart} sx={{cursor:"pointer",fontWeight:"600 !important",color:"#B2B2B2",fontSize:"2rem"}}/>}
       
     
        </TableCell>
      </TableRow>
    </>
  );
};

export default CartItem;
