import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import comma from "../../Assets/Images/comma.svg";
import comma_w from "../../Assets/Images/comma_w.svg";
import ellipse from "../../Assets/Images/ellipse.svg";
import story_1 from "../../Assets/Images/story_1.png";
import StoryModal from './StoryModal';

const StorySliderBox = () => {
    const [openModal,setOpenModal]=useState(false)
  return (
  <>
      <Box
    sx={{
      background: "#fff",
      cursor:"pointer",
      textAlign: "center",
      p: "2rem 1rem",
      width:{lg:"340px",sm:"300px",xs:"250px"},
      borderRadius:"5px",
      position:"relative",
      boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
      transition:"0.2s ease-in",
      "&:hover":{
        background: "#782980",
        "& p":{
          color:"#fff"
        },
        "& .vector_w":{
        display:"inline-block"
      },
      "& .vector":{
        display:"none"
      },
      },
      "& .vector_w":{
        display:"none"
      },
      "& .vector":{
        display:"inline-block"
      },
    }}
    onClick={()=>setOpenModal(true)}
  >

    <Typography component={"img"} src={ellipse} sx={{
        position:"absolute",
        bottom:"20px",
        right:"0"
    }}/>
    <Typography component={"img"} src={ellipse} sx={{
        position:"absolute",
        top:"20px",
        left:"0",
        transform:"rotate(-180deg)"
    }}/>
  <Box textAlign={"center"}>  <Typography className="vector" component={"img"} src={comma} /></Box>
    <Box textAlign={"center"}><Typography className="vector_w" component={"img"} src={comma_w} /> </Box>

    <Typography
      sx={{
        color: "#C8A7CB",
        fontSize: { sm: "16px", xs: "16px" },
        fontFamily: "AvenirLTStdRoman",
        //   fontWeight: "600",
        pb: "1.5rem",
      }}
    >
      CapnoTrainer is a fantastic tool for medical training. The
      realistic simulations have improved my skills and
      confidence in interpreting capnography waveforms.
    </Typography>
    <Typography
      component={"img"}
      src={story_1}
      width={"82px"}
    />
    <Typography
      sx={{
        color: "#782980",
        fontSize: { sm: "20px", xs: "20px" },
        fontFamily: "AvenirLTStdRoman",
        //   fontWeight: "600",
        // pt:"0.5rem"
      }}
    >
      Mark
    </Typography>
  </Box>
    <StoryModal openModal={openModal} setOpenModal={setOpenModal}/>
  </>
  )
}

export default StorySliderBox