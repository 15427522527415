import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const MaxQtyMessage = ({maxQnty}) => {
  return (
    <Snackbar
    open={maxQnty}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    sx={{
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    }}
  >
    <Alert
      severity={"success"}
      style={{
        backgroundColor: "red",
        color: "white",
        fontSize: "16px",
        fontFamily: "AvenirLTStdRoman",
        display: "flex",
        alignItems: "center",
        fontWeight: "600",
      }}
    >
      You have reached the maximum quantity limit.
    </Alert>
  </Snackbar>
  )
}

export default MaxQtyMessage