import { Box, Typography } from '@mui/material'
import React from 'react'

const TrackClient = ({data}) => {
  return (
    <Box
    sx={{
      width: {md:"290px",xs:"290px"},
      background:"#F0EDF6",
      textAlign:"center"
    }}
  >

    <Typography
      sx={{
        color: "#fff",
        background:"#8F4D96",
        fontSize: {sm:"50px",xs:"30px"},
        fontFamily: "AvenirLTStdRoman",
        fontWeight: "600",
        p:"1rem",
        borderRadius:"0 0 20px 20px"
        // letterSpacing: "2px",
      }}
    >{data.num}
    </Typography>
  <Box sx={{
     p: "20px 20px 5px 20px",
  }}>
  <Typography
      sx={{
        color: "#782980",
        fontSize: "16px",
        fontFamily: "AvenirLTStdRoman",
        fontWeight: "600",
       
        // letterSpacing: "2px",
      }}
    >
      {data.title}
    </Typography>
    <Typography
      sx={{
        width:"77px",
        m:"10px auto 0",
        background:"#782980",
        height:"2px"
      }}
   />
   </Box>
  </Box>
    

  

  )
}

export default TrackClient