import {
    Alert,
    Box,
    Button,
    Grid,
    Snackbar,
    TextField,
    TextareaAutosize,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import axios from "axios";
import { API_URL } from "../../redux/Constant";


  
  const GetInTouch = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const formik = useFormik({
      initialValues: {
        fname: "",
        // lname: "",
        email: "",
        // phone: "",
        message: "",
      },
      validationSchema: Yup.object({
        fname: Yup.string()
          .trim("Name should not contain spaces")
          .required("Required"),
        // lname: Yup.string()
        //   .trim("Last Name should not contain spaces")
        //   .required("Required"),
        email: Yup.string()
          .trim("Email should not contain spaces")
          .email("Invalid email")
          .required("Required"),
        message: Yup.string()
          .trim("Message should not contain spaces")
          .required("Required"),
        // phone: Yup.number().required("Required"),
      }),
      onSubmit: async (
        values,
        { setErrors, setSubmitting, resetForm, errors }
      ) => {
        console.log(values);
  
        if (errors) {
          setErrors(errors);
        }
        // createContact()
      },
    });
    const createContact = async () => {
      
      setErrors("")
      setLoading(true);
  
      try {
        const data = {
          fname: formik.values.fname,
        //   lname: formik.values.lname,
          email: formik.values.email,
        //   phone: formik.values.phone,
          message: formik.values.message,
        };
        const res = await axios.post(`${API_URL}/create/contact`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (res?.status === 201) {
          setLoading(false);
          setShowSnackbar(true);
          setTimeout(() => {
            setShowSnackbar(false);
          }, 2500);
          formik.resetForm();
        } else {
          setLoading(false);
        }
      } catch (err) {
       if(err?.response?.status===400){
         setErrors(err.response.data.error)
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2500);
       }
        setLoading(false);
      }
    };
    return (
  <>
  <Snackbar
      open={showSnackbar}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        "& .MuiSvgIcon-root": {
          color:errors?"red":"green",
        },
      }}
    >
      <Alert
        severity={"success"}
        style={{
          backgroundColor: "#EED37F",
          border:"1px solid",
          p:'10px',
          color: errors?"red":"green",
          fontSize: "15px",
          fontFamily: "AvenirLTStdBook",
          display: "flex",
          alignItems: "center",
          fontWeight: "600",
        }}
      >
        {errors?errors:"Thank you for contacting us"}
      </Alert>
    </Snackbar>
      <Box
 
        sx={{
          "& .bin1": {
            borderRadius: "5px",
            border: "1px solid #782980",
            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
            width: "100%",
            background: "#F0EDF6",
            color: "#fff",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&:focus-Visible": {
              outline: "none !important",
              border: "none !important",
            },
            "&:hover": {
              borderBottom: "1px solid #782980 !important",
            },
            "& .MuiInputBase-root": {
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            },
  
            "&:focus": {
              boxShadow: "none !important",
              border: "1px solid #782980 !important",
              borderRadius: "10px !important ",
            },
            "& input": {
                padding: "10px",
              width: "100%",
              color: "#000",
              fontSize: "15px",
              fontWeight: "600",
              fontFamily: "AvenirLTStdBook !important",
              "&:focus": {
                outline: "none",
              },
              "&::placeholder": {
                color: "#000 !important",
                fontFamily: "AvenirLTStdBook !important",
              },
            },
          },
          "& .bin_2": {
            background:"#F0EDF6",
            borderRadius: "5px",
            border: "1px solid #782980 !important",
            width: "100%",
            padding: "10px",
            height: "120px !important",
            fontSize: "16px",
            fontWeight: "600",
            color: "#000",
            fontFamily: "AvenirLTStdBook !important",
            "&::placeholder": {
              color: "rgb(0 0 0 / 34%) !important",
              fontFamily: "AvenirLTStdBook !important",
              fontSize: "15px",
            },
            "&:focus": {
              boxShadow: "none !important",
              outline: "none",
            },
          },
          "& .error": {
            marginTop: "0.4rem !important",
            fontSize: "13px !important",
            color: "red !important",
            fontFamily: "AvenirLTStdBook !important",
            textAlign: "start",
          },
          "& .btn": {
            background: "#C8A7CB !important",
            color: "#782980 !important",
            textTransform: "capitalize !important",
            fontFamily: "AvenirLTStdBook !important",
            padding: "0.6rem 2rem 0.5rem !important",
            fontSize: "16px !important",
            borderRadius: "5px !important",
            lineHeight: "initial !important",
            fontWeight: "700 !important",
            // border: "1px solid #EED37F !important",
            // width: "100%",
            "&:hover": {
              background: "#C8A7CB",
            },
            "&.Mui-disabled": {
              cursor: "not-allowed !important",
              pointerEvents: "auto !important",
              color: "rgb(255 255 255 / 38%) !important",
              "&:hover": {
                opacity: "1",
              },
            },
          },
        }}
      >
        <Grid item md={12} sm={12} xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="fname"
                    name="fname"
                    type="text"
                    className="bin1"
                    placeholder="Your Name"
                    variant="standard"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.fname && formik.errors.fname && (
                    <Typography variant="body1" className="error">
                      {formik.errors.fname}
                    </Typography>
                  )}
                </Grid>
                {/* <Grid item md={6} sm={12} xs={12} mb={"1.2rem"}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="lname"
                    name="lname"
                    type="text"
                    className="bin1"
                    placeholder="Enter Your Last Name"
                    variant="standard"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lname && formik.errors.lname && (
                    <Typography variant="body1" className="error">
                      {formik.errors.lname}
                    </Typography>
                  )}
                </Grid> */}
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="email"
                    name="email"
                    type="email"
                    className="bin1"
                    placeholder="Your Email"
                    variant="standard"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body1" className="error">
                      {formik.errors.email}
                    </Typography>
                  )}
                </Grid>
                {/* <Grid item md={6} sm={12} xs={12} mb={"1.2rem"}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="phone"
                    name="phone"
                    type="number"
                    className="bin1"
                    placeholder="Enter Your Phone No."
                    variant="standard"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <Typography variant="body1" className="error">
                      {formik.errors.phone}
                    </Typography>
                  )}
                </Grid> */}
                <Grid item md={12} sm={12} xs={12}>
                  {/* <Typography
                    sx={{
                      fontSize: { md: "16px", xs: "16px" },
                      color: "#fff",
                      fontWeight: "600",
                      fontFamily: "AvenirLTStdBook",
                    }}
                  >
                    Message
                  </Typography> */}
                  <TextareaAutosize
                    autoComplete="off"
                    fullWidth
                    id="message"
                    name="message"
                    type="text"
                    className="bin_2"
                    placeholder="Enter your message..."
                    variant="standard"
                    multiline={6}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <Typography variant="body1" className="error">
                      {formik.errors.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
  
              <Box mt={"1rem"}>
                <Button  type="submit" className="btn">
                  {loading?"Submitting...":"Submit"}
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Box>
  </>
    );
  };
  
  export default GetInTouch;
  