import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import training from "../../Assets/Images/training/interview.png";
import chat from "../../Assets/Images/training/interview_icon_1.svg";
import user from "../../Assets/Images/training/interview_icon_2.svg";
import phone from "../../Assets/Images/phone_dark.svg";
import gmail from "../../Assets/Images/gmail_dark.svg";
import VideoModal from "../GoVideo/VideoModal";
import InterviewList from "./InterviewList";
import GoBackButton from "../../Components/GoBackButton";

const IconBox = ({ src, sx }) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#C8A7CB",
      borderRadius: "50%",
      width: { md: "95px", xs: "50px" },
      height: { md: "95px", xs: "50px" },
      ...sx,
    }}
  >
    <Typography
      component={"img"}
      src={src}
      sx={{ width: { md: "auto", xs: "30px" } }}
    />
  </Box>
);

const Interviews = () => {
    const [videoLink, setVideoLink] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [interviewList, setInterviewList] = useState(false);
    const handleVideo = (link) => {
      setVideoLink(link);
      setOpenModal(true);
    };
    const url1 = `https://better-physiology-videos.s3.amazonaws.com/Interview-with-Sandy-Abrams-Nov-8-2021.mp4`;
    const url2 = `https://better-physiology-videos.s3.amazonaws.com/Denise-Campbell-2-cases.mp4`;
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={4} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6.7} xs={12} sx={{
                display:"flex",
                alignSelf:"center"
            }}>
           <Box>
           <Typography className="title" pb={"1rem"}>
                Interviews
              </Typography>
              <Box pb={"2rem"}>
                <Typography
                  className="subTitle"
                  sx={{ color: "#782980 !important", fontWeight: "600" }}
                >
                  Dr. Mercola interviews Dr. Litchfield.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mt: "0.5rem",
                    width: { lg: "80%" },
                  }}
                >
                  <Button
                    className={"btn"}
                    sx={{
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontFamily: "AvenirLTStdBook",
                      width: "112px",
                      minWidth: "112px",
                      background: "#C8A7CB !important",
                      color: "#000 !important",
                    }}
                    onClick={()=>setInterviewList(true)}
                  >
                    Click Here
                  </Button>
                  <Typography className="subTitle">
                    to choose and view short clips of Dr. Litchfield being
                    interviewed by Dr. Mercola.
                  </Typography>
                </Box>
              </Box>
              <Box pb={"2rem"}>
                <Typography
                  className="subTitle"
                  sx={{ color: "#782980 !important", fontWeight: "600" }}
                >
                  Sandy Abrams interviews Dr. Litchfield.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mt: "0.5rem",
                    width: { lg: "80%" },
                  }}
                >
                  <Button
                    className={"btn"}
                    sx={{
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontFamily: "AvenirLTStdBook",
                      width: "112px",
                      minWidth: "112px",
                      background: "#C8A7CB !important",
                      color: "#000 !important",
                    }}
                    onClick={() => handleVideo(url1)}
                  >
                    Click Here
                  </Button>
                  <Typography className="subTitle">
                    to see an interview with Peter Litchfield and Sandy Abrams
                    regarding breathing misconceptions, myths, and
                    pseudoscience.
                  </Typography>
                </Box>
              </Box>
              <Box pb={"2rem"}>
                <Typography
                  className="subTitle"
                  sx={{ color: "#782980 !important", fontWeight: "600" }}
                >
                  Dr. Litchfield interviews Denise Campbell.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mt: "0.5rem",
                    width: { lg: "80%" },
                  }}
                >
                  <Button
                    className={"btn"}
                    sx={{
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontFamily: "AvenirLTStdBook",
                      width: "112px",
                      minWidth: "112px",
                      background: "#C8A7CB !important",
                      color: "#000 !important",
                    }}
                    onClick={() => handleVideo(url2)}
                  >
                    Click Here
                  </Button>
                  <Typography className="subTitle">
                    to see an interview with Peter Litchfield and Denise
                    Campbell regarding a breathing behavior analysis of two
                    clients.
                  </Typography>
                </Box>
              </Box>
           </Box>
            </Grid>

            <Grid item md={5.3} xs={12}>
              <Box
                sx={{
                  lineHeight: "0",
                  position: "relative",
                //   background: "#C8A7CB",
                //   borderRadius: "10px",
                  mt: { md: "0", xs: "1.5rem" },
                  ml: { md: "0px", xs: "10px" },
                  mr: { md: "15px", xs: "0px" },
                  textAlign: "center",
                }}
              >
                {/* Icon Boxes */}
                <IconBox
                  src={chat}
                  sx={{
                    top: { md: "35%", xs: "35%" },
                    left: { md: "-35px", xs: "5px" },
                    transform:{md:"translateY(-35%)",xs:"translateY(-35%)"}
                  }}
                />
                <IconBox
                  src={user}
                  sx={{
                    bottom: { md: "-25px", xs: "-10px" },
                    right: { md: "15px", xs: "0" },
                  }}
                />

                {/* Demo Image */}
                <Typography
                  component={"img"}
                  src={training}
                  sx={{
                    width: "100%",
                    position: "relative",
                    // top: "-10px",
                    left: {md:"-50px"},
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          mt={"4.5rem"}
          mb={"2rem"}
          sx={{
            background: "#fff",
          }}
        >
          {/* <Container> */}
          <Grid container spacing={0}>
            <Grid
              item
              md={7}
              xs={12}
              sx={{
                display: "flex",
                alignSelf: "center",
                //   justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  p: { sm: "3.5rem", xs: "3.5rem 1.5rem" },
                  "@media (min-width:1600px)": {
                    pl: "12rem",
                  },
                }}
              >
                <Typography
                  className="title"
                  sx={{
                    //   lineHeight: { md: "50px !important" },
                    fontSize: { lg: "55px !important" },
                  }}
                >
                  Curious About What the <br /> Experts Say?
                </Typography>
                <Typography
                  className="subTitle"
                  sx={{ width: { lg: "80%" }, pt: "1rem" }}
                >
                  Have questions or want more details about our expert
                  interviews? We're here to help! Whether you're curious about
                  the topics discussed, looking for more insights, or need
                  assistance with any of the interviews, feel free to reach out.
                  Our team is happy to assist you with any queries you have.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box
                sx={{
                  background: "#fff",
                  boxShadow: "-2px 1px 5px rgba(0, 0, 0, 0.3)",
                  height: "100%",
                  // p:"1rem"
                }}
              >
                <Box
                  sx={{
                    // background: "#782980",
                    // borderRadius: "10px",
                    p: { sm: "2rem 3rem", xs: "2rem 1.5rem" },
                    display: "grid",
                    "& .img_box": {
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      // border: "1px solid #C8A7CB",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                    },
                    "& .text_1": {
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                      opacity: "0.7",
                    },
                    "& .text_2": {
                      color: "#49194E",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .main_box": {
                      display: "flex",
                      gap: "10px",
                      my: "1rem",
                      alignItems: "center",
                    },
                    "& a": {
                      textDecoration: "none",
                    },
                    "& .main_wrapper": {
                      // p: "0.5rem",
                      // transition: "0.3s all",
                      // borderRadius: "5px",
                      // "&:hover": {
                      //   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                      // },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#782980",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdRoman",
                      pb: { sm: "1rem", xs: "1rem" },
                      // letterSpacing: "2px",
                    }}
                  >
                    You can reach us at:
                  </Typography>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Tel Support</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Sales & Product Inquiries
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:instruments@betterphysiology.com"
                        >
                          instruments@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="main_wrapper">
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">
                          Tech Support & Assistance
                        </Typography>
                        <Typography
                          className="text_2"
                          component={"a"}
                          href="mailto:tech@betterphysiology.com"
                        >
                          tech@betterphysiology.com
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* </Container> */}
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
      <VideoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        videoLink={videoLink}
      />
      <InterviewList
        openModal={interviewList}
        setOpenModal={setInterviewList}
      />
    </>
  );
};

export default Interviews;
