import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { Box, Button, Grid, Grow, Slide, Typography } from "@mui/material";
import axios from "axios";
import { API_URL } from "../../redux/Constant";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/ProductSlice";
import { getCart } from "../../store/cartSlice";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const ReminderPopup = ({ reminder, setReminder, product_Id,addToCart,productQuantity }) => {
  const { data: result } = useSelector((state) => state.cart);
  const [loadingKit, setLoadingKit] = useState(false);

  const dispatch = useDispatch();

  const addToCartFunc = async (productId, quantity) => {
    setLoadingKit(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        setLoadingKit(false);
        const userId = res.data.user_id;
        dispatch(getCart(userId));
        setReminder(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        setLoadingKit(false);
      }
    }
  };
  const handleClose = () => {
    setReminder(false);
    addToCart(product_Id,productQuantity)
  };


  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={reminder}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: "300px", // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background:"#F0EDF6"
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
        
            <CancelOutlinedIcon   sx={{ position: "absolute", top: 2, right: 2,color:"#782980",cursor:"pointer",fontSize:"1.8rem" }}
            onClick={handleClose}/>
         
        </Box>
        <Box sx={{
        p:"1.2rem 1rem 1rem"
      }}>
          <Typography
            sx={{
            
              fontStyle: "italic",
            //   fontWeight: "600",
              fontSize: "16px",
              textAlign: "center",
              color:"#49194E"
            }}
          >
            <PanToolAltIcon
              sx={{
                transform: "rotate(90deg)",
                verticalAlign: "bottom",
                mr: "0.5rem",
                fontSize: "2rem",
                color: "#782980",
              }}
            />
            Do you want to operate your GO on a computer (Apple or PC)?  Clicking {" "}
            <a
              style={{
                textDecoration: !loadingKit && "underline",
                color: "#782980",
                fontSize: "16px",
                fontWeight: "600",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={() => addToCartFunc(product_Id==14?67:13, 1)}
              // href="#supply"
              title="Order a Starter Kit"
            >
              {loadingKit ? "Processing..." : "YES"}
            </a>{" "}
        {product_Id==14?"will add option C and option M to your order ($300.00)":"will add option C to your order ($300.00)"}
        .{" "}
        If you do not wish to add, click{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#782980",
                fontSize: "16px",
                fontWeight: "600",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={handleClose}
              title="No"
            >
              NO
            </span>
            .
          </Typography>
        </Box>
      </Dialog>
    </div>
  );
};

export default ReminderPopup;
