import { Badge, Box, Button, Collapse, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import cart from "../Assets/Images/cart.png";
// import user from "../Assets/Images/user.png";
// import Subscribe from "../Pages/Subscribe";
import logo from "../Assets/Images/new_logo.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const MobileNav = ({ handleDrawerToggle, cartValue }) => {

  const [submenus, setSubmenus] = React.useState({
    submenu: false,
    submenu1: false,
    submenu2: false,
    submenu3: false,
    submenu4: false,
  });

  const handleClickMenu = (menu) => {
    setSubmenus((prevSubmenus) => ({
      ...prevSubmenus,
      [menu]: !prevSubmenus[menu],
    }));
  };
  const { submenu, submenu1, submenu2, submenu3, submenu4 } = submenus;
  return (
    <Box sx={{
      "& .submenuList":{
        cursor:"none !important"
      }
    }}>
          {/* <Link to={"/"} style={{ lineHeight: "0" }}>
              {" "}
              <Typography component={"img"} src={logo} width={"180px"} />
            </Link>
      <IconButton
        aria-label="close"
        onClick={handleDrawerToggle("top", false)}
        title="Close"
        sx={{
          position: "absolute",
          right: "5px",
          color: "#782980",
          top: "5px",
          background: "#fff",
          "&:hover": {
            background: "#fff",
          },
     
        }}
      >
        <CloseIcon sx={{ fontSize: "1.1rem" }} />
      </IconButton> */}
      <Box
        // onClick={handleDrawerToggle}
        sx={{
          // textAlign: "center",
          display: "grid",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.1rem",
          mt: "5rem",
          "& a": {
            // color: "#782980",
            color: "#49194E",
            textDecoration: "none",
          },
          "& a:hover": {
            color: "#782980",
          },
          "& .btn": {
            background: "#782980 !important",
            color: "#fff !important",
            fontFamily: "AvenirLTStdRoman",
            fontSize: "0.85rem !important",
            lineHeight: "initial !important",
            padding: "10px 8px !important",
            "&:hover": {
              background: "#782980",
            },
            border: "2px solid #C5A7CF !important",
            borderRadius: "6px !important",
          },
          "& .submenuList": {
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            alignItems: "center",
            // background: "#782980",
            // color: "#782980",
            color: "#49194E",
            padding: "0.4rem",
            borderRadius: "4px",
            fontFamily: "AvenirLTStdRoman !important",
            "&:hover": {
              color: "#782980",
            },
          },
        }}
      >
        <Typography className={"submenuList"}>
          <Link to={"/"}>HOME</Link>
        </Typography>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu")}
            className={"submenuList"}
          >
            Company{" "}
            {submenu ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#49194E !important",
                  fontFamily: "AvenirLTStdRoman",
                },
              }}
            >
              <Typography>
                <Link to={"/contact"}>Contact</Link>
              </Typography>
              <Typography>
                <Link to={"/distributors"}>Distributors</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/history"}>History</Link>
              </Typography>
              <Typography>
                <Link to={"/mission"}>Mission</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu1")}
            className={"submenuList"}
          >
            Products{" "}
            {submenu1 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu1}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#49194E !important",
                  fontFamily: "AvenirLTStdRoman",
                },
              }}
            >
              <Typography>
                {" "}
                <Link to={"/go-brochure"}>GO Brochure</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/go-buy"}>GO Buy</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/request-demonstration"}>GO Demo</Link>
              </Typography>
              <Typography>
                {" "}
                <Link to={"/go-overview"}>GO Overview</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu2")}
            className={"submenuList"}
          >
            Educations{" "}
            {submenu2 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu2}>
            <Box
              sx={{
                borderRadius: "4px",
                background: "#fcfcfc6b",
                p: "0.5rem",
                "& a": {
                  color: "#49194E !important",
                  fontFamily: "AvenirLTStdRoman",
                },
              }}
            >
              <Typography>
                <Link to={"/book"}>Book</Link>
              </Typography>
              <Typography>
                <Link to={"/course"}>Course</Link>
              </Typography>
              <Typography>
                <Link to={"/interview"}>Interviews</Link>
              </Typography>
              <Typography>
                <Link to={"/lectures"}>Lectures</Link>
              </Typography>
              <Typography>
                <Link to={"/references"}>References</Link>
              </Typography>
              <Typography>
                <Link to={"/professional-education"}>Professional School</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu3")}
            className={"submenuList"}
          >
            Services{" "}
            {submenu3 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu3}>
            <Box
              sx={{
                borderRadius: "4px",
                background: "#fcfcfc6b",
                p: "0.5rem",
                "& a": {
                  color: "#49194E !important",
                  fontFamily: "AvenirLTStdRoman",
                },
              }}
            >
              <Typography>
                <Link to={"/consultation"}>Consultation</Link>
              </Typography>
              <Typography>
                <Link to={"/go-service"}>GO Services</Link>
              </Typography>
              <Typography>
                <Link to={"/go-video"}>GO Videos</Link>
              </Typography>
              <Typography>
                <Link to={"/training"}>Training</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>
        <Box>
          <Typography
            onClick={() => handleClickMenu("submenu4")}
            className={"submenuList"}
          >
            Software{" "}
            {submenu4 ? (
              <ExpandLess sx={{ pb: "3px", cursor: "pointer" }} />
            ) : (
              <ExpandMore sx={{ pb: "3px", cursor: "pointer" }} />
            )}
          </Typography>
          <Collapse in={submenu4}>
            <Box
              sx={{
                background: "#fcfcfc6b",
                p: "0.5rem",
                borderRadius: "4px",
                "& a": {
                  color: "#49194E !important",
                  fontFamily: "AvenirLTStdRoman",
                },
              }}
            >
              <Typography>
                <Link to={"/go-download"}>GO Downloads</Link>
              </Typography>
              <Typography>
                <Link to={"/go-manual"}>GO Manuals</Link>
              </Typography>
              <Typography>
                <Link to={"/go-quickstart"}>GO Quickstart</Link>
              </Typography>
              <Typography>
                <Link to={"/download-5.0"}>5.0 Instruments</Link>
              </Typography>
            </Box>
          </Collapse>
        </Box>

        <Typography
          className={"submenuList"}
          // onClick={() => setSubscribeModal(true)}
        >
          <a href={"/subscribe"}>Subscribe</a>
        </Typography>
        <Typography className={"submenuList"}>
          <Link to={"/faq"}>FAQs</Link>
        </Typography>
        <Box
          mt={"0.5rem"}
          sx={{
            "& .MuiBadge-root": {
              display: "block !important",
            },
          }}
        >
          <Badge
            badgeContent={cartValue}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: "#E4D5F4",
                color: "#000000",
                fontSize: "15px",
                fontFamily: "AvenirLTStdRoman",
                height: "23px",
                width: "23px",
                right: "10px",
                border: "1px solid #782980",
                borderRadius: "100%",
              },
            }}
          >
            <Button
            disableRipple
              className={"btn"}
              component={Link}
              sx={{ width: "100%", p: "0.6rem" }}
              to="/cart"
            >
              Cart{" "}
              <Typography
                ml={"0.5rem"}
                component={"img"}
                width={"16px"}
                src={cart}
              />
            </Button>
          </Badge>
        </Box>
        <Box mt={"0.5rem"}>
          <Button
          disableRipple
            className={"btn"}
            sx={{ width: "100%", p: "0.6rem" }}
            component={Link}
            to="/go-buy"
          >
            Buy Now
          </Button>
        </Box>

        {/* ***subscribe modal**** */}
        {/* <Subscribe
          subscribeModal={subscribeModal}
          setSubscribeModal={setSubscribeModal}
        /> */}
      </Box>
    </Box>
  );
};

export default MobileNav;
