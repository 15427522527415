import { Alert, Snackbar } from '@mui/material'
import React from 'react'

const SuccesAlert = ({showSnackbar,addSuccessMsg,addErrorMsg}) => {
  return (
    <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "middle", horizontal: "right" }}
        sx={{
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
        }}
      >
        <Alert
          severity={"success"}
          style={{
            backgroundColor:addErrorMsg?"red": "#782980",
            color: "white",
            fontSize: "16px",
            fontFamily: "AvenirLTStdRoman",
            display: "flex",
            alignItems: "center",
            fontWeight: "600",
          }}
        >
        {addSuccessMsg ? addSuccessMsg:addErrorMsg}
        </Alert>
      </Snackbar>
  )
}

export default SuccesAlert