import { Box, Button, Container, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'
import phone from "../Assets/Images/phone_dark.svg"
import gmail from "../Assets/Images/gmail_dark.svg"

const NewsLetter = () => {
  return (
    <Box
    sx={{
     background:"#fff",
      p: "4rem 1rem",
      }}
  >
 <Container>
 <Grid container spacing={4}>
    <Grid item md={4} xs={12}>
    <Box>
         <Typography
            sx={{
              color: "#782980",
              fontSize: "29px",
              fontFamily: "AvenirLTStdRoman",
              fontWeight: "600",
              // letterSpacing: "2px",
            }}
          >
            Be Part of Our Inner Circle!
          </Typography>
          <Typography
            sx={{
              color: "#49194E",
              fontSize: "16px",
              fontFamily: "AvenirLTStdRoman",
              opacity:"50%",
          pb:{sm:"2rem",xs:"1rem"},
              // letterSpacing: "2px",
            }}
          >
           Get our latest offers and news straight in your inbox.
          </Typography>
      <Box>
        <TextField
          autoComplete="off"
          fullWidth
          variant="standard"
          type="text"
          placeholder="Enter your email here..."
          id="fname"
          name="fname"
          sx={{
            borderRadius: "5px",
            boxShadow: "none",
            border: "1px solid transparent",
            // width: { sm: "400px", xs: "100%" },
            background: "#E6E4E9",
            color: "#782980",
            "& input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "&:focus-Visible": {
              outline: "none !important",
              border: "1px solid transparent",
            },
            "&:hover": {
              border: "1px solid transparent",
            },
            "& .MuiInputBase-root": {
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            },

            "&:focus": {
              boxShadow: "none !important",
              border: "1px solid #782980",
              borderRadius: "10px !important ",
            },
            "& input": {
              padding: "10px",
              width: "100%",
              color: "#782980",
              fontSize:"18px",
              fontFamily:"AvenirLTStdBook",
              "&:focus": {
                outline: "none",
              },
              "&::placeholder": {
                color: "#000",
                fontSize:"16px",
                //   color: "#494949",
              },
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  sx={{
                    background: "#782980 !important",
                    color: "#fff !important",
                    fontFamily: "AvenirLTStdBook",
                    padding: "9px 12px !important",
                    fontSize: "16px",
                    // lineHeight: "initial",
                    "&:hover": {
                      background: "#782980",
                    },
                    borderRadius: "0 5px 5px 0 !important",
                    textTransform: "capitalize",
                  }}
                >
                  Subscribe
                </Button>
              </InputAdornment>
            ),
          }}
        />
     
      </Box>
    </Box>
    </Grid>
    <Grid item md={8} xs={12} sx={{
         "& .text_1": {
                      color: "#782980",
                      fontSize: "16px",
                      opacity:"70%",
                      fontWeight:"600",
                      fontFamily: "AvenirLTStdBook",
                    },
                    "& .text_2,a": {
                      color: "#49194E",
                      fontSize: "16px",
                      fontFamily: "AvenirLTStdBook",
                      textDecoration:"none"
                    },
                    "& .main_box": {
                      display: "flex",
                      gap: "10px",
                    },
                    
    }}>
    <Box sx={{
        display:"flex",
        justifyContent:{sm:"space-evenly",xs:"start"},
        gap:"2rem",
        height:"100%",
        alignItems:"center",
        flexWrap:"wrap"
    }}>
    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={phone} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Tel Support</Typography>
                        <Typography className="text_2">
                          +1.800.850.9795
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="main_box">
                      <Typography className="img_box">
                        <Typography component={"img"} src={gmail} />
                      </Typography>
                      <Box>
                        <Typography className="text_1">Write to us</Typography>
                        <Typography className="text_2">
                          <a href='mailto:instruments@betterphysiology.com'>instruments@betterphysiology.com</a>
                        </Typography>
                      </Box>
                    </Box>
    </Box>
    </Grid>
 </Grid>
 </Container>
  </Box>
  )
}

export default NewsLetter