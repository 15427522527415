import React from 'react';
import { Box, Grid, Typography, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';


const CommonSection = ({title,img_1,img_2,img_3,btn_1,btn_2,btn_3,link_1,link_2,link_3}) => {
  return (
    <Box
      sx={{
        background: '#782980',
        p: '3.5rem 0',
        '& .service_title': {
          fontFamily: 'AvenirLTStdRoman',
          fontSize: { lg: '55px', xs: '35px' },
          lineHeight: 'normal',
          fontWeight: '600',
          color: '#fff !important',
          textAlign:{sm:"start",xs:"center"}
        },
        '& .img_box': {
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '15px',
          lineHeight:"0",
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'transform 0.3s ease-in-out',
          '& img': {
            height: '100%',
            width: '100%',
            transition: 'transform 0.3s ease-in-out',
            transform: 'scale(1.12)'
          },
          '&:hover img': {
            transform: 'scale(1)', 
          },
        },
        '& .sevices_btn': {
          fontSize: '20px',
          textTransform: 'capitalize !important',
          fontFamily: 'AvenirLTStdBook !important',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: '0.3s all',
          color: '#fff',
          border: '1px solid #fff',
          lineHeight: 'normal',
          fontWeight: '600',
          borderRadius: '8px',
          p: '8px 25px',
          '&:hover': {
            background: '#782980',
            border: '1px solid transparent',
          },
        },
      }}
    >
      <Container>
        <Grid container spacing={4}>
          {/* Left Side */}
          <Grid
            item
            md={4}
            sm={3}
            xs={12}
            sx={{
              display: {sm:'flex'},
              alignItems: 'center',
            }}
          >
            <Typography className="service_title">
             {title}
            </Typography>
          </Grid>

          {/* Right Side */}
          <Grid item md={8} sm={9} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6} xs={12} sx={{
                display:"flex",
                alignSelf:"center"
              }}>
               
               
                <Box className="img_box" sx={{ mt: 3 }}>
                  <img src={img_1} alt="videos" />
                  <Button disableRipple className="sevices_btn"  component={Link} to={link_1}>
                  {btn_1}
                  </Button>
                </Box>
               
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
              
                <Box className="img_box">
                  <img src={img_2} alt="goDemo" />
                  <Button disableRipple className="sevices_btn" component={Link} to={link_2}>
                  {btn_2}
                  </Button>
                </Box>
                <Box className="img_box" sx={{ mt: 3 }}>
                  <img src={img_3} alt="overview" />
                  <Button disableRipple className="sevices_btn" component={Link} to={link_3}>
                  {btn_3}
                  </Button>
                </Box>
           
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CommonSection;
