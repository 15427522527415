import React from 'react'
import { Box, Typography } from '@mui/material';


const Clients = ({data}) => {
  return (
    <a href='/'
    p={"1rem"}
  
  >
    <Typography component={"img"} src={data?.image} width="100%"  sx={{
        transition:"0.2s all",
       "&:hover":{
         transform:"translateY(-10px)"
       }
    }} />
    </a>
  )
}

export default Clients