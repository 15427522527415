import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CommonSection from "../../Components/CommonSection";
import support from "../../Assets/Images/go-brochure/support.png";
import consultation from "../../Assets/Images/go-brochure/consultation.png";
import training from "../../Assets/Images/go-brochure/training.png";
import VideoModal from "./VideoModal";
import { videoData } from "./VideoData";
import GoBackButton from "../../Components/GoBackButton";

const GoVideo = () => {
  const [videoLink, setVideoLink] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleVideo = (link) => {
    setVideoLink(link);
    setOpenModal(true);
  };
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { sm: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
            textDecoration: "none",
          },
        }}
      >
        <Container
          sx={{
            "& a": {
              display: "block",
              transition: "0.2s all",
              "&:hover": {
                transform: "scale(1.015)",
              },
            },
          }}
        >
        <GoBackButton/>
          <Box pb={"2rem"} mt={"2rem"}>
            <Typography className="title" pb={"0.5rem"}>
              GO Videos
            </Typography>

            <Typography className="subTitle">
              Watch our videos by clicking on the names below.
            </Typography>
          </Box>
          <Grid container spacing={3} mb={"3rem"} mt={0}>
            <Grid item md={4} sm={12} xs={12}>
              {videoData.map(
                (data, index) =>
                  index === 2 && (
                    <Box
                      sx={{
                        borderRadius: "10px",
                        p: { sm: "1.5rem", xs: "1.5rem" },
                        background: "#fff",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                      }}
                      key={index}
                    >
                      {console.log(index, "value of index", data, "data value")}

                      <Typography className="title">{data.title}</Typography>
                      <Typography component={"ul"} pt={"0.7rem"}>
                        {data.lists.map((e) => (
                          <Typography component={"li"} pb={"0.5rem"}>
                            <Typography
                              className="subTitle"
                              component={"a"}
                              //   fontStyle={"italic"}
                              href="#"
                              target="_parent"
                              onClick={() => handleVideo(e.link)}
                            >
                              {e.text}
                            </Typography>
                          </Typography>
                        ))}
                      </Typography>
                    </Box>
                  )
              )}
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Grid container spacing={3}>
                {videoData.map(
                  (data, index) =>
                    index < 2 && (
                      <Grid item md={6} sm={12} xs={12}>
                        <Box
                          sx={{
                            borderRadius: "10px",
                            p: { sm: "1.5rem", xs: "1.5rem" },
                            background: "#fff",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                          }}
                          key={index}
                        >
                          <Typography className="title">
                            {data.title}
                          </Typography>

                          <Typography component={"ul"} pt={"0.7rem"}>
                            {data.lists.map((e) => (
                              <Typography component={"li"} pb={"0.5rem"}>
                                <Typography
                                  className="subTitle"
                                  //   fontStyle={"italic"}
                                  component={"a"}
                                  href="#"
                                  target="_parent"
                                  onClick={() => handleVideo(e.link)}
                                >
                                  {e.text}
                                </Typography>
                              </Typography>
                            ))}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box pt={"2rem"}>
          <CommonSection
            title={
              <>
                Explore
                <br /> Our <br /> Other <br />
                Services
              </>
            }
            btn_1={"Go Support"}
            btn_2={"Consultation"}
            btn_3={"Training"}
            img_1={support}
            img_2={consultation}
            img_3={training}
            link_1={"/go-support"}
            link_2={"/consultation"}
            link_3={"/training"}
          />
        </Box>
        <Box pb={"2rem"}>
          <ProductNewsLetter
            text={
              "Have questions or need further clarification? you can reach us at:"
            }
          />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
      <VideoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        videoLink={videoLink}
      />
    </>
  );
};

export default GoVideo;
