import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import ProductNewsLetter from "../../Components/ProductNewsLetter";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductsById } from "../../store/ProductByIdSlice";
import { IMAGE_URL } from "../../redux/Constant";
import ImageGallery from "react-image-gallery";
import GoProfessionalInfo from "./CapnoTrainerDetailInfo/GoProfessionalInfo";
import GoBasicInfo from "./CapnoTrainerDetailInfo/GoBasicInfo";
import GoPersonal from "./CapnoTrainerDetailInfo/GoPersonal";
import GoPersonalC from "./CapnoTrainerDetailInfo/GoPersonalC";
import GoPersonalM from "./CapnoTrainerDetailInfo/GoPersonalM";
import Loader from "../../Components/Loader";
import GoBackButton from "../../Components/GoBackButton";

const ProductDetail = () => {
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getProductsById(id));
  }, [dispatch, id]);
  const { data: result } = useSelector((state) => state.productById);
  const [productData] = result.data || [];
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (productData?.category) {
      const _image = productData?.product_images.map((v, i) => {
        return {
          original: `${IMAGE_URL}/${v}`,
          thumbnail: `${IMAGE_URL}/${v}`,
          originalClass: "custom-image",
          thumbnailClass: "custom-thumbnail",
        };
      });
      setImages(_image);
      // relatedProductData();
    }
    // eslint-disable-next-line
  }, [productData]);
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "20px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },

          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
          "& .subTitle_small": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
          },
          "& .btn_hover_box": {
            transition: "0.3s all",
            display: "flex",
            gap: "2px",
            pr: { lg: "8px" },
            "&:hover": {
              "& .hoverable": {
                transition: "0.3s all",
                transform: "translateX(8px)",
              },
            },
            alignSelf: "center",
          },
        }}
      >
        <Container>
          <GoBackButton/>

       {productData?
          <Box className="go_details" pt={"2rem"} pb={"4rem"}>
            <Box
              sx={{
                float: "left",
                width: { md: "50%", xs: "100%" },
                paddingRight: { md: "3rem" },
                paddingBottom: { md: "1.5rem", xs: "1.7rem" },
              }}
            >
              <Typography className="subTitle" pb={"0.2rem"}>
                Actual size: 9.7 X 7.6 X 3.2 cm{" "}
              </Typography>
              <ImageGallery
                items={images}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                disableSwipe={true}
                disableThumbnailSwipe={true}
                slideDuration={0}
              />
            </Box>

            <Box
              py={"1rem"}
              sx={{ pt: { md: productData?.id === 10 ? "5rem" : "1.5rem" } }}
            >
              <Typography className="title" pb={"0.5rem"}>
                {productData?.name}
              </Typography>
              <Button
                className={"btn"}
                sx={{
                  fontSize: "16px",
                  textTransform: "capitalize",
                  lineHeight: "normal",
                  padding: "8px 20px !important",
                  fontFamily: "AvenirLTStdBook",
                }}
              >
                $ {productData?.price}
              </Button>
            </Box>
            {productData?.id === 10 && <GoProfessionalInfo />}
            {productData?.id === 67 && <GoBasicInfo />}
            {productData?.id === 12 && <GoPersonal />}
            {productData?.id === 13 && <GoPersonalC />}
            {productData?.id === 14 && <GoPersonalM />}
            {/* <Typography
          component={"span"}
          dangerouslySetInnerHTML={{
                          __html:description,
                        }}
          sx={{
            "& p":{
          wordBreak:"break-word",
          fontSize: "20px",
          textAlign: "justify",
          fontFamily: "ABLE !important",
        }    
          }}
        />    */}
          </Box>
          :
          <Box
              sx={{
                height: "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </Box>
          }
        </Container>
        <Box py={"2rem"}>
          <ProductNewsLetter text={"You can reach us at:"} />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight/>
        </Container>
      </Box>
    </>
  );
};

export default ProductDetail;
