import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import forest_bg from "../../Assets/Images/forest_bg.png";
import forest_bg_2 from "../../Assets/Images/forest_bg_2.png";
import forest_bg_3 from "../../Assets/Images/forest_bg_3.png";
import forest_bg_4 from "../../Assets/Images/forest_bg_4.png";
import line_5 from "../../Assets/Images/line_5.svg";
import line_4 from "../../Assets/Images/line_4.svg";

const bgImages = [forest_bg, forest_bg_2, forest_bg_4, forest_bg_3];

const CapnoDevice = () => {
  const [currentBg, setCurrentBg] = useState(forest_bg);
  const [zoom, setZoom] = useState(true); // State to control zoom

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      // Zoom out quickly, then change image
      setZoom(false);

      setTimeout(() => {
        currentIndex = (currentIndex + 1) % bgImages.length;
        setCurrentBg(bgImages[currentIndex]);

        // After changing the background, zoom in slowly
        setZoom(true);
      }, 1200); // Fast zoom out for 0.5 seconds, then change image
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      lineHeight={"0"}
      position={"relative"}
      sx={{
        height: { lg: "650px" },
        "@media (min-width:1400px)": {
          height: "720px",
        },
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${currentBg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          transition: !zoom
            ? "transform 2s cubic-bezier(0.4, 0.0, 0.2, 1)" // Slow zoom-in
            : "transform 0.5s ease-out", // Fast zoom-out
          transform: !zoom ? "scale(3)" : "scale(1)", // Zoom effect
          zIndex: -1, // Place it behind the content
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={5} xs={12}></Grid>
        <Grid item lg={7} xs={12}>
          <Box
            sx={{
              p: {sm:"2rem",xs:"0.5rem"},
            }}
          >
            <Box
              sx={{
                "@media (min-width:1400px)": {
                  width: "80%",
                },
                width: { lg: "100%" },
                background: "rgb(255, 255, 255, 0.3)",
                backdropFilter: "blur(15px)",
                p: "1.5rem",
                borderRadius: "3px",
              }}
            >
              <Typography
                sx={{
                  color: "#782980",
                  fontSize: "30px",
                  letterSpacing: "2px",
                  fontFamily: "AvenirLTStdRoman",
                }}
              >
                Meet our instrument
              </Typography>
              <Typography
                sx={{
                  my: { sm: "-20px", xs: "-8px" },
                  color: "#782980",
                  fontSize: { sm: "70px", xs: "40px" },
                  fontFamily: "AvenirLTStdBlack",
                  letterSpacing: { sm: "3.5px" },
                }}
              >
                CapnoTrainer GO
              </Typography>
              <Typography
                sx={{
                  pt: "15px",
                  color: "#49194E",
                  fontSize: "21px",
                  letterSpacing: "2px",
                  fontFamily: "AvenirLTStdRoman",
                }}
              >
                The instrument is a combination hardware and software system
                that provides users with training, learning and management tools for
                personalized breathing education based on the principles of
                behavioral analysis through the presentation of both live and
                recorded physiology. Learn about the three versions of the 
                CapnoTrainer® GO: Personal, Basic, and Professional.
              </Typography>

              <Box textAlign={"end"} pt={"10px"}>
                <Box pb={"5px"}>
                  <Typography component={"img"} src={line_4} />
                </Box>
                <Box>
                  <Typography component={"img"} src={line_5} />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "2px",
                pt: "1rem",
                "&:hover": {
                  "& .hoverable": {
                    transform: "translateX(10px)",
                  },
                },
              }}
            >
              <Button
                className={"btn"}
                  component={Link}
                to="/go-brochure"
                sx={{
                  fontSize: "18px",
                  textTransform: "capitalize",
                }}
              >
                Learn more about CapnoTrainer
              </Button>
              <Button
                className={"btn hoverable"}
                sx={{
                  fontSize: "15px",
                  transition: "0.2s",
                }}
                component={Link}
                to="/go-brochure"
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CapnoDevice;
