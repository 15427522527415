import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../Assets/Images/new_logo_.png";
import menu_logo from "../Assets/Images/menu_logo.png";
import cart from "../Assets/Images/cart.png";
// import user from "../Assets/Images/user.png";
import { Link, NavLink } from "react-router-dom";
import { Container, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MobileNav from "./MobileNav";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../store/cartSlice";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from "@mui/icons-material/Close";



const drawerWidth = 300;

function Navbar(props) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#782980" : "#782980",
      // padding: '0rem 0.8rem',
      borderRadius: "8px",
      display: "flex",
      transition: "0.5s",
      fontFamily: "AvenirLTStdRoman",
      textDecoration: "none",
      alignItems: "center",
      // fontWeight: isActive ? '700' : '500',
    };
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getCart(localStorage.getItem("user")));
  }, [dispatch]);

  const { data: result } = useSelector((state) => state.cart);
  const drawer = (
    <MobileNav
      handleDrawerToggle={toggleDrawer}
      cartValue={result?.data?.length}
    />
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          // position: "inherit",
          backgroundColor: "transparent",
          zIndex: 2000,
          // width: "100%",
          backdropFilter: "blur(15px)",
          background: "rgb(240 237 246 / 20%) !important",
          position: "fixed",
          top: 0,
          boxShadow: "none",
          py: "1px",
          pr: "0 !important",
          //   borderBottom: { md: "none", lg: "1px solid #782980" },
        }}
      >
        <Container>
          <Toolbar
            sx={{
              justifyContent: "space-between",
              p: "0 !important",
              position: { lg: "relative" },
            }}
          >
            <Link to={"/"} style={{ lineHeight: "0" }}>
              {" "}
              <Typography component={"img"} src={logo} width={"186px"} />
            </Link>
            <IconButton
            disableRipple
              color="inherit"
              aria-label="open drawer"
              edge="start"
              // onClick={handleDrawerToggle}
              onClick={ state?.top ?toggleDrawer("top", false):toggleDrawer("top", true)}
              sx={{ display: { lg: "none", color: "#782980" }, p: "0",animation:"0.8s all" }}
            >
             {
              state?.top ?  <CloseIcon sx={{animation:"0.8s all"}} />:
              <MenuIcon sx={{animation:"0.8s all"}}/>
             }
            </IconButton>

            <Box
              sx={{
                display: { md: "none", lg: "flex", sm: "none", xs: "none" },
                gap: "2rem",
                background: "rgb(255 255 255 /30%)",
                p: "20px 40px",
                borderRadius: "50px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                ml: "-80px",
              }}
            >
              <NavLink style={navLinkStyles} to={"/"}>
                <Typography
                  sx={{
                    borderRadius: "8px",
                    cursor: "pointer",
                    "@media (min-width:1500px)": {
                      fontSize: "18px !important",
                    },
                    "@media (max-width:1499px)": {
                      fontSize: "18px !important",
                    },
                    fontFamily: "AvenirLTStdRoman",
                    color: "#49194E",
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: "2px",
                      backgroundColor: "#49194E",
                      transition: "width 0.3s ease",
                    },
                    "&:hover::after": {
                      width: "100%",
                    },
                  }}
                >
                  Home
                </Typography>
              </NavLink>
              <Box
                sx={{
                  // position:"relative",
                  "&:hover .menu_1": {
                    display: "block",
                  },
                }}
              >
                <Typography
                  className={"submenuList"}
                  // {...bindHover(popupState)}
                >
                  Company
                </Typography>
                <Box
                  className="menu_1"
                  sx={{
                    pt: "1.8rem",
                    display: "none",
                    position: "absolute",
                    left: "50.5%",
                    transform: "translateX(-50.5%)",
                    width: "44%",
                  }}
                >
                  <Box
                    sx={{
                      background: "rgb(243 243 243 / 98%)",
                      p: "45px 30px",
                      borderRadius: "30px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                      backdropFilter: "blur(200px)",
                    }}
                  >
                    <Typography className="submenu_title">
                      About & Beyond
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.5rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/contact"}>
                        Contact
                      </Link>
                      <Link className={"menuLink_hover"} to={"/distributors"}>
                        Distributors
                      </Link>
                      <Link className={"menuLink_hover"} to={"/history"}>
                        History
                      </Link>
                      <Link className={"menuLink_hover"} to={"/mission"}>
                        Mission
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // position:"relative",
                  "&:hover .menu_2": {
                    display: "block",
                  },
                }}
              >
                <Typography className={"submenuList"}>Products</Typography>
                <Box
                  className="menu_2"
                  sx={{
                    pt: "1.8rem",
                    display: "none",
                    position: "absolute",
                    left: "50.5%",
                    transform: "translateX(-50.5%)",
                    width: "44%",
                  }}
                >
                  <Box
                    sx={{
                      background: "rgb(243 243 243 / 98%)",
                      p: "45px 30px",
                      borderRadius: "30px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                      backdropFilter: "blur(200px)",
                    }}
                  >
                    <Typography className="submenu_title">
                      Product Showcase
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.5rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/go-brochure"}>
                        GO Brochure
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-buy"}>
                        GO Buy
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-demo"}>
                        GO Demo
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-overview"}>
                        GO Overview
                      </Link>
                    </Box>
                    <Typography className="submenu_title" pt={"1.5rem"}>
                      Software Suite
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.5rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/go-download"}>
                        GO Downloads
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-manual"}>
                        GO Manuals
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-quickstart"}>
                        GO Quickstart
                      </Link>
                      <Link className={"menuLink_hover"} to={"/download-5.0"}>
                        5.0 Instruments
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  // position:"relative",
                  "&:hover .menu_4": {
                    display: "block",
                  },
                }}
              >
                <Typography className={"submenuList"}>Services</Typography>
                <Box
                  className="menu_4"
                  sx={{
                    pt: "1.8rem",
                    display: "none",
                    position: "absolute",
                    left: "50.5%",
                    transform: "translateX(-50.5%)",
                    width: "44%",
                  }}
                >
                  <Box
                    sx={{
                      background: "rgb(243 243 243 / 98%)",
                      p: "45px 30px",
                      borderRadius: "30px",
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                      backdropFilter: "blur(200px)",
                    }}
                  >
                    <Typography className="submenu_title">
                      Service Offerings
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.5rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/consultation"}>
                        Consultation
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-support"}>
                        GO Support
                      </Link>
                      <Link className={"menuLink_hover"} to={"/go-video"}>
                        GO Videos
                      </Link>
                      <Link className={"menuLink_hover"} to={"/training"}>
                        Training
                      </Link>
                    </Box>
                    <Typography className="submenu_title" pt={"1.5rem"}>
                      Education Hub
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.5rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/book"}>
                        Book
                      </Link>
                      <Link className={"menuLink_hover"} to={"/course"}>
                        Course
                      </Link>
                      <Link className={"menuLink_hover"} to={"/interview"}>
                        Interviews
                      </Link>
                      <Link className={"menuLink_hover"} to={"/lectures"}>
                        Lectures
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "2rem",
                        flexWrap: "wrap",
                        pt: "0.8rem",
                      }}
                    >
                      <Link className={"menuLink_hover"} to={"/references"}>
                        References
                      </Link>
                      <a
                        className={"menuLink_hover"}
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://e-campus-registration.bp.edu/special/capnolearning?showbrochure=true"
                        }
                      >
                        CapnoLearning
                      </a>
                      <Link
                        className={"menuLink_hover"}
                        to={"/professional-education"}
                      >
                        Professional School
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <NavLink style={navLinkStyles} to={"/subscribe"}> */}
              <Typography
                sx={{
                  color: "#49194E",
                  borderRadius: "8px",
                  cursor: "pointer",
                  "@media (min-width:1500px)": {
                    fontSize: "18px !important",
                  },
                  "@media (max-width:1499px)": {
                    fontSize: "18px !important",
                  },
                  fontFamily: "AvenirLTStdRoman",
                  textDecoration: "none",
                  position: "relative",
                  "&::after": {
                    content: "''",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: 0,
                    height: "2px",
                    backgroundColor: "#49194E",
                    transition: "width 0.3s ease",
                  },
                  "&:hover::after": {
                    width: "100%",
                  },
                }}
                href="/subscribe"
                component={"a"}
                // onClick={() => setSubscribeModal(true)}
              >
                Subscribe
              </Typography>
              {/* </NavLink> */}
              <NavLink style={navLinkStyles} to={"/faq"}>
                <Typography
                  sx={{
                    borderRadius: "8px",
                    cursor: "pointer",
                    color: "#49194E",

                    "@media (min-width:1500px)": {
                      fontSize: "18px !important",
                    },
                    "@media (max-width:1499px)": {
                      fontSize: "18px !important",
                    },
                    fontFamily: "AvenirLTStdRoman",
                    position: "relative",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: 0,
                      height: "2px",
                      backgroundColor: "#49194E",
                      transition: "width 0.3s ease",
                    },
                    "&:hover::after": {
                      width: "100%",
                    },
                    // "&:hover": {
                    //   color: "#782980",
                    // },
                  }}
                >
                  FAQs
                </Typography>
              </NavLink>
            </Box>
            <Box
              sx={{
                display: { md: "none", lg: "flex", sm: "none", xs: "none" },
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Badge
                badgeContent={
                  result?.data?.length > 0 ? result?.data?.length : "0"
                }
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#E4D5F4",
                    color: "#000000",
                    fontSize: "15px",
                    fontFamily: "AvenirLTStdRoman",
                    height: "23px",
                    width: "23px",
                    borderRadius: "100%",
                  },
                }}
              >
                <Button
                  className={"btn"}
                  sx={{
                    fontSize: {
                      xl: "15px !important",
                      xs: "15px !important",
                    },
                  }}
                  component={Link}
                  to="/cart"
                >
                  Cart{" "}
                  <Typography
                    ml={"0.5rem"}
                    component={"img"}
                    width={"16px"}
                    src={cart}
                  />
                </Button>
              </Badge>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        {/* <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#e4dbe7",
              padding: "1rem",
            },
          }}
        >
          {drawer}
        </Drawer> */}
        <Drawer
            anchor={"top"}
            open={state["top"]}
            onClose={toggleDrawer("top", false)}
            sx={{
               background: "rgb(255 255 255 /10%)",
                backdropFilter:"blur(10px)",
              "& .MuiBackdrop-root":{
                background: "rgb(255 255 255 /30%)",
                backdropFilter:"blur(10px)"
              },
              "& .MuiDrawer-paper": {
                  background: "rgb(255 255 255 /30%)",
                  backdropFilter:"blur(10px)",
                  boxShadow:"none",
                  transition: "transform 0.8s all",
            },


            }}
          >
           <Box
      sx={{ width: "100%",p:"0 1rem 1rem"}}
      role="presentation"
      // onClick={toggleDrawer("top", false)}
      // onKeyDown={toggleDrawer("top", false)}
    >
 {drawer}
    </Box>
           
          </Drawer>
      </nav>

      {/* ***subscribe modal**** */}
      {/* <Subscribe
        subscribeModal={subscribeModal}
        setSubscribeModal={setSubscribeModal}
      /> */}
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
