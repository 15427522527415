import * as React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import img_1 from "../../Assets/Images/img_1.png";
import img_2 from "../../Assets/Images/img_2.png";
import img_3 from "../../Assets/Images/img_3.png";
import img_4 from "../../Assets/Images/img_4.png";
import img_5 from "../../Assets/Images/img_5.png";
import img_6 from "../../Assets/Images/img_6.png";
import img_7 from "../../Assets/Images/img_7.png";
import img_8 from "../../Assets/Images/img_8.png";
import img_9 from "../../Assets/Images/img_9.png";

import "./imageGallery.css"; // Import CSS styles

export default function ImageGallery() {
  const containerRef = React.useRef(null);

  const handleHover = (entering) => {
    const images = containerRef.current.querySelectorAll("img");
    images.forEach((img, index) => {
      img.classList.toggle("animate", entering);
    });
  };

  return (
    <Container
      ref={containerRef}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      sx={{
        position: "relative",
        opacity: "0.05",

        // transition: "opacity 0.2s ease-in",
        "&:hover": {
          "& p": {
            opacity: "1",
          },
          opacity: 1,
        },
        "& p": {
          opacity: "0.05",
          transition: "0.2s all",
        },
        "& img": {
          width: "100%",
          // p: "1px",
          // opacity: 0.05,
        },
        "& .MuiGrid-root": {
          lineHeight: "0",
        },
      }}
    >
      <Box>
        <Grid container spacing={0} overflow={"hidden"}>
          <Grid item lg={4.44}>
            <img
              src={img_1}
              alt="img_1"
              // style={{ border: "2px solid rgb(109 79 160 / 62%)" }}
              // className="fade-left"
            />
            <img alt="img_1" src={img_6} className="fade-left" />
            <img alt="img_1" src={img_4} className="fade-up" />
          </Grid>

          <Grid item lg={7.56}>
            <Grid container spacing={0}>
              <Grid item lg={4}>
                <img alt="img_1" src={img_3} className="fade-down" />
              </Grid>
              <Grid item lg={8}>
                <img
                alt="img_1"
                  src={img_2}
                  style={{ height: "100%" }}
                  className="fade-right"
                />
              </Grid>
              <Grid container spacing={0}>
                <Grid item lg={6}>
                  <img alt="img_1" src={img_7} className="scale-in" />
                </Grid>
                <Grid item lg={6}>
                  <img
                  alt="img_1"
                    src={img_8}
                    style={{ height: "100%" }}
                    className="fade-right"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item lg={4}>
                  <img
                  alt="img_1"
                    src={img_5}
                    style={{ height: "100%" }}
                    className="fade-up"
                  />
                </Grid>
                <Grid item lg={8}>
                  <img
                  alt="img_1"
                    src={img_9}
                    style={{
                      // border: "2px solid rgb(109 79 160 / 62%)",
                      height: "100%",
                    }}
                    // className="fade-left"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Typography
          sx={{
            position: "absolute",
            top: "53%",
            left: "53%",
            transform: "translate(-53%, -53%)",
            color: "#f0edf699",
            fontSize: { sm: "80.8px", xs: "30px" },
            fontFamily: "AvenirLTStdBlack",
            letterSpacing: { lg: "6px", sm: "6px", xs: "6px" },
            "@media (min-width:1300px)": {
              letterSpacing: "35px !important",
            },
            WebkitTextStroke: "1.5px #782980",
            fontWeight: 900,
          }}
          className="fade-up"
        >
          YOUR
        </Typography>
      </Box>
    </Container>
  );
}
