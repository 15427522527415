import React from 'react';
import { Box, Grid, Typography, Container, Button } from '@mui/material';
import consultation from '../../Assets/Images/go-brochure/consultation.png';
import support from '../../Assets/Images/go-brochure/support.png';
import videos from '../../Assets/Images/go-brochure/videos.png';
import training from '../../Assets/Images/go-brochure/training.png';
import { Link } from 'react-router-dom';

const ServicesSection = () => {
  return (
    <Box
      sx={{
        background: '#782980',
        p: '3.5rem 0',
        '& .service_title': {
          fontFamily: 'AvenirLTStdRoman',
          fontSize: { lg: '55px', xs: '35px' },
          lineHeight: 'normal',
          fontWeight: '600',
          color: '#fff',
          textAlign:{sm:"start",xs:"center"}
        },
        '& .img_box': {
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '15px',
          lineHeight:"0",
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'transform 0.3s ease-in-out',
          '& img': {
            height: '100%',
            width: '100%',
            transition: 'transform 0.3s ease-in-out',
            transform: 'scale(1.12)'
          },
          '&:hover img': {
            transform: 'scale(1)', 
          },
        },
        '& .sevices_btn': {
          fontSize: '20px',
          textTransform: 'capitalize !important',
          fontFamily: 'AvenirLTStdBook !important',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          transition: '0.3s all',
          color: '#fff',
          border: '1px solid #fff',
          lineHeight: 'normal',
          fontWeight: '600',
          borderRadius: '8px',
          p: '8px 25px',
          '&:hover': {
            background: '#782980',
            border: '1px solid transparent',
          },
        },
      }}
    >
      <Container>
        <Grid container spacing={4}>
          {/* Left Side */}
          <Grid
            item
            md={4}
            sm={3}
            xs={12}
            sx={{
              display: {sm:'flex'},
              alignItems: 'center',
            }}
          >
            <Typography className="service_title">
              Explore <br /> Our
              <br /> Services
            </Typography>
          </Grid>

          {/* Right Side */}
          <Grid item md={8} sm={9} xs={12}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={6} xs={12}>
                {/* Consultation */}
                <Box className="img_box" sx={{mt:{sm:"4rem"}}}>
                  <img src={consultation} alt="Consultation" />
                  <Button disableRipple className="sevices_btn" component={Link} to="/consultation">
                    Consultation
                  </Button>
                </Box>
                {/* GO Videos */}
                <Box className="img_box" sx={{ mt: 3 }}>
                  <img src={videos} alt="GO Videos" />
                  <Button disableRipple className="sevices_btn" component={Link} to="/go-video">
                    GO Videos
                  </Button>
                </Box>
              </Grid>

              <Grid item md={6} sm={6} xs={12}>
                {/* GO Support */}
                <Box className="img_box">
                  <img src={support} alt="GO Support" />
                  <Button disableRipple className="sevices_btn" component={Link} to="/go-support">
                    GO Support
                  </Button>
                </Box>
                {/* Training */}
                <Box className="img_box" sx={{ mt: 3 }}>
                  <img src={training} alt="Training" />
                  <Button disableRipple className="sevices_btn" component={Link} to="/training">
                    Training
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServicesSection;
