import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Paypal({
  orderId,
  newAdd,
  totalAmount,
  unitPrice,
  count,
  billing_address_info,
  shipping_address_info,
  shipping,
  orderItems,
  itemTotalAmount,
  shippingNew

}) {
  const [paypalContainer, setPaypalContainer] = useState(null);
  const navigate = useNavigate();
  const shippingFee = shipping;
  const _purchase_units =  [
    {
      amount: {
        currency_code: "USD",
        value: totalAmount?.toFixed(2),
        breakdown: {
          item_total: {
            currency_code: "USD",
            value: itemTotalAmount?.toFixed(2),
          },
          shipping: {
            currency_code: "USD",
            value: shippingFee?.toFixed(2), // Shipping charges
          },
        },
      },
      items: 
        orderItems.map((e,i) => {
          return (
            {
              name: e.productData[0].name /* Shows within upper-right dropdown during payment approval */,
              description: e.productData[0].name /* Item details will also be in the completed paypal.com transaction view */,
              unit_amount: {
                currency_code: "USD",
                value: parseFloat(e.productData[0].price).toFixed(2),
              },
              quantity: e.quantity,
            }
          )
        })
    
      ,
      shipping_address: {
        line1:
          newAdd === false
            ? billing_address_info?.address_1
            : shipping_address_info?.address_1,
        line2:
          newAdd === false
            ? billing_address_info?.address_2
            : shipping_address_info?.address_2,
        city:
          newAdd === false
            ? billing_address_info?.city
            : shipping_address_info?.city,
        country_code:
          newAdd === false
            ? billing_address_info?.country
            : shipping_address_info?.country,
        Postal_CODE:
          newAdd === false
            ? billing_address_info?.postal
            : shipping_address_info?.postal,
        state:
          newAdd === false
            ? billing_address_info?.state
            : shipping_address_info?.state,
        phone:
          newAdd === false
            ? billing_address_info?.phone
            : shipping_address_info?.phone,
        recipient_name:
          newAdd === false
            ? billing_address_info?.fname
            : shipping_address_info?.fname,
      },
    },
  ] ; 

  console.log(_purchase_units);
  useEffect(() => {
    if (!paypalContainer) {
      console.error("PayPal container not found");
      return;
    }
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            purchase_units: _purchase_units,
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(function (details) {
            console.log(details);
            confirmOrder(orderId, details);
          });
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypalContainer);
    // eslint-disable-next-line
  }, [paypalContainer]);

    const confirmOrder = async (id, details) => {
      console.log(details);
      const response = await fetch(
        `${process.env.REACT_APP_API}/shopping-cart/confirm-order`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          }, 
          body: JSON.stringify({
            order_id: id,
            transaction_body: JSON.stringify(details),
            transaction_id: details.id,
            shipping_method:shippingNew
          }),
        }
      );
      const data = await response.json();
      if (data.status) {
        navigate("/success");
      } else {
        alert("Something went wrong..");
      }
    };
    
  return (
    <Box mt={"1rem"}>
    <div
    data-pp-message
    data-pp-style-layout="text"
    data-pp-style-logo-type="inline"
    data-pp-style-text-color="black"
    data-pp-style-text-size="16"
    data-pp-amount={totalAmount?.toFixed(2)}
    data-pp-placement="payment">
</div>
      <div ref={setPaypalContainer}></div>
    </Box>
  );
}

export default Paypal;
