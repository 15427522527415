import React, { useState } from 'react';
import { Box, Container, Typography, keyframes } from "@mui/material";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import logo from "../../Assets/Images/logo.gif";
import vertical from "../../Assets/Images/vertical.svg";
import Navbar from "../../Components/Navbar";
import banner from "../../Assets/Images/banner.png";
import banner_2 from "../../Assets/Images/banner_2.png";
import banner_3 from "../../Assets/Images/banner_3.png";
import banner_4 from "../../Assets/Images/banner_4.png";

// Slide data with images and text
const slides = [
  { image: banner, text: "Identify breathing habits" },
  { image: banner_2, text: "Discover their effects" },
  { image: banner_3, text: "Learn good breathing habits" },
  { image: banner_4, text: "Unlearn the bad ones" },
];

// Zoom in animation
const zoomIn = keyframes`
  0% {
    transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
`;

// Zoom out animation
const zoomOut = keyframes`
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }
`;

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (splide) => {
    setCurrentSlide(splide.index);
  };

  return (
    <>
      {/* Fixed Navbar */}
      <Box
        sx={{
        //   zIndex: 1200,
        //   width: "100%",
        //   backdropFilter: "blur(15px)",
        //   background: "rgb(240 237 246 / 20%)",
        //   position: "fixed",
        //   top: 0,
        }}
      >
        {/* <Container> */}
          <Navbar />
        {/* </Container> */}
      </Box>

      {/* Fixed Logo and Common Text */}
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          right: "8%",
          zIndex: 2,
          display: { md: "block", xs: "none" },
        }}
      >
        <Typography component={"img"} src={logo} width="350px" />
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: { sm: "50%", xs: "65%" },
          left: { sm: "5%", xs: "0%" },
          transform: { sm: "translate(-5%,-50%)", xs: "translate(-0%,-65%)" },
          zIndex: "2",
          width: "100%",
          p: "1rem",
        }}
      >
        <Container>
          <Typography component={"img"} src={vertical} sx={{ display: { md: "block", xs: "none" } }} />
          <Box mt={"5rem"}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                width: { sm: "400px", xs: "100%" },
                fontFamily: "AvenirLTStdRoman",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              Optimize respiration
              <br/>
              for improving health and performance
            </Typography>
            <Typography
              sx={{
                width: "180px",
                mt: "10px",
                background: "#fff",
                height: "3px",
              }}
            />
          </Box>
          <Box mt={"3rem"}>
            <Typography
              sx={{
                position: "relative",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                width: { sm: "400px", xs: "100%" },
                fontFamily: "AvenirLTStdRoman",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              with CapnoLearning technology,
              <br/>
              the CapnoTrainer.
            </Typography>
            <Typography
              sx={{
                width: "180px",
                mt: "10px",
                background: "#fff",
                height: "3px",
              }}
            />
          </Box>
          <Box mt={"3rem"}>
            <Typography
              sx={{
                position: "relative",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                width: { sm: "400px", xs: "100%" },
                fontFamily: "AvenirLTStdRoman",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
             Professional and personal applications
            </Typography>
            <Typography
              sx={{
                width: "180px",
                mt: "10px",
                background: "#fff",
                height: "3px",
              }}
            />
          </Box>
        </Container>
      </Box>

      {/* Background and Rotating Text Carousel */}
      <Splide
        options={{
          type: 'loop',
          autoplay: true,
          interval: 3000, // 3-second interval
          speed: 1000, // Slow transition for smooth effect
          arrows: false,
          pagination: false,
          pauseOnHover: false,
        }}
        onMoved={handleSlideChange} // Trigger slide change handler
      >
        {slides.map((slide, index) => (
          <SplideSlide key={index}>
            <Box
              sx={{
                backgroundImage: `url(${slide.image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: { lg: "1080px", xs: "800px" },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  left: 0,
                  zIndex: "1",
                  width: "100%",
                  height: "100%",
                  background: "rgb(0 0 0 / 24%)",
                }}
              />
              <Typography
                sx={{
                  position: "relative",
                  zIndex: "100",
                  pt: { lg: "6rem", sm: "5rem", xs: "6rem" },
                  fontFamily: "ArchivoBlack",
                  fontSize: { sm: "75.29px", xs: "40px" },
                  textAlign: "center",
                  background: "linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%)",
                  WebkitBackgroundClip: "text", // Clip the background to the text
                  WebkitTextFillColor: "transparent", // Make the text transparent to reveal the gradient
                  backgroundClip: "text", // Standard background-clip for non-webkit browsers
                  textFillColor: "transparent", // Standard text fill for non-webkit browsers
                  opacity: currentSlide === index ? 1 : 0, // Show only the active slide's text
                  transition: "opacity 0.5s ease-in-out", // Transition effect for opacity
                  animation: currentSlide === index ? `${zoomIn} 0.3s ease-in-out` : `${zoomOut} 0.5s ease-in-out`, // Apply the appropriate animation
                }}
              >
                {slide.text}
              </Typography>
            </Box>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};

export default Banner;
