import React, { useState, useRef } from "react";
import { Box, Container } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Mousewheel, Keyboard, Pagination, Navigation } from "swiper/modules";
import SliderBox from "./SliderBox";
import monitor from "../../Assets/Images/monitor_1.png";
import monitor_2 from "../../Assets/Images/monitor_2.png";
import monitor_3 from "../../Assets/Images/monitor_3.png";
import breathing_1 from "../../Assets/Images/breathing_1.png";
import yoga from "../../Assets/Images/yoga.png";
import breathing_2 from "../../Assets/Images/breathing_2.png";
import breathing_3 from "../../Assets/Images/breathing_3.png";
import breathing_4 from "../../Assets/Images/breathing_4.png";

const sliderData = [
  { img: monitor, title: <>Real-time respiratory chemistry (CO<sub>2</sub> O<sub>2</sub>)</>, para: "Help clients and yourself to identify breathing habits and their effects, disengage dysfunctional habits, and learn new habits that immediately enhance health and performance" },
  { img: monitor_2, title: "Realtime breathing mechanics (rate, holding, pushing, aborting)", para: "Help clients and yourself to identify breathing habits and their effects, disengage dysfunctional habits, and learn new habits that immediately enhance health and performance" },
  { img: monitor_3, title: "Real-time testing and learning", para: "Help clients and yourself to identify breathing habits and their effects, disengage dysfunctional habits, and learn new habits that immediately enhance health and performance" },
  { img: breathing_2, title: "Improves Respiratory Health & Wellness", para: "Supports better lung function and overall health, reducing the risk of respiratory issues." },
  { img: breathing_1, title: "Enhances Athletic Performance", para: "Increases endurance and oxygen efficiency, allowing athletes to perform at their best." },
  { img: yoga, width: "70%", title: "Reduces Stress & Anxiety", para: "Teaches effective breathing techniques to manage stress and anxiety, promoting mental clarity and calmness." },
  { img: breathing_4, width: "80%", title: "Easy to Use and Portable", para: "Designed for convenience, allowing users to practice breathing exercises anywhere and anytime." },
  { img: breathing_3, title: "Scientifically Backed Technology", para: "Built on evidence-based research, ensuring the most effective and reliable results for users." },
];

const BreathingSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSectionRef = useRef(null); // Reference to the next section

  const handleScrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Box
        sx={{
          background: "#423062",
          p: "5rem 1rem",
          "& .swiper-pagination-bullet": {
            background: "#E4D5F4 ",
          },
          "& .swiper-button-prev, .swiper-button-next": {
            color: "#782980 ",
            background: "#F2EAF2",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
          },
          "& .swiper-button-prev:after, .swiper-button-next:after": {
            fontSize: "20px",
            fontWeight: "bold",
          },
        }}
      >
        <Container sx={{ position: "relative" }}>
          <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                color: "#ffff",
                fontSize: { sm: "24px", xs: "18px" },
                fontFamily: "AvenirLTStdRoman",
                gap: "10px",
                pb: { md: 0, xs: "1rem" },
              }}
            >
              <Box
                sx={{
                  alignSelf: "center",
                  mb: { md: "35px" },
                  width: "40px",
                  background: "#F2EAF2",
                  height: "1px",
                }}
              />
              Why You’ll Love CapnoTrainer
            </Box>
            <Box
              sx={{
                width: "1px",
                background: "#F2EAF2",
                height: "74px",
                display: { md: "block", xs: "none" },
              }}
            />
          </Box>
        </Container>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={false}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Mousewheel, Keyboard, Pagination, Navigation]}
          className="mySwiper"
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          // onReachEnd={handleScrollToNextSection} // Scroll to the next section when reaching the last slide
        >
          {sliderData.map((e, i) => (
            <SwiperSlide key={i}>
              <SliderBox e={e} isActive={activeIndex === i} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      {/* Next Section */}
      <Box ref={nextSectionRef}>

      </Box>
    </>
  );
};

export default BreathingSlider;
