import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Divider, Grid, Slide, Typography } from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import VideoModal from "../GoVideo/VideoModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InterviewList({ setOpenModal, openModal }) {
  const [videoLink, setVideoLink] = React.useState("");
  const [openVideo, setOpenVideo] = React.useState(false);
  const handleVideo = (link) => {
    setVideoLink(link);
    setOpenVideo(true);
  };
  const List = [
    [
      "Dr Mercola introduces Dr Litchfield",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip1+-+Dr+Mercola+introduces+Dr+Litchfield.mp4",
      "58 sec",
    ],
    [
      "Dr Mercola - CO2 is vital to good health",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip2+-+Dr+Mercola+-+CO2+is+vital+to+good+health+.mp4",
      "58 sec",
    ],
    [
      "Dr Mercola - CO2 is a critical missing link",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip3+-+Dr+Mercola+-+CO2+is+a+critical+missing+link.mp4",
      "1 min 43 sec",
    ],
    [
      "Dr Mercola - Capnolearning Technology",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip4+-+Dr+Mercola+-+Capnolearning+Technology.mp4",
      "42 sec",
    ],
    [
      "Dr Litchfield - Breathing Habit Triggers",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip5+-+Dr+Litchfield+-+Breathing+Habit+Triggers.mp4",
      "2 min 20 sec",
    ],
    [
      "Dr Mercola and Dr Litchfield talk about the CapnoTrainer",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip6+-+Dr+Mercola+and+Dr+Litchfield+talk+about+the+CapnoTrainer.mp4",
      "3 min 05 sec",
    ],
    [
      "Dr Litchfield explains symptom misinterpretations",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip7+-+Dr+Litchfield+explains+symptom+misinterpretations.mp4",
      "24 sec",
    ],
    [
      "Dr Litchfield and Dr Mercola discuss the role of trust in breathing",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip8+-+Dr+Litchfield+and+Dr+Mercola+discuss+the+role+of+trust+in+breathing.mp4",
      "2 min 06 sec",
    ],
    [
      "Dr Mercola - The CapnoTrainer and my bad breathing habit",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip9+-+Dr+Mercola+-+The+CapnoTrainer+and+my+bad+breathing+habit.mp4",
      "1 min 24 sec",
    ],
    [
      "Dr Litchfield - Mechanical vs behavioral explanations of breathing technique outcomes",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip10+-+Dr+Litchfield+-+Mechanical+vs+behavioral+explanations+of+breathing+technique+outcomes.mp4",
      "58 sec",
    ],
    [
      "Dr Mercola and Dr Litchfield discuss Buteyko Breathing",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip11+-+Dr+Mercola+and+Dr+Litchfield+discuss+Buteyko+Breathing.mp4",
      "6 min 18 sec",
    ],
    [
      "Dr Mercola hypocapnia is a very serious issue",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip12+-+Dr+Mercola+hypocapnia+is+a+very+serious+issue.mp4",
      "24 sec",
    ],
    [
      "Dr Mercola introduces breathing behavioral analysis",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip13+-+Dr+Mercola+introduces+breathing+behavioral+analysis.mp4",
      "1 min 36 sec",
    ],
    [
      "Dr Litchfield describes breathing behavioral analysis",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip14+-+Dr+Litchfield+describes+breathing+behavioral+analysis.mp4",
      "6 min 32 sec",
    ],
    [
      "Dr Litchfield - Physiology is a learning system",
      "https://better-physiology-videos.s3.amazonaws.com/marcola-interviews/clip15+-+Dr+Litchfield+-+Physiology+is+a+learning+system.mp4",
      "1 min 11 sec",
    ],
  ];
  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: { lg: "70%" }, // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background: "#fff",
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
        <Box
          sx={{
            p: { sm: "1rem 2rem", xs: "1rem" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            "& .MuiDivider-root::before": {
              width: "0%",
            },
            "& .MuiDivider-root::after": {
              borderTop: "1.5px solid #782980",
              // width:{sm:"auto",xs:"0%"}
            },
            "& .MuiDivider-wrapper": {
              pl: "0",
            },
          }}
        >
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
              color: "#49194E",
              cursor: "pointer",
              fontSize: "1.8rem",
            }}
            onClick={() => setOpenModal(false)}
          />

          <Divider textAlign="left" sx={{ pt: "1.5rem", position: "relative" }}>
            <Typography
              sx={{
                color: "#782980",
                fontSize: { sm: "24px", xs: "16px" },
                fontFamily: "AvenirLTStdRoman",
                fontWeight: "600"
                
              }}
            >
              Click on the clip names to watch them
            </Typography>
          </Divider>
        </Box>
        <Box
          sx={{
            p: { md: "3rem 2rem 3rem", xs: "1rem" },
            background: "rgba(200, 167, 203, 0.16)",

            "& .subTitle": {
              fontFamily: "AvenirLTStdRoman",
              fontSize: "16px",
              color: "#49194E",
              cursor:"pointer"
            },
          }}
        >
          <Grid container spacing={3}>
          {
            List?.map((data,i)=>(
                <Grid item md={6} xs={12} key={i}>
              <Typography
                className="subTitle"
                // sx={{fontWeight:"600"}}
                onClick={() =>
                  handleVideo(data[1])
                }
              >
                {`${i+1})`} <span style={{fontWeight:"600"}}>{data[0]}</span>{" "}
                <span style={{ color: "#782980", opacity: "0.8" }}>
                  ({data[2]})
                </span>
              </Typography>
            </Grid>
            ))
          }
          </Grid>
        </Box>
      </Dialog>
      <VideoModal
        openModal={openVideo}
        setOpenModal={setOpenVideo}
        videoLink={videoLink}
      />
    </React.Fragment>
  );
}
