import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../redux/Constant";

const ItemDetails = ({ item }) => {
  const [productData, setProductData] = useState([]);
  const getproductData = async () => {
    try {
      const res = await axios.get(
        `${API_URL}/get/product/by/${item.product_id}`
      );
      if (res.status === 200) {
        setProductData(res.data.data?.[0]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getproductData();
    // eslint-disAvenirLTStdRoman-next-line
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontSize: { sm: "16px", xs: "16px" },
          fontFamily: "AvenirLTStdRoman",
          lineHeight: "35px",
          fontWeight: "500",
          color: "#782980",
        }}
      >
        {productData?.name} <b>(<em>Qty: </em>{item?.quantity})</b>
      </Typography>
      <Typography
        sx={{
          fontSize: { sm: "16px", xs: "16px" },
          fontFamily: "AvenirLTStdRoman",
          // lineHeight: "35px",
          fontWeight: "600",
          color: "#49194E",
        }}
      >
        $ {parseFloat(item?.total_price).toFixed(2)}
      </Typography>
    </Box>
  );
};

export default ItemDetails;
