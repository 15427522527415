import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_URL, IMAGE_URL } from "../../redux/Constant";
import { getCart } from "../../store/cartSlice";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SuppliesModal from "./SuppliesModal";
import { getProductsById } from "../../store/ProductByIdSlice";

const GoCard = ({ data, setShowSnackbar, setAddSuccessMsg,setAddErrorMsg }) => {
  const [openModal, setOpenModal] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (openModal) {
      setImages([])
      dispatch(getProductsById(data?.id));
    }
  }, [dispatch, data?.id, openModal]);
    const { data: result } = useSelector((state) => state.productById);
    const [productData] = result.data || [];
const [images, setImages] = React.useState([]);
  React.useEffect(() => {
  if (productData?.category) {
    const _image = productData?.product_images.map((v, i) => {
      return {
        original: `${IMAGE_URL}/${v}?${Date.now()}`,
        thumbnail: `${IMAGE_URL}/${v}?${Date.now()}`,
        originalClass: "custom-image",
        thumbnailClass: "custom-thumbnail",
      };
    });
    setImages(_image);

    // relatedProductData();
  }
  // eslint-disable-next-line
}, [productData,data?.id]);
  const navigate = useNavigate();
  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const addToCartFunc = async (productId, quantity) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_URL}/add-to-cart`, {
        user_id: localStorage.getItem("user")
          ? localStorage.getItem("user")
          : "",
        product_ids: [productId],
        quantities: [quantity],
      });
      if (res.status === 200) {
        // console.log(res);
        setOpenModal(false)
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddSuccessMsg("");
        }, 2000);
        setAddSuccessMsg(res.data.message);
        const userId = res.data.user_id ;
        localStorage.setItem("user", res.data.user_id);
        dispatch(getCart(userId));
      }
  
    } catch (err) {
      console.log(err);
          if (err.response.status === 404) {
        setLoading(false);
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          setAddErrorMsg("")
        }, 2000);
        setAddErrorMsg("Out of stock");
      }
    }
  };
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: "10px",
        p: "1.5rem 1rem",
        transition: "0.3s all",
        // "&:hover": {
        //   transform: "translateY(-8px)",
        // },
      }}
    >
    <Box onClick={() => setOpenModal(true)} sx={{cursor:"pointer"}}>
      <Typography component={"img"} src={`${IMAGE_URL}/${data?.product_images && data?.product_images }`} width={"100%"} />
    </Box>
      <Typography
        className="subTitle"
        sx={{ color: "#782980 !important", pb: "0.2rem",pt:"1rem", fontWeight: "600" }}
      >
        {data?.name}
      </Typography>
      <Typography className="subTitle" sx={{ pb: "0.5rem", height: "56px" }}>
      {data?.subtitle}
      </Typography>
   
      <Box
          py={"0.5rem"}
        sx={{
          
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          className="subTitle"
        >
          Quantity
        </Typography>
        <Box
      display="flex"
      alignItems="center"
      gap={2}
  
    >
      {/* Decrement Button */}
      <Button
        variant="outlined"
        disableRipple
        onClick={decrement}
        disabled={count==0} // Disable button when count is 1
        sx={{
          minWidth: "35px",
          height: "25px",
          padding: "0",
          fontSize: "1.5rem",
          pb:"0.2rem",
          color:"#49194E",
          borderColor:"#49194E",
          "&:hover":{
            borderColor:"#49194E",
          }
          // lineHeight: "0rem",
        }}
      >
        -
      </Button>

      {/* Count Display */}
      <Typography  className="subTitle" fontWeight={"600"}>{count}</Typography>

      {/* Increment Button */}
      <Button
      disableRipple
        variant="outlined"
        onClick={increment}
        sx={{
          minWidth: "35px",
          height: "25px",
          padding: "0",
          fontSize: "1.5rem",
          color:"#49194E",
          borderColor:"#49194E",
          // lineHeight: "0rem",
          "&:hover":{
            borderColor:"#49194E",
          }
        }}
      >
        +
      </Button>
    </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: "0.5rem",
          gap: "10px",
        }}
      >
        <Typography
          className="subTitle"
          sx={{ color: "#782980 !important", fontWeight: "600" }}
        >
          $ {data.price}
        </Typography>
        <Button
          className={"btn"}
          disabled={count === 0}
          onClick={() => addToCartFunc(data?.id, count)}
          sx={{
            fontSize: "16px",
            textTransform: "capitalize",
            width: "150px",
            //   padding: "6px 20px !important",
            fontFamily: "AvenirLTStdBook",
            "&.Mui-disabled": { background: "#782980a1 !important" },
          }}
          
        >
              {loading ? (
            <CircularProgress
              sx={{
                width: "23px !important",
                height: "23px !important",
                color: "#e4dbe7",
              }}
            />
          ) : (
            "Add to Cart"
          )}
        </Button>
      </Box>
      <SuppliesModal setOpenModal={setOpenModal} openModal={openModal} addToCartFunc={addToCartFunc} loadingKit={loading} data={productData} images={images}/>
    </Box>
  );
};

export default GoCard;
