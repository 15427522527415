import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Rating, Slide, Typography } from "@mui/material";
import story_1 from "../../Assets/Images/story_1.png";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function StoryModal({ setOpenModal, openModal }) {


  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: {lg:"48%"}, // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background: "#F0EDF6",
            
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
        <Box
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton> */}
          <CloseIcon
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#C8A7CB",
              cursor: "pointer",
              fontSize: "1.8rem",
            }}
            onClick={() => setOpenModal(false)}
          />
        </Box>
        <Box
          sx={{
            p: "1rem 2rem 2rem",
            textAlign:"center"
          }}
        >
        <Typography
      component={"img"}
      src={story_1}
      width={"82px"}
    />
    <Typography
      sx={{
        color: "#782980",
        fontSize: { sm: "20px", xs: "20px" },
        fontFamily: "AvenirLTStdRoman",
        //   fontWeight: "600",
        // pt:"0.5rem"
      }}
    >
      Mark
    </Typography>
    <Typography
      sx={{
        color: "#B1B1B1",
        fontSize: { sm: "16px", xs: "16pxpx" },
        fontFamily: "AvenirLTStdRoman",
        //   fontWeight: "600",
        pb:"1rem"
      }}
    >
      New York, USA
    </Typography>
    <Rating name="size-medium" defaultValue={5} sx={{color:"#f9d410"}} />
    <Typography
      sx={{
        color: "#49194E",
        fontSize: { sm: "16px", xs: "16px" },
        fontFamily: "AvenirLTStdRoman",
        //   fontWeight: "600",
        pb: "1.5rem",
        pt: "0.5rem",
      }}
    >
     "CapnoTrainer is a fantastic tool for medical training. The realistic simulations have improved my skills and confidence in interpreting capnography waveforms. As a healthcare professional, I find the hands-on experience invaluable. It allows me to practice in a safe environment, enabling me to react effectively in real-life scenarios. The ability to visualize and understand capnography data has made a significant difference in my patient assessments. 
     <br/>
     I highly recommend CapnoTrainer to anyone looking to enhance their medical training."
    </Typography>
    <Typography sx={{
        width:"220px",
        height:"8px",
        background:"#782980",
        borderRadius:"10px",
        m:"0 auto"
    }}/>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
