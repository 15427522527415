import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Slide, Typography } from "@mui/material";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function FaqModal({ setOpenModal, openModal,data}) {


  return (
    <React.Fragment>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            margin: "1rem", // Center horizontally
            maxWidth: {lg:"60%"}, // Set a max width
            width: "100%",
            borderRadius: "10px", // Rounded corners,
            background: "#F0EDF6",
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Dim the background
          },
        }}
      >
        <Box
          sx={{
            p: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* <IconButton
            sx={{ position: "absolute", top: 5, right: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton> */}
          <CancelOutlinedIcon
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
              color: "#49194E",
              cursor: "pointer",
              fontSize: "1.8rem",
            }}
            onClick={() => setOpenModal(false)}
          />
        </Box>
        <Box
          sx={{
            p: "0rem 2rem 2rem",
            "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#782980",
            fontWeight:"600",
            transition:"0.3s all",
            cursor:"pointer",
            pb:"1rem",
            }
          }}
        >
           <Typography className="subTitle">
        {data?.question}{" "}
     
      </Typography>
      <Typography
          component={"span"}
          dangerouslySetInnerHTML={{
                          __html:data.answer,
                        }}
          sx={{
            "& p":{
              marginTop:0,
              fontFamily: "AvenirLTStdRoman",
              color: "#49194E",
              fontSize:"16px"

        }    
          }}
        />  
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
