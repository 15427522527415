import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import NewsLetter from "../../Components/NewsLetter";
import peter from "../../Assets/Images/peter.png";
import book from "../../Assets/Images/common/book.png";
import interview from "../../Assets/Images/common/interview.png";
import course from "../../Assets/Images/common/course.png";
import lecture from "../../Assets/Images/common/lecture.png";
import CommonSolutionSection from "../../Components/CommonSolutionSection";
import founder_img from "../../Assets/Images/founder_img.png";
import lung from "../../Assets/Images/lung.svg";
import idea from "../../Assets/Images/idea.svg";
import star from "../../Assets/Images/start.svg";

const FounderPage = () => {
  const IconBox = ({ src, sx }) => (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#C8A7CB",
        borderRadius: "50%",
        width: { md: "95px", xs: "50px" },
        height: { md: "95px", xs: "50px" },
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        ...sx,
      }}
    >
      <Typography
        component={"img"}
        src={src}
        sx={{ width: { md: "auto", xs: "30px" } }}
      />
    </Box>
  );
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { lg: "8rem", md: "6rem", xs: "4rem" },
          overflow:"hidden",
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "16px",
            color: "#49194E",
          },
        }}
      >
        <Container>
          <Box
            sx={{
              position: "relative",
              // mb:"2rem"
            }}
          >
            <Box
              sx={{
                background: "#782980",
                p: "1.5rem",
                mb: "3rem",
                height: "450px",
                position: "absolute",
                width: "100%",
              }}
            />
            <Grid
              container
              spacing={4}
              sx={{
                mt: "0",
                mb: { lg: "0", xs: "3rem" },
                position: "relative",
                top: "10px",
              }}
            >
              <Grid
                item
                md={7}
                xs={12}
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: { md: "start", xs: "center" },
                }}
              >
                <Box px={"1rem"}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#fff",
                      fontFamily: "AvenirLTStdRoman",
                      pl: "10px",
                    }}
                  >
                    Creator of CapnoLearning® and the CapnoTrainer®
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "AvenirLTStdBlack",
                      fontSize: { md: "118px", xs: "60px" },
                      lineHeight: "normal",
                      color: "#fff", // Slightly transparent white
                      textAlign: { md: "start", xs: "center" },
                      textShadow: `
  -1px -1px 2px rgba(120, 41, 128, 0.3), /* Top left shadow with low opacity */
   1px -1px 2px rgba(120, 41, 128, 0.3), /* Top right shadow */
  -1px  1px 2px rgba(120, 41, 128, 0.3), /* Bottom left shadow */
   1px  1px 2px rgba(120, 41, 128, 0.3), /* Bottom right shadow */
   0px  5px 15px rgba(55, 10, 65, 0.5) /* Larger, softer shadow for depth */
`,
                      display: { md: "block", xs: "none" },
                    }}
                  >
                    Dr. Peter M <br />
                    Litchfield
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "AvenirLTStdBlack",
                      fontSize: { md: "115px", xs: "60px" },
                      lineHeight: "normal",
                      color: "#fff",
                      textAlign: { md: "start", xs: "center" },
                      textShadow: "1px 12px 10px rgba(0, 0, 0, 0.5)",
                      display: { md: "none", xs: "block" },
                      textAlign: "center",
                    }}
                  >
                    Dr. Peter M Litchfield
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box
                  sx={{
                    // lineHeight: "0",
                    position: "relative",
                    textAlign: "center",
                    width: { md: "90%", xs: "85%" },
                    m: "0 auto",
                  }}
                >
                  {/* Icon Boxes */}
                  <IconBox
                    src={lung}
                    sx={{
                      bottom: { md: "100px", xs: "20px" },
                      left: { md: "-65px", xs: "-20px" },
                    }}
                  />
                  <IconBox
                    src={idea}
                    sx={{
                      bottom: { md: "0px", xs: "-10px" },
                      right: { md: "-60px", xs: "-10px" },
                    }}
                  />

                  {/* Demo Image */}
                  <Typography
                    component={"img"}
                    src={founder_img}
                    sx={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { md: "90%", xs: "85%" },
                    m: "0 auto",
                    px: "10px",
                  }}
                >
                  <Typography
                    className="subTitle"
                    sx={{
                      fontSize: "20px !important",
                      fontWeight: "600",
                      textAlign: { md: "start", xs: "center" },
                    }}
                    py={"1rem"}
                  >
                    <Typography
                      component={"img"}
                      src={star}
                      sx={{ alignSelf: "center" }}
                    />{" "}
                    Ph.D. (Experimental Psychology)
                  </Typography>
                  <Typography
                    className="subTitle"
                    sx={{
                      fontSize: "20px !important",
                      fontWeight: "600",
                      textAlign: { md: "start", xs: "center" },
                    }}
                  >
                    <Typography component={"img"} src={star} /> CEO & Co-Founder
                    of BetterPhysiology Ltd.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box pb={"2rem"}>
            <Typography className="title" pb={"1rem"}>
              Shaping Psychology with expertise
              <br />
              and experience.
            </Typography>
            <Typography className="subTitle">
              Dr. Peter M. Litchfield is a renowned expert in psychology with a
              distinguished career spanning over five decades. He earned his
              Ph.D. in experimental psychology from the{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                University of Portland
              </span>{" "}
              in 1972, after obtaining his M.A. in psychology from{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                San Diego State University
              </span>{" "}
              and his B.A. in psychology from the{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                University of Michigan
              </span>
              . His academic journey also includes a one-year sabbatical in
              behavioral medicine at Medfield Hospital, Massachusetts, under the
              mentorship of Theodore X. Barber.
            </Typography>
          </Box>
          <Box pb={"2rem"}>
            <Typography className="title" pb={"1rem"}>
              Expert in Behavioral and Respiratory Sciences.
            </Typography>
            <Typography className="subTitle">
              Dr. Litchfield’s expertise encompasses behavioral medicine,
              behavioral physiology, applied psychophysiology, research design,
              behavioral pharmacology, placebo effects, biological learning and
              motivation, and physiological monitoring technology.{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                Since 2000,
              </span>{" "}
              he has concentrated exclusively on{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                respiratory psychophysiology
              </span>
              , making significant contributions to the field.
            </Typography>
          </Box>
          <Box pb={"2rem"}>
            <Typography className="title" pb={"1rem"}>
              President, CEO and Editor.
            </Typography>
            <Typography className="subTitle">
              Dr. Litchfield’s expertise encompasses behavioral medicine,
              behavioral physiology, applied psychophysiology, research design,
              behavioral pharmacology, placebo effects, biological learning and
              motivation, and physiological monitoring technology.{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                Since 2000,
              </span>{" "}
              he has concentrated exclusively on{" "}
              <span
                style={{
                  color: "#782980",
                }}
              >
                respiratory psychophysiology
              </span>
              , making significant contributions to the field.
            </Typography>
          </Box>
          <Box pb={"2rem"}>
            <Typography className="title" pb={"1rem"}>
              Expert in Behavioral and Respiratory Sciences
            </Typography>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <Typography className="subTitle">
                  His academic career includes a tenure as{" "}
                  <span
                    style={{
                      color: "#782980",
                    }}
                  >
                    Assistant and Associate Professor of Psychology at
                    California Polytechnic State University, San Luis Obispo,
                  </span>{" "}
                  where he taught from 1970 to 1981. Dr. Litchfield has founded
                  and led several institutions, including the Proseminar
                  Institute, the Applied Psychophysiology Institute, and the
                  Behavioral Physiology Institutes, all of which have made
                  significant strides in the fields of psychophysiology and
                  behavioral medicine.
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography className="subTitle">
                  Dr. Litchfield has been a prominent lecturer for{" "}
                  <span
                    style={{
                      color: "#782980",
                    }}
                  >
                    over 40 years, sharing his knowledge on behavioral
                    physiology, respiratory psychophysiology, and
                    self-regulation science
                  </span>{" "}
                  across North America, Asia, Australia, Europe, and Latin
                  America. His work has reached a diverse audience, including
                  medical schools, corporate groups, and health professionals,
                  making him a leading voice in the intersection of physiology
                  and behavioral science.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box pt={"2rem"}>
          <CommonSolutionSection
            text={
              <>
                Step Into His Explore <br />
                Classroom of
                <br />
                Insights
              </>
            }
            btn_1={"Course"}
            btn_2={"Book"}
            btn_3={"Interviews"}
            btn_4={"Lectures"}
            img_1={course}
            img_2={book}
            img_3={interview}
            img_4={lecture}
            link_1={"/course"}
            link_2={"/book"}
            link_3={"/interview"}
            link_4={"/lectures"}
          />
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
    </>
  );
};

export default FounderPage;
