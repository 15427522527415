import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Footer from "../../Components/Footer";
import CopyRight from "../../Components/CopyRight";
import attention from "../../Assets/Images/attention_icon.png";
import left_attention from "../../Assets/Images/left_attention.png";
import right_attention from "../../Assets/Images/right_attention.png";
import small_attention from "../../Assets/Images/small_attention.png";
import NewsLetter from "../../Components/NewsLetter";
import VideoModal from "../GoVideo/VideoModal";
import GoBackButton from "../../Components/GoBackButton";


const lectureData = [
    {
      id: 1,
      title: "VIDEO 1",
      heading:<>
        Breathing:<br/> Unexplained Symptoms and Deficits
      </>,
      subtitle:
        "Your breathing may be affecting you in profound ways, in ways you never imagined,much more so than you know, and without your realizing it.",
      video:"https://better-physiology-videos.s3.amazonaws.com/Shambhala-Breathing-Video-1.mp4",
    },
    {
      id: 2,
      title:
        "VIDEO 2",
        heading:<>Breathing:<br/> Dysfunctional Habits & Vicious Circle Learning</>,
      subtitle:
        "Bad breathing habits are vicious circle and they may stay with you for a life time,triggering unexplained symptoms and deficits year after year.",
      video:"https://better-physiology-videos.s3.amazonaws.com/Shambhala-Breathing-Video-2.mp4",
    },
    {
      id: 3,
      title:
        "VIDEO 3",
        heading:<>Breathing:<br/> Intuitive Learning vs. Prescriptive Intervention</>,
      subtitle:
        "You can learn to break your bad breathing habits, replace them with good ones,and benefit enormously from aligning your breathing with respiratory reflexes.",
      video:"https://better-physiology-videos.s3.amazonaws.com/Shambala-Breathing-Video-3.mp4",
    },
  ];
const Lectures = () => {
    const [videoLink, setVideoLink] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const handleVideo = (link) => {
      setVideoLink(link);
      setOpenModal(true);
    };
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: {lg:"8rem",md: "6rem", xs: "4rem" },
          "& .title": {
            fontFamily: "AvenirLTStdRoman",
            fontSize: "30px",
            lineHeight: "normal",
            fontWeight: "600",
            color: "#782980",
          },
          "& .subTitle": {
            fontFamily: "AvenirLTStdBook",
            fontSize: "16px",
            color: "#49194E",
          },
        }}
      >
        <Container>
        <GoBackButton/>
          <Grid container spacing={4} sx={{ my: { md: "0", xs: "2rem" } }}>
            <Grid item md={6} xs={12}>
  
              <Box>
           <Typography className="title" pb={"1rem"}>
           Lectures
              </Typography>
                <Typography
                  className="subTitle"
                  sx={{
                    width:{lg:"90%"}
                  }}
                >
                  Produced in partnership with Shambhala Mountain Center in Colorado, this series of three videos is designed for yoga practitioners and those interested in self-regulation breathing practices. The lectures delve into critical topics such as <span style={{color:"#782980",fontWeight:"600"}}>
                  Overbreathing, Hypocapnia (CO2 deficit), and Dysfunctional breathing habits,
                  </span> offering practical insights to help improve breathwork. Relevant for both practitioners and their clients, these videos provide essential knowledge on how breathing patterns affect overall health and well-being.
                </Typography>
      </Box>
            </Grid>

            <Grid item md={6} xs={12} position={"relative"}>
            <Typography component={"img"} src={left_attention} sx={{
                width:"52px",
                position:"absolute",
                top:"-25px",
                right:"25%",
                 transform:"translateX(-25%)",
                 display:{md:"block",xs:"none"}
                // zIndex:"1"
            }}/>
              <Box
                sx={{
                  lineHeight: "0",
                  position: "relative",
                  p:"15px",
                  width:{lg:"75%"},
                  m:"0 auto",
                  borderRadius: "20px",
                  border:"1px solid #782980",
                  textAlign: "center",
                }}
              >
            <Box sx={{
                width:"72px",
                height:"72px",
                borderRadius:"50%",
                p:"12px",
                background:"#fff",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                position:"absolute",
                top:"-30px",
                left:"50%",
                transform:"translateX(-50%)"
            }}>
            <Typography component={"img"} src={attention} width={"100%"}/>
            </Box>
            <Typography component={"img"} src={small_attention} sx={{
                width:"130px",
                position:"absolute",
                bottom:"0",
                left:"-80px",
                display:{md:"block",xs:"none"}
            }}/>
            <Typography component={"img"} src={right_attention} sx={{
                width:"82px",
                position:"absolute",
                bottom:"-30px",
                right:"-40px",
                display:{md:"block",xs:"none"}
            }}/>
              <Box sx={{
                // position:"relative",
                p:"2rem",
                  borderRadius: "20px",
                  background:"#fff"
              }}>
              <Typography className="title" py={"1rem"} sx={{
                fontSize:"35px !important",
                fontFamily:"AvenirLTStdBlack !important"
              }}>
              Attention
              </Typography>
              <Typography className="subTitle">
              Practitioners may download the videos for purposes of working with their clients, but may not use them for commercial purposes.The videos are copyrighted by the Professional School of Behavioral Health Sciences.
              </Typography>

              </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={"3rem"}>
           {
            lectureData?.map((data,i)=>(
                <Grid item md={4} sm={6} xs={12} key={i}>
                <Box sx={{
                    background:"#fff",
                    borderRadius:"10px",
                    p:"2rem 1.5rem",
                    height:"100%",
                    transition:"0.3s all",
                    "&:hover":{
                        transform:"translateY(-5px)"
                    }
                }}>
                 <Typography className="title" pb={"1rem"} sx={{
                    fontSize:"24px !important"
                 }}>
                 {data.title}
              </Typography>
                 <Typography className="subTitle" pb={"1rem"} sx={{
                    color: "#782980 !important",fontWeight:"600"
                 }}>
                 {data.heading}
              </Typography>
                 <Typography className="subTitle" pb={"3rem"}>
                {data.subtitle}
              </Typography>
              <Button
                    className={"btn"}
                    sx={{
                      fontSize: "16px",
                      textTransform: "capitalize",
                      fontFamily: "AvenirLTStdBook",
                      width:"100%",
                      fontWeight:"600",
                      py:"20px !important",
                      background: "#C8A7CB !important",
                      color: "#782980 !important",
                      "&:hover":{
                        background: "#782980 !important",
                      color: "#fff !important",
                      }
                    }}
                    onClick={() => handleVideo(data.video)}
                  >
                    Watch Now
                  </Button>
                </Box>
            </Grid>
            ))
           }
          </Grid>
        </Container>
        <Box pt={"4rem"} pb={"2rem"}>
        <NewsLetter/>
        </Box>
        <Container>
          <Footer display="none" padding="0" />
          <CopyRight />
        </Container>
      </Box>
      <VideoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        videoLink={videoLink}
      />
    </>
  );
};

export default Lectures;
